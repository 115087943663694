import {
  ACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_REQUEST
} from "../types/redirect";
import { login,activateUserModal } from "./modals";
import { onActivateUser } from "../services/users.service";
import { loginForm, loginHyveForm } from "./forms";

export const activateRequest = () => ({
  type: ACTIVATE_USER_REQUEST
});
export const deactivateRequest = () => ({
  type: DEACTIVATE_USER_REQUEST
});

export const activateUserIfNeeded = (email, activationHash) => (
  dispatch,
  getState
) => {
  dispatch(activateRequest());

  const { isLoggedIn } = getState().login;
  const { loginType } = getState().toggles.hyveLogin;
  if (isLoggedIn) return false; // no need to activate if already logged in
  let postParams = {
    email: email,
    activationHash: activationHash
  };

  onActivateUser(postParams)
    .then(({ data }) => {
      if (data.success) {
        // dispatch(login.open());
        // if (loginType) {
        //   dispatch(loginHyveForm.setSuccess("email", "ACTIVATION_SUCCESS"));
        // } else {
        //   dispatch(loginForm.setSuccess("email", "ACTIVATION_SUCCESS"));
        // }

        dispatch(activateUserModal.open());
      } else {
        dispatch(login.open());
        if (loginType) {
          dispatch(loginHyveForm.setError("email", "ACTIVATION_FAILURE"));
        } else {
          dispatch(loginForm.setError("email", "ACTIVATION_FAILURE"));
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
};
