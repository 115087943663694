import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import { billingSubscriptionForm } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";

class BillingSubscriptionComponent extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    let ErrorOtp;
    ErrorOtp = this.props.billingSubscriptionForm.fields.otp.errorMsg
      ? APP_MESSAGES[this.props.billingSubscriptionForm.fields.otp.errorMsg](
          this.props.billingSubscriptionForm.fields.otp.errorPayload,
          () => {
            this.props.billingSubscriptionFormResetError("otp");
          }
        )
      : props => <>{""}</>;

    const { t } = this.props;
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingSubscriptionFormResetError("otp");
          }}
          isOpen={this.props.isVasErrorModalOpen}
        >
          <ErrorOtp />
        </ModalERROR>

        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 ">
                <div className="row d-flex flex-column height-fixed p-0 p-lg-3">
                  <div className="col h-100 bg-white overflow-auto radius-15 min-h-500">
                    <div className="row">
                      <div className="col-sm-12 header mb-3 mb-lg-4">
                        <p className="font-size-21 semibold mb-0 px-0 px-lg-3 py-2 py-lg-4">
                          {t("continuePaymentProcess")}
                        </p>
                      </div>
                    </div>
                    <form
                      className="box mx-0 mx-lg-3 subscription"
                      onSubmit={e => {
                        e.preventDefault();

                        if (!this.props.billingSubscriptionForm.isSubmitting) {
                          if (
                            !this.props.billingSubscriptionForm.fields.otp.value
                          ) {
                            this.props.billingSubscriptionFormSetError(
                              "otp",
                              "VAS_NOT_EMPTY"
                            );
                            this.props.vasError();
                            return false;
                          }

                          let postParam = {
                            mondiaCustomerId: this.props.billingSubscriptionForm
                              .fields.mondiaCustomerId.value,
                            mondiaSubscriptionId: this.props
                              .billingSubscriptionForm.fields
                              .mondiaSubscriptionId.value,
                            otp: this.props.billingSubscriptionForm.fields.otp
                              .value
                          };
                          this.props.billingSubscriptionFormSubmit(postParam);
                        }
                      }}
                    >
                      <div className="row ">
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-center border-bottom">
                            <div className="bg-primary rounded-pill text-white d-inline-flex px-3 py-1 medium">
                              {t("productName")}
                            </div>
                            <div className="semibold ml-auto font-size-24">
                              ArcadeX
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-center border-bottom">
                            <div className="bg-primary rounded-pill text-white d-inline-flex px-3 py-1 medium">
                              Code
                            </div>
                            <input
                              type="text"
                              placeholder="Code"
                              className="form-control w-50 ml-auto"
                              onChange={event => {
                                this.props.billingSubscriptionFormUpdateField(
                                  "otp",
                                  event.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-center border-bottom">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary py-2 btn-block rounded-pill font-size-24 bold " +
                                (this.props.billingSubscriptionForm.isSubmitting
                                  ? " disabled"
                                  : "")
                              }
                            >
                              {t("subscribe")}
                            </button>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-center border-bottom">
                            <p className="mb-0 semibold font-size-21">
                              {t("freeTrial")}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-center border-bottom">
                            <p className="mb-0 semibold font-size-21">
                              {t("renewedDaily")}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex flex-column flex-lg-row align-items-start border-bottom">
                            <div className="bg-primary rounded-pill text-white d-inline-flex px-3 py-1 medium text-nowrap">
                              {t("subscriptionPrice")}
                            </div>
                            <p className="mb-0 semibold font-size-18 ml-auto text-left text-lg-right pl-0 pl-lg-3 mt-2 mt-lg-0">
                              {t("xTNDperday")}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-12 ">
                          <div className="subscription-sec-padder d-flex align-items-start">
                            <a
                              href="/"
                              className="btn btn-dark-rose py-2 font-size-24 medium radius-10 text-white"
                            >
                              {t("challengePage.cancel")}
                            </a>
                          </div>
                        </div>
                      </div>
                     
                    </form>
                    <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                            <div className="blank-space"></div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingSubscriptionFormSubmit: billingSubscriptionForm.submit,
  billingSubscriptionFormReset: billingSubscriptionForm.reset,
  billingSubscriptionFormResetField: billingSubscriptionForm.resetField,
  billingSubscriptionFormUpdateField: billingSubscriptionForm.updateField,
  billingSubscriptionFormSetError: billingSubscriptionForm.setError,
  billingSubscriptionFormSetSuccess: billingSubscriptionForm.setSuccess,
  billingSubscriptionFormResetError: billingSubscriptionForm.resetError,
  billingSubscriptionFormResetSuccess: billingSubscriptionForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  return {
    billingSubscriptionForm: state.vas.billingsubscriptionform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BillingSubscriptionComponent));
