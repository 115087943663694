export default {
  loginProfile: "/login",
  ngLoginProfile: "/billing/ngLogin",
  loginHyve: "/profiles/login/v5",
  createProfile: "/register",
  getProfile: "/profile/##id##",
  getLanguages: "/admin/languages",
  getFollowers: "/profile/##id##/followers",
  updateProfile: "/profile/update",
  raffles: "/raffles",
  joinRaffles: "/raffles/join",
  createProfileHyve: "/profiles/create/v2",
  subscribeEmail: "/subscribe",
  myActiveRaffles: "/raffles/active",
  tournaments: "/r/reactjs.json",
  contactUs: "/contact-us",
  // challenges : "/tournaments/getAll",
  mytournaments: "/challenges/entered",
  enteredTournamentsByProfile: "/challenges/enteredByProfile",
  // mytournamentsEndedOfTwoMonth:"/challenge-list-my-entered/get",
  mytournamentsEndedOfTwoMonth: "/challenges/finishedTwoMonths",
  activateProfile: "/profile/activate",
  challenges: "https://gbg-api-dev-2.herokuapp.com/tournaments/getAll",
  createUsername: "/profile/set-username-and-game-preferences",
  usernameAvailable: "/profile/username-available",
  upload: "/images/upload",
  updateAvatar: "/profile/set-avatar",
  addShownTutorial: "/profile/add-shown-tutorial",
  challengesLandingPopular: "/challenges/popular",
  challengesListByBrand: "/challenges/bybrand",
  challengeDetail: "/challenges/##id##",
  challengeLeaderboard: "/challenges/leaderboards",
  brandLeaderboard: "/brands/leaderboards",
  joinChallenge: "/challenges/join",
  changePasswordRequest: "/profile/change-password",
  resetPasswordRequest: "/profile/reset-password-request",
  resetPassword: "/profile/reset-password",
  finishChallenge: "/challenges/finish",
  challengesLive: "/challenges/live",
  challengesQuickPlay: "/challenges/quickplay",
  challengesPremium: "/challenge-list-premium/get",
  challengesUpcoming: "/challenges/upcoming",
  popularBrands: "/brands",
  brandDetails: "/brands/##id##",
  topPlayers: "/brands/##id##/top-players",
  arcadeCategories: "/arcade-games/categories",
  arcadeGamesByCategory: "/arcade-games/category",
  // newsV2: "/newsListByCategory/get",
  newsCategories: "/news/categories",
  newsDetails: "/news/##id##",
  getRelatedNews: "/relatedNewsList/get",
  getNewsListUrl: "/news",
  getNewsCategoriesList: "/news/category/##id##",
  arcadeGameDetail: "/arcade-games/##id##",
  messageSupportV2: "/message-support",
  reportPlayerRequest: "/profile/report",
  getFriends: "/profile/friends",
  addAsFriend: "/profile/##id##/friend-request",
  follow: "/profile/##id##/follow",
  unfollow: "/profile/##id##/unfollow",

  prizeRedemption: "/prizes/redemption",
  verifyPrize: "/prizes/verify",
  profileLastPrize: "/prizes/profileLastPrize",
  friendAccepted: "/profile/##id##/friend-accepted",
  friendDeclined: "/profile/##id##/friend-declined",
  unFriend: "/profile/##id##/unfriend",
  getFollowings: "/profile/##id##/followings",
  featureChallenges: "/challenges/featured",
  followBrand: "/brands/##id##/follow",
  unfollowBrand: "/brands/##id##/unfollow",

  publishRaffle: "/raffles/publish",
  challengesByBrand: "/challenges/bybrand",
  mobileOperatorDetails: "/billing/mondia/getMobileOperators",
  productsByMobileOperator: "/billing/mondia/getProductsByMobileOperator",
  vasSubscribe: "/billing/subscribe",
  vasVerifyOTP: "/billing/verifyOTP",
  vasRegister: "/billing/register",
  vasProfileLogin: "/billing/profileLogin",
  vasProfileBillingInfo: "/billing/wifiSubscribe",
  vasNGProfileBillingInfo: "/billing/ngWifiSubscribe",
  vasForgotPassword: "/billing/forgotPassword",
  vasResetPassword: "/billing/verifyOtpByToken",
  vasUnsubscribe: "/billing/unsubscribe",
  vasHoldScreen: "/billing/getSubscriptionStatus",
  vasNGHoldScreen: "/billing/getNGSubscriptionStatus",
  vasNGAutoLogin: "/billing/ngAutoLogin",
  vasWrapme: "/billing/wrapMeSubscribe",
  vasNGGetDOIUrl: "/billing/getDOIUrl",
};
