import React, { Component } from "react";
import { connect } from "react-redux";
import "./ContactUsComponent.scss";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import AppTextAreaComponent from "../../layouts/components/sidebars/AppTextAreaComponent";
import { validateEmail } from "../../utils/validators";
import { APP_MESSAGES } from "../../services/config/messages";
import { contactForm } from "../../actions/forms";
import { withTranslation } from "react-i18next";
import { platform } from "os";
import platformParams from "../../utils/platformParams";
class ContactUsComponent extends Component {
  componentDidMount() {}

  //validation rules
  handleValidation() {
    let fields = this.props.contactForm.fields;
    //player name
    if (!fields.name.value) {
      this.props.contactFormSetError("email", "NOT_EMPTY");
      return false;
    }
    //email
    if (!fields.email.value) {
      this.props.contactFormSetError("email", "NOT_EMPTY");
      return false;
    }

    if (!validateEmail(fields.email.value)) {
      this.props.contactFormSetError("email", "EMAIL");
      return false;
    }

    //question
    if (!fields.message.value) {
      this.props.contactFormSetError("email", "NOT_EMPTY");
      return false;
    }

    return true;
  }

  //submit form
  submitcontactForm(e) {
    e.preventDefault();
    let postParams;

    if (this.handleValidation()) {
      postParams = {
        name: this.props.contactForm.fields.name.value,
        email: this.props.contactForm.fields.email.value,
        message: this.props.contactForm.fields.message.value
      };
      this.props.contactFormSubmit(postParams);
    } else {
    }
  }

  getSuccessMsg() {
    let fields = this.props.contactForm.fields;
    if (fields.email.successMsg) {
      return APP_MESSAGES[fields.email.successMsg](fields.email.successPayload);
    } else {
      return props => <>{""}</>;
    }
  }

  getErrorMsg() {
    let fields = this.props.contactForm.fields;

    if (fields.email.errorMsg) {
      return APP_MESSAGES[fields.email.errorMsg](
        fields.email.errorPayload,
        () => {
          this.props.contactFormResetError("email");
        }
      );
    } else {
      return props => <>{""}</>;
    }
  }

  render() {
    let ErrorMsg;
    let SuccessMsg;

    SuccessMsg = this.props.contactForm.fields.email.success
      ? APP_MESSAGES[this.props.contactForm.fields.email.successMsg](
          this.props.contactForm.fields.email.successPayload,
          () => {
            this.props.contactFormResetSuccess("email");
          }
        )
      : props => <>{""}</>;

    ErrorMsg = this.props.contactForm.fields.email.error
      ? APP_MESSAGES[this.props.contactForm.fields.email.errorMsg](
          this.props.contactForm.fields.email.errorPayload,
          () => {
            this.props.contactFormResetError("email");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <section className="container-fluid banner-wrapper news-wrapper">
          <div className="row">
            <div
              className={
                "banner news help-support position-relative " + platformParams['className']
              }
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  contents">
                  <div className="col-sm-12 col-xl-9 d-flex flex-column  justify-content-center align-items-start">
                    <h2 className="bold display-1 text-white mb-3 uppercase">
                      {t("navContactUs")}
                    </h2>
                  </div>
                </div>
              </div>
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1345.6 495.3"
                enable-background="new 0 0 1345.6 495.3"
                className="svgIE"
                xmlSpace="preserve"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="hexagonal-mask">
                    <path
                      d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                      Fill="none"
                    />
                  </clipPath>
                </defs>
                <image
                  clip-path="url(#hexagonal-mask)"
                  height="100%"
                  width="100%"
                  xlinkHref={platformParams['contactUsBanner']}
                  preserveAspectRatio="none"
                />
              </svg>
            </div>
          </div>
        </section>

        <section className="[ container-fluid ] bg-light-gradient  question-wrapper contactPageForm">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row question-form justify-content-center">
                  <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                    <form
                      className="get-prize-form w-100"
                      id="question-form"
                      onSubmit={this.submitcontactForm.bind(this)}
                    >
                      <div className="form-group with-lable ">
                        <h2 className="bold">{t("getInTouch")}</h2>
                        <h5 className="mt-2">
                          {t("backToSoon")}
                        </h5>
                        <ErrorMsg />
                        <SuccessMsg />
                        <div className="row">
                          <div className="col-sm-6 ">
                            <AppInputComponent
                              divClass="form-group with-lable mt-5"
                              labelText={t("playerName")}
                              onChange={event => {
                                this.props.contactFormUpdateField(
                                  "name",
                                  event.target.value
                                );
                                this.props.contactFormResetError("email");
                              }}
                              value={this.props.contactForm.fields.name.value}
                            />
                          </div>
                          <div className="col-sm-6 ">
                            <AppInputComponent
                              divClass="form-group with-lable mt-5"
                              labelText="Email"
                              onChange={event => {
                                this.props.contactFormUpdateField(
                                  "email",
                                  event.target.value
                                );
                                this.props.contactFormResetError("email");
                              }}
                              value={this.props.contactForm.fields.email.value}
                            />
                          </div>

                          <div className="col-sm-12 ">
                            <AppTextAreaComponent
                              divClass="form-group with-lable mt-5"
                              labelText="Message"
                              onChange={event => {
                                this.props.contactFormUpdateField(
                                  "message",
                                  event.target.value
                                );
                                this.props.contactFormResetError("email");
                              }}
                              value={
                                this.props.contactForm.fields.message.value
                              }
                            />
                          </div>
                        </div>

                        <div className="row mt-2 mt-sm-6 button-wrapper">
                          <div className="col-12 col-sm text-center">
                            <button
                              type="submit"
                              className={
                                "btn btn-" + platformParams['shadeButtonClassSufix'] + " dark btn-lg" +
                                (this.props.contactForm.isSubmitting
                                  ? " disable"
                                  : "")
                              }
                            >
                             {t("send")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  contactFormSubmit: contactForm.submit,
  contactFormReset: contactForm.reset,
  contactFormResetField: contactForm.resetField,
  contactFormUpdateField: contactForm.updateField,
  contactFormSetError: contactForm.setError,
  contactFormSetSuccess: contactForm.setSuccess,
  contactFormResetError: contactForm.resetError,
  contactFormResetSuccess: contactForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    contactForm: state.forms.contactForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ContactUsComponent));
