import React, { Component } from "react";
import "./MaintenanceContainer.scss";
import LogoWhite from "../assets/images/logo-white.svg";

class MaintenanceContainer extends Component {
  render() {
    return (
      <div className="maintenance">
        <section className="[ container-fluid ] arcadexmobi-section">
          <div className="row">
            <div className="container ">
              <div className="row  comingsoon">
                <div className="col-lg-12 d-flex justify-content-center align-items-center h-100vh flex-column text-center">
                  <img src={LogoWhite} alt="" />
                  <h1 className="text-white display-1 text-uppercase">
                    UNDER MAINTENANCE
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default MaintenanceContainer;
