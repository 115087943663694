import React, { Component } from "react";
import helpSupportBannerImgNickx from "../../assets/images/help-support-banner-nick.png";
import helpSupportBannerImg from "../../assets/images/help-support-banner.jpg";
import "./PrivacyPolicyComponent.scss";
import PrivacyContent from "./PrivacyContent";
import PrivacyContentFr from "./PrivacyContentFr";
import i18n from "../../i18n";
import platformParams from "../../utils/platformParams";

class PrivacyPolicyComponent extends Component {
  render() {
      const lang = i18n.language;
    return (
      <>
        <section className="container-fluid banner-wrapper news-wrapper">
          <div className="row">
            <div
              className={
                "help-support position-relative " + platformParams['className']
              }
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  contents">
                  <div className="col-sm-12 col-xl-9 d-flex flex-column  justify-content-center align-items-start">
                    <h2 className="bold display-1 text-white uppercase">
                      { lang === "en" ? "Privacy Policy" : " Politique de confidentialité" }
                    </h2>
                    {/* <h5 className="text-white">
                      Take a look at our handy FAQ for all the answers, insights
                      and tips you’re looking for.
                    </h5> */}
                  </div>
                </div>
              </div>
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1345.6 495.3"
                enable-background="new 0 0 1345.6 495.3"
                className="svgIE"
                xmlSpace="preserve"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="hexagonal-mask">
                    <path
                      d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                      Fill="none"
                    />
                  </clipPath>
                </defs>
                <image
                  clip-path="url(#hexagonal-mask)"
                  height="100%"
                  width="100%"
                  xlinkHref={platformParams['helpSupportBanner']}
                  preserveAspectRatio="none"
                />
              </svg>
            </div>
          </div>
        </section>
        { lang === "en" ?
            <PrivacyContent setclass="container-fluid news event-section help-support about-mtn-treasure" /> :
            <PrivacyContentFr setclass="container-fluid news event-section help-support about-mtn-treasure" /> 
        }
        
      </>
    );
  }
}

export default PrivacyPolicyComponent;
