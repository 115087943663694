import { connect } from "react-redux";
import DashboardComponent from "../components/Dashboard/Dashboard/DashboardComponent";
import {
  challengesLive,
  challengesQuickPlay,
  challengesUpcoming,
  challengesPremium,
  featureChallenge
} from "../actions/challenges";
import { brandsListPopular } from "../actions/brands";

import {  
  newsList
} from "../actions/news";

import {  
  freeToPlayList
} from "../actions/categories";

const mapDispatchToProps = {

  
  fetchFeatureChallengesIfNeeded : featureChallenge.fetchIfNeeded,
  invalidateFeatureChallengesIfNeeded : featureChallenge.invalidate,  
  invalidateAllFeatureChallengesIfNeeded : featureChallenge.invalidateAll,  


  fetchChallengesLiveIfNeeded : challengesLive.fetchIfNeeded,
  invalidateChallengesLiveIfNeeded : challengesLive.invalidate,  
  invalidateAllChallengesLiveIfNeeded : challengesLive.invalidateAll,  

  fetchChallengesUpcomingIfNeeded : challengesUpcoming.fetchIfNeeded,
  invalidateChallengesUpcomingIfNeeded : challengesUpcoming.invalidate,
  invalidateAllChallengesUpcomingIfNeeded : challengesUpcoming.invalidateAll,

  fetchChallengesPremiumIfNeeded : challengesPremium.fetchIfNeeded,
  invalidateChallengesPremiumIfNeeded : challengesPremium.invalidate,
  invalidateAllChallengesPremiumIfNeeded : challengesPremium.invalidateAll,

  fetchChallengesQuickPlayIfNeeded : challengesQuickPlay.fetchIfNeeded,
  invalidateChallengesQuickPlayIfNeeded : challengesQuickPlay.invalidate,
  invalidateAllChallengesQuickPlayIfNeeded : challengesQuickPlay.invalidateAll,
  
  fetchPopularBrandsIfNeeded : brandsListPopular.fetchIfNeeded,
  invalidatePopularBrandsIfNeeded : brandsListPopular.invalidate,
  invalidateAllPopularBrandsIfNeeded : brandsListPopular.invalidateAll,

  fetchFreeToPlayIfNeeded : freeToPlayList.fetchIfNeeded,
  invalidateFreeToPlayIfNeeded : freeToPlayList.invalidate,
  invalidateAllFreeToPlayIfNeeded : freeToPlayList.invalidateAll,

  fetchNewsIfNeeded : newsList.fetchIfNeeded,
  invalidateNewsIfNeeded : newsList.invalidate,  
  invalidateAllNewsIfNeeded : newsList.invalidateAll,  
};

const mapStateToProps = state => {
  const { challenges, brands, news, categories ,user , login } = state;  

  const {
    item: userDetail
  } = user.item || {
    item: []
  };


  const {
    items: freeToPlayList
  } = categories.freeToPlay || {
    items: []
  };

  const {
    items: newsListAll
  } = news.newsList || {
    items: []
  };

  const {
    items: brandsPopularList
  } = brands.popularBrands || {
    items: []
  };

  const {
    items: challengesLiveList
  } = challenges.live || {
    items: []
  };

  const {
    items: challengesQuickPlayList
  } = challenges.quickPlay || {
    items: []
  };

  const {
    items: challengesUpcomingList
  } = challenges.upcoming || {
    items: []
  };

  const {
    items: challengesPremiumList
  } = challenges.premium || {
    items: []
  };

  const {
    items: featureChallengesList
  } = challenges.featureChallenges || {
    items: []
  };


  return {
    challengesLiveList,
    challengesQuickPlayList,
    challengesUpcomingList,
    challengesPremiumList,
    brandsPopularList,
    newsListAll,
    freeToPlayList,
    featureChallengesList,
    featureChallengesIsFetching  : challenges.featureChallenges.isFetching,
    userDetail:user.item || [],
    currentLang: login.currentLang,
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
