import React, { Component } from "react";
import helpSupportBannerImg from "../../assets/images/help-support-banner.jpg";
import "./TermsConditionsComponent.scss";

class TermsContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div className={this.props.setclass!=="container-fluid"?"padder-main":""}>
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12">
                    <h6
                      className={
                        "mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      TERMS AND CONDITIONS OF USE
                    </h6>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      1 Introduction
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.1</span>Terms
                        </p>
                        Hi there and welcome to our terms and conditions of use{" "}
                        <span className="text-black bold">(“Terms”)</span>.
                        These Terms are important because they:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>set out the terms and
                        conditions upon which we grant you a right to use the
                        cloud-based and smart device software{" "}
                        <span className="text-black bold">(“Software”)</span>{" "}
                        and our services, including all instructions in hard
                        copy or electronic form and any update, modification or
                        release of any part of that Software or that service{" "}
                        <span className="text-black bold">(“Services”)</span>.
                        You can access, use, buy or subscribe to the Services by
                        downloading the application on a mobile device or
                        another platform (for example a website){" "}
                        <span className="text-black bold">(“App”)</span>; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>are binding on you from
                        the date{" "}
                        <span className="text-black bold">
                          (“Effective Date”)
                        </span>{" "}
                        on which you download or access the App and we provide
                        you with an account to enable you to access and use the
                        Service{" "}
                        <span className="text-black bold">(“Account”)</span>{" "}
                        until the date on which your Account is terminated in
                        accordance with these Terms{" "}
                        <span className="text-black bold">(“Term”)</span>.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.2</span>Terms
                        </p>
                        Please read these Terms carefully and immediately
                        terminate your Account if you do not agree to them.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.3</span>Apple mandatory
                          terms.
                        </p>
                        If you access or download the App from the Apple Inc{" "}
                        <span className="text-black bold">(“Apple”)</span> App
                        Store, you agree to Apple’s Licensed Application End
                        User Licence Agreement and any Usage Rules set forth in
                        the App Store Terms of Service and if you download the
                        App from the Google Play Store, you agree to Android’s
                        Google Inc. Terms and Conditions including the License
                        Agreement and Terms of Application.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.4</span>By creating an
                          Account and/or accessing and/or using the Service,
                          you:
                        </p>
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>warrant to us that you
                        have reviewed these Terms, including our Privacy Policy,
                        available in the App, and you understand them;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>warrant to us that you
                        have the legal capacity to enter into a legally binding
                        agreement;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>warrant to us that you
                        have the authority to act on behalf of any person or
                        entity for whom you are using the Service, and you are
                        deemed to have agreed to these Terms on behalf of any
                        entity for whom you use the Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>warrant to us that you
                        have all hardware, software and services which are
                        necessary to access and use the Service; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>agree to use the
                        Service in accordance with these Terms.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      2 Registration and Account
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.1</span>Account
                        </p>
                        You will be required to create an Account. You must
                        ensure that any personal information you give us when
                        creating an Account is accurate and up-to-date. All
                        personal information that you give to us will be treated
                        in accordance with our Privacy Policy.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.2</span>Using social media to
                          register
                        </p>
                        You may register for an Account using your Facebook,
                        Google or other social media account available on the
                        App{" "}
                        <span className="text-black bold">
                          (“Social Media Account”)
                        </span>
                        . If you sign in to your Account using your Social Media
                        Account, you authorise us to access certain information
                        on your Social Media Account including but not limited
                        to your current profile photo and other basic
                        information.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.3</span>User usernames and
                          accounts
                        </p>
                        When you create an Account, you will be able to choose
                        or the App will generate certain Account details (such
                        as a username and password). It is your responsibility
                        to keep your Account details confidential. You are
                        liable for all content posted and all activity on your
                        Account, including purchases made using your Account
                        details, and you must immediately notify us of any
                        unauthorised use of your Account.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.4</span>Profile
                        </p>
                        Once you have registered an Account, your account
                        information will be used to create a profile which you
                        may then curate{" "}
                        <span className="text-black bold">(“Profile”)</span>.
                        Your Profile is personal and you must not transfer it to
                        others, except with our written permission.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.5</span>Age restrictions
                        </p>
                        You must not create an Account unless you are at least
                        18 years of age.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.6</span>Our discretion
                        </p>
                        At our sole discretion, we may refuse to allow any
                        person to create an Account.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      3 Use of the App
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">3.1</span>Use of the App
                        </p>
                        Our App offers games of skill, with no element of risk
                        or chance, where players can win prizes. Users can
                        directly message other users on the App, and must do so
                        in accordance with this Agreement.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      4 Collection Notice
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.1</span>Collection
                        </p>
                        We collect personal information about you, in order to
                        enable you to access and use the App, to contact and
                        communicate with you, to analyse your interests and
                        provide tailored services suggestions, to respond to
                        your enquiries and for other purposes set out in our
                        Privacy Policy.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.2</span>Third parties
                        </p>
                        We may disclose that information to third party service
                        providers who help us deliver our services (including
                        information technology service providers, data storage,
                        web-hosting and server providers, professional advisors,
                        payment systems operators and our business partners) or
                        as required by law. If you do not provide this
                        information we may not be able to provide the Service to
                        you. In certain circumstances, we may disclose your
                        personal information to third parties located, or who
                        store data, outside Australia.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.3</span>Privacy Policy
                        </p>
                        Our Privacy Policy contains further information about:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>how we store and use
                        your personal information;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>how you can access and
                        seek correction of your personal information;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>how you can make a
                        privacy-related complaint; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>our complaint handling
                        process. By providing personal information to us, you
                        consent to us collecting, holding, using and disclosing
                        your personal information in accordance with our Privacy
                        Policy.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.4</span>Analytics
                        </p>
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>Despite anything to the
                        contrary in these Terms or elsewhere, we may monitor,
                        analyse and compile statistical and performance
                        information based on and/or related to your use of the
                        Service, in an aggregated and anonymised format{" "}
                        <span className="text-black bold">(“Analytics”)</span>.
                        You agree that we may make such Analytics publicly
                        available, provided that it:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>does not contain
                        identifying information; and
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>is not compiled using
                        a sample size small enough to make the underlying data
                        identifiable.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>We and/or our licensors
                        own all right, title and interest in and to the
                        Analytics and all related software, technology,
                        documentation and content provided in connection with
                        the Analytics, including all intellectual property
                        rights in the foregoing.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      5 Licence
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">5.1</span>Licence
                        </p>
                        We grant you a non-exclusive, non-transferable,
                        non-sublicensable (except as otherwise permitted under
                        these Terms), personal and revocable licence to access
                        and use the Service for your own personal purposes for
                        the Term{" "}
                        <span className="text-black bold">(“Licence”)</span>.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">5.2</span>Use
                        </p>
                        You agree that:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>the Licence permits you
                        to use the Service in accordance with these Terms; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>we reserve the right at
                        any time and from time to time to:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>refuse any request in
                        relation to the Service that we deem inappropriate,
                        unreasonable, illegal or otherwise non-compliant with
                        these Terms; and/or
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>modify or discontinue,
                        temporarily or permanently, access to the Service (or
                        any part) with notice.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      6 User guidelines
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">6.1</span>Intellectual property
                          rights
                        </p>
                        You must not access or use the Service except as
                        permitted by the Licence and you must not do, or omit to
                        do, any act that would or might invalidate or be
                        inconsistent with our intellectual property rights in
                        the Service or Software.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">6.2</span>Guidelines
                        </p>
                        You must not:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>resell, assign,
                        transfer, distribute or provide others with access to
                        the Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>“frame”, “mirror” or
                        serve any of the Service on any web server or other
                        computer server over the Internet or any other network;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>copy, alter, modify,
                        create derivative works from, reproduce to a third
                        party, reverse assemble, reverse engineer, reverse
                        compile or enhance the Service or Software;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>alter, remove or tamper
                        with any trademarks, any patent or copyright notices,
                        any confidentiality legend or notice, any numbers or any
                        other means of identification used on or in relation to
                        the Service or Software;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>use the Service to make
                        fraudulent offers of goods or services;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>use the Service in any
                        way which is in breach of any applicable local, state,
                        federal and international laws and regulations{" "}
                        <span className="text-black bold">(“Laws”)</span> or
                        which infringes any person&#39;s rights, including
                        intellectual property rights;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>use the Service to
                        transmit, publish or communicate material that is
                        defamatory, offensive, abusive, indecent, menacing or
                        unwanted;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(h)</span>use the Service in any
                        way that damages, interferes with or interrupts the
                        supply of the Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(i)</span>introduce malicious
                        programs into our hardware and Software or our hardware,
                        Software and services which are integrated and operate
                        together, including our networks, including viruses,
                        worms, trojan horses and e-mail bombs;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(j)</span>reveal your Account
                        password to others or allow others to use your Account;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(k)</span>use the Service to
                        carry out security breaches or disruptions of a network.
                        Security breaches include accessing data where you are
                        not the intended recipient or logging into a server or
                        account that you are not expressly authorised to access
                        or corrupting any data (including network
                        sniffing/monitoring, pinged floods, packet spoofing,
                        denial of service and forged routing information for
                        malicious purposes);
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(l)</span>use any
                        program/script/command, or send messages of any kind,
                        with the intent to interfere with, or disable, any
                        person’s use of the Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(m)</span>send any unsolicited
                        email messages through or to users of the Service in
                        breach of the Spam Act 2003 (Cth) or to send any form of
                        harassment via email, or any other form of messaging,
                        whether through language, frequency, or size of messages
                        or use the Service in breach of any person’s privacy
                        (such as by way of identity theft or “phishing”); and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(n)</span>use the Service to
                        circumvent user authentication or security of any of
                        your networks, accounts or hosts or those of your
                        customers or suppliers.
                      </li>
                    </ul>

                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      7 Rights you grant to us
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.1</span>Grant
                        </p>
                        So we can provide the Services to you, you grant us and
                        our sub-processors a worldwide, non-exclusive,
                        perpetual, irrevocable, royalty-free, fully paid,
                        sublicensable and transferable licence to:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>use, copy, back-up,
                        process, transmit, store, edit, modify, aggregate,
                        combine, reproduce, distribute, display, perform, and
                        prepare derivative works of the information, documents
                        and other data you provide to us and any content you
                        upload or which we otherwise access in providing the
                        Service including the User Content{" "}
                        <span className="text-black bold">(“Data”)</span>; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>otherwise access, use
                        or make reference to the Data or any intellectual
                        property rights in the Data:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>to supply the Service;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>for diagnostic
                        purposes;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iii)</span>to test, enhance and
                        otherwise modify the Service;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iv)</span>to develop other
                        services; and
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(v)</span>as reasonably required
                        for the performance of our obligations under these
                        Terms.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.2</span>Australian moral
                          rights
                        </p>
                        If you or any of your personnel have any moral rights
                        (as defined in the Copyright Act 1968 (Cth)){" "}
                        <span className="text-black bold">
                          (“Moral Rights”)
                        </span>{" "}
                        in any Data, you (and you will ensure that your
                        personnel) consent to the infringement of those Moral
                        Rights by us or our personnel.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.3</span>Data integrity
                        </p>
                        You must, at all times, ensure the integrity of your
                        Data and that your use of your Data is compliant with
                        all Laws. You represent and warrant that:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>you have obtained all
                        necessary rights, releases and permissions to provide
                        all your Data to us and to grant the rights granted to
                        us in these Terms; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>your Data and its
                        transfer to and use by us as authorised by you under
                        these Terms do not violate any Laws (including those
                        relating to export control and electronic
                        communications) or rights of any third party, including
                        any intellectual property rights, rights of privacy, or
                        rights of publicity, and any use, collection and
                        disclosure authorised in these Terms is not inconsistent
                        with the terms of any applicable privacy policies. We
                        are not responsible or liable for your Data, and you
                        will be solely responsible for your Data and the
                        consequences of using, disclosing, storing or
                        transmitting it.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.4</span>No monitoring
                        </p>
                        We have no obligation to monitor any content uploaded to
                        the Service. Nonetheless, if we deem such action
                        necessary for any reason, we may (without limiting our
                        other rights) remove your Data from the Service. We have
                        no liability to you for removing your Data from the
                        Service.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.5</span>Security
                        </p>
                        We implement security procedures to help protect your
                        Data from security attacks. However, you understand that
                        use of the Service necessarily involves transmission of
                        your Data over networks that are not owned, operated or
                        controlled by us, and we are not responsible for any of
                        your Data being lost, altered, intercepted or stored
                        across such networks. We cannot guarantee that our
                        security procedures will be error-free, that
                        transmissions of your Data will always be secure or that
                        unauthorised third parties will never be able to defeat
                        our security measures or those of our third-party
                        service providers.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      8 Technical problems
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        In case of technical problems, you must make all
                        reasonable efforts to investigate and diagnose problems
                        before contacting us. If you still need help, please
                        check the support we provide as stated on the App or
                        failing that please email us at support@arcadex.co.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      9 Your Responsibilities and Obligations
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">You must, at your expense:</li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>provide us with all
                        reasonable assistance and cooperation in order for us to
                        supply the Service in an efficient and timely manner;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>ensure all information
                        provided to us is kept up-to-date and the email address
                        you provide is valid and regularly checked; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>make any changes to
                        your device, such as system upgrades, that may be
                        required to support the delivery and operation of any
                        Service.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      10 Intellectual Property Rights
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">10.1</span>IP
                        </p>
                        All intellectual property (including copyright)
                        developed, adapted, modified or created by us or our
                        personnel (including in connection with these Terms, the
                        Software and the Service) will at all times vest, or
                        remain vested, in us.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">10.2</span>Conditions of use
                        </p>
                        You must not, without our prior written consent:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>copy or use, in whole
                        or in part, any of our intellectual property;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>reproduce, retransmit,
                        distribute, disseminate, sell, publish, broadcast or
                        circulate any of our intellectual property to any third
                        party; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>breach any intellectual
                        property rights connected with the App, the Software or
                        the Service, including (without limitation) altering or
                        modifying any of our intellectual property; causing any
                        of our intellectual property to be framed or embedded in
                        another application; or creating derivative works from
                        any of our intellectual property. This{" "}
                        <span className="text-black bold">clause 10</span> will
                        survive termination of your Account.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      11 Content
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.1</span>User Content
                        </p>
                        You may be permitted to post, upload, publish, submit or
                        transmit relevant information, content and photographs{" "}
                        <span className="text-black bold">
                          (“User Content”)
                        </span>{" "}
                        on the App. By making available any User Content on or
                        through the App, you grant to us a worldwide,
                        irrevocable, perpetual, non-exclusive, transferable,
                        royalty-free licence to use the User Content, with the
                        right to use, view, copy, adapt, modify, distribute,
                        license, sell, transfer, communicate, publicly display,
                        publicly perform, transmit, stream, broadcast, access,
                        or otherwise exploit such User Content on, through, or
                        by means of the App.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.2</span>You are responsible
                          for your User Content
                        </p>
                        You agree that you are solely responsible for all User
                        Content that you make available on or through the App.
                        You represent and warrant that:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>you are either the sole
                        and exclusive owner of all User Content or you have all
                        rights, licences, consents and releases that are
                        necessary to grant to us the rights in such User Content
                        as contemplated by these Terms; and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>neither the User
                        Content nor the posting, uploading, publication,
                        submission or transmission of the User Content or our
                        use of the User Content on, through or by means of the
                        App will infringe, misappropriate or violate a third
                        party’s intellectual property rights, or rights of
                        publicity or privacy, or result in the violation of any
                        applicable law or regulation.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.3</span>Not responsible
                        </p>
                        We do not endorse or approve and are not responsible for
                        any User Content. We may, at any time at our sole
                        discretion, remove any User Content.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      12 Indemnity and liability
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">12.1</span>Liability
                        </p>
                        Despite anything to the contrary, to the maximum extent
                        permitted by the law:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>our maximum aggregate
                        liability arising from or in connection with these Terms
                        (including the Service or the subject matter of these
                        Terms) will be limited to, and must not exceed the total
                        amount of Fees you paid to us in the month in which the
                        liability arose or $1 if no such Fees have been paid;
                        and
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>we will not be liable
                        to you for any consequential loss, indirect loss, loss
                        of profit (including anticipated profit), loss of
                        revenue, loss of business, loss of goodwill, loss of
                        opportunity, loss of savings (including anticipated
                        savings), loss of reputation, loss of use and/or loss or
                        corruption of data,
                      </li>
                      <li className="mb-2">
                        whether under statute, contract, equity, tort (including
                        negligence), indemnity or otherwise.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">12.2</span>Exclusion
                        </p>
                        Despite anything to the contrary, to the maximum extent
                        permitted by the law, we have no liability, and you
                        release and discharge us from all liability, arising
                        from or in connection with any:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>injury, damage or loss
                        to any person or property;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>failure or delay in
                        providing the Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>unavailability, outage
                        or interruption to the Service or your device; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>breach of these Terms
                        or any Laws,
                      </li>
                      <li className="mb-2">
                        where caused or contributed to by any:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>event or circumstance
                        beyond our reasonable control;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>fault, defect, error or
                        omission in your computing environment or Data; or
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>act or omission of you,
                        your related parties or a third-party service provider,
                        and, in any event, any error, omission or lack of
                        suitability (or the absence of, or reduction in, any
                        anticipated result, outcome or benefit) with respect to
                        the Service.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      13 Australian Consumer Law
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.1</span>ACL
                        </p>
                        Certain legislation, including the Australian Consumer
                        Law <span className="text-black bold">(“ACL”)</span>,
                        and similar consumer protection Laws and regulations,
                        may confer you with rights, warranties, guarantees and
                        remedies relating to the provision of the Deliverables
                        by us to you which cannot be excluded, restricted or
                        modified{" "}
                        <span className="text-black bold">
                          (“Statutory Rights”)
                        </span>
                        .
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.2</span>Failures
                        </p>
                        Our Services come with guarantees that cannot be
                        excluded under the ACL. For major failures with the
                        Services, you are entitled to cancel these Terms with
                        us.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.3</span>Consumers
                        </p>
                        If the ACL applies to you as a consumer, nothing in
                        these Terms excludes your Statutory Rights as a consumer
                        under the ACL. You agree that our Liability for the
                        Deliverables provided to an entity defined as a consumer
                        under the ACL is governed solely by the ACL and these
                        Terms.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.4</span>Exclusions
                        </p>
                        Subject to your Statutory Rights, we exclude all express
                        and implied warranties, and all material, work and
                        services (including the Services) provided to you
                        without warranties of any kind, either express or
                        implied, whether in statute, at Law or on any other
                        basis. This{" "}
                        <span className="text-black bold">clause 13</span> will
                        survive the termination or expiry of this Agreement.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      14 Termination
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">14.1</span>Deleting the App
                          doesn’t delete your Account
                        </p>
                        If you delete the App, your Account will remain active.
                        You may only terminate your Account by clicking on the
                        ‘Delete your profile’ option in the ‘Edit your Profile’
                        section of the App, or by emailing our support staff at
                        the contact details below. No refunds will be given upon
                        termination in accordance with this
                        <span className="text-black bold"> clause 14</span> .
                        Once your profile is deleted, the information you have
                        put into the App will be archived and remain on our
                        database.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">14.2</span>Termination
                        </p>
                        We may, at any time and at our sole discretion, suspend
                        or terminate your Account for any reason. You must
                        ensure that all of your Data on the Service is backed up
                        so that you do not lose your Data if we suspend or
                        terminate your Account. We will generally alert you when
                        we take such action and give you a reasonable
                        opportunity to remedy any breach of these Terms, but if
                        we determine that your actions endanger the operation of
                        the Service or other users, we may suspend or terminate
                        your Account immediately without notice. You will
                        continue to be charged for the Service during any
                        suspension period in which you are in breach of these
                        Terms.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      15 Notice regarding Apple
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>To the extent that you
                        are using or accessing the App on an iOS device, you
                        further acknowledge and agree to the terms of this
                        clause. You acknowledge that these Terms are between you
                        and us only, not with Apple, and Apple is not
                        responsible for the App and any content available on the
                        App.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>Apple has no obligation
                        to furnish you with any maintenance and support services
                        with respect to the App.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>If our mobile
                        application fails to conform to any applicable warranty,
                        you may notify Apple and Apple will refund the purchase
                        price of the mobile application to you. To the maximum
                        extent permitted by applicable law, Apple will have no
                        other warranty obligation whatsoever with respect to the
                        mobile application and any other claims, losses,
                        liabilities, damages, costs or expenses attributable to
                        any failure to conform to any warranty will be our
                        responsibility.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>Apple is not
                        responsible for addressing any claims by you or any
                        third party relating to our mobile application or your
                        use of our mobile application, including but not limited
                        to:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>product liability
                        claims;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>any claim that our
                        mobile application fails to conform to any applicable
                        legal or regulatory requirement; and
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iii)</span>claims arising under
                        consumer protection or similar legislation.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>Apple is not
                        responsible for the investigation, defence, settlement
                        and discharge of any third-party claim that our mobile
                        application infringes that third party’s intellectual
                        property rights.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>You agree to comply
                        with any applicable third-party terms when using our
                        mobile application.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>Apple and Apple’s
                        subsidiaries are third-party beneficiaries of these
                        Terms, and upon your acceptance of these Terms, Apple
                        will have the right (and will be deemed to have accepted
                        the right) to enforce these Terms against you as a
                        third-party beneficiary of these Terms.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(h)</span>You hereby represent
                        and warrant that:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>you are not located in
                        a country that is subject to a U.S. Government embargo,
                        or that has been designated by the U.S. Government as a
                        &quot;terrorist supporting&quot; country; and
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>you are not listed on
                        any U.S. Government list of prohibited or restricted
                        parties.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      16 General
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.1</span>Force Majeure:
                        </p>
                        If a party{" "}
                        <span className="text-black bold">
                          (“Affected Party”)
                        </span>{" "}
                        is unable to perform any of its obligations under these
                        Terms due to an event or circumstance beyond its
                        reasonable control{" "}
                        <span className="text-black bold">
                          (“Force Majeure”)
                        </span>{" "}
                        and it gives the other party prompt written notice of
                        such, the Affected Party’s obligations will be suspended
                        to the extent necessary. The Affected Party must use
                        reasonable efforts to limit the impact of the event on
                        its performance and must continue to perform its
                        obligations in full as soon as the Force Majeure ceases.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.2</span>Disputes
                        </p>
                        Neither party may commence court proceedings relating to
                        any dispute arising from, or in connection with, these
                        Terms without first meeting with a senior representative
                        of the other party to seek (in good faith) to resolve
                        that dispute (unless that party is seeking urgent
                        interlocutory relief or the dispute relates to
                        compliance with this provision).
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.3</span>Notices
                        </p>
                        Any notice given under these Terms must be in writing
                        and be addressed to us at the details set out below or
                        to you at the details provided when setting up your
                        Account. Any notice may be sent by standard post or
                        email and will be deemed to have been served on the
                        expiry of 3 days in the case of post, or at the time of
                        transmission in the case of email.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.4</span>Waiver
                        </p>
                        Any failure or delay by a party in exercising a power or
                        right (either wholly or partly) in relation to these
                        Terms does not operate as a waiver or prevent a party
                        from exercising that power or right or any other power
                        or right. A waiver must be in writing.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.5</span>Relationship of
                          parties
                        </p>
                        These Terms are not intended to create a partnership,
                        joint venture or agency relationship between the
                        parties.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.6</span>Severance
                        </p>
                        If a provision of these Terms is held to be void,
                        invalid, illegal or unenforceable, that provision is to
                        be read down as narrowly as necessary to allow it to be
                        valid or enforceable, failing which, that provision (or
                        that part of that provision) will be severed from these
                        Terms without affecting the validity or enforceability
                        of the remainder of that provision or the other
                        provisions.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.7</span>Assignment
                        </p>
                        You must not assign any rights or obligations under
                        these Terms, whether in whole or in part, without our
                        prior written consent.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.8</span>Entire agreement
                        </p>
                        These Terms contain the entire understanding and
                        agreement between you and us in respect of their subject
                        matter.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.9</span>Amendment
                        </p>
                        We may, at any time and at our discretion, vary these
                        Terms by publishing varied terms on the App. Such
                        variation will take effect at the commencement of your
                        next plan billing cycle. Prior to the commencement of
                        each plan billing cycle, you must carefully read the
                        terms that are in effect at that time to ensure you
                        understand and agree to them.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.10</span>Governing law
                        </p>
                        These Terms are governed by the laws of New South Wales.
                        You irrevocably and unconditionally submit to the
                        exclusive jurisdiction of the courts operating in New
                        South Wales and any courts entitled to hear appeals from
                        those courts and waive any rights to object to
                        proceedings being brought in those courts. The Service
                        may be accessed in Australia and overseas. We make no
                        representation that the Service complies with the laws
                        (including intellectual property laws) of any country
                        outside of Australia. If you access the Service from
                        outside Australia, you do so at your own risk and we are
                        responsible for complying with the laws in the place you
                        access the Service.
                      </li>
                    </ul>

                    <p className="text-black mb-4 bold">
                      For any questions, please contact us at:
                    </p>
                    <p className="text-muted mb-4">
                      Emerge Gaming Limited ABN 31 004 766 376
                    </p>
                    <p className="text-muted mb-4">
                      Email:{" "}
                      <a
                        href="mailto:support@arcadex.co"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        support@arcadex.co
                      </a>
                    </p>
                    <p className="text-muted mb-4">Last update: 28 May 2019</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TermsContent;
