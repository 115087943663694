import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./BrandComponent.scss";
import searchImg from "../../assets/images/svg/search.svg";
import TournamentCardContainer from "../Tournaments/TournamentCardContainer";
import AvatarDemo from "../../assets/images/avatar-demo.png";
import BrandsTopPlayerComponent from "./BrandsTopPlayer/BrandsTopPlayerComponent";
import loadinggif from "../../assets/images/loading.gif";
import NotFoundContainer from "../../containers/NotFoundContainer";
import adv11 from "../../assets/images/adv/adv-2.png";
import adv11Nickx from "../../assets/images/adv/adv-nick-1.png";
import DashboardNews from "../../components/Dashboard/DashboardNews/DashboardNewsComponents";
import DashboardQuickPlay from "../../components/Dashboard/DashboardQuickPlay/DashboardQuickPlayComponent";
import ProfileMessageDropdownComponent from "../NiceDropdown/ProfileMessageDropdownComponent";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import BrandLeaderboard from "../../components/Brand/BrandLeaderboard";
import platformParams from "../../utils/platformParams";

class BrandComponent extends Component {
  constructor(props) {
    super(props);
    this.showChatListSidebar = this.showChatListSidebar.bind(this);
    this.state = {
      search: ""
    };
  }

  showChatListSidebar(profileDetail) {
    this.props.chatListSidebarOpen();
    this.props.openChatHistory(profileDetail);
  }

  prepareTagClass(brandName) {
    // if(brandName != ""){
    //   return " gtmBrandBanner-" + brandName.split(' ').join('-');
    // }
    // return "";
    return " gtmBrandBanner-1";
  }

  componentDidMount() {
    this.props.invalidateBrandDetails();
    this.props.invalidateChallengesLiveIfNeeded();
    this.props.invalidateChallengesUpcomingIfNeeded();
    this.props.invalidateChallengesQuickPlayIfNeeded();
    this.props.invalidateTopPlayersIfNeeded();
    this.props.invalidateNewsIfNeeded();

    this.props.fetchBrandDetailsIfNeeded({
      brandId: this.props.match.params.id
    });
    this.props.fetchChallengesLiveIfNeeded({
      brandId: this.props.match.params.id,
      page: ""
    });
    this.props.fetchChallengesUpcomingIfNeeded({
      brandId: this.props.match.params.id,
      page: ""
    });
    this.props.fetchChallengesQuickPlayIfNeeded({
      brandId: this.props.match.params.id,
      page: ""
    });
    this.props.fetchTopPlayersIfNeeded({
      brandId: this.props.match.params.id,
      page: 1
    });
    this.props.fetchNewsIfNeeded({
      page: ""
    });

    this.props.invalidateAllBrandsLeaderboardsViewAll();
    this.props.fetchBrandsLeaderboardsViewAllIfNeeded({
      brandId: this.props.match.params.id,
      page: 1,
      q: ""
    });
  }


  handleInputChange = () => {
    let searchedWord = this.search.value.trim();
    this.setState({ search: searchedWord });

    this.props.fetchBrandsLeaderboardsViewAllIfNeededAll({
      brandId: this.props.match.params.id,
      page: 1,
      q: searchedWord
    });
  };

  render() {
    const {
      brandDetails,
      brandsQuickPlayList,
      brandsUpcomingList,
      brandsLiveList,
      brandsTopPlayersList,
      newsList,
      allOnlineUserList,
      currentUserLeaderboards,
      t
    } = this.props;

    const lang = i18n.language;
    if (this.props.brandDetails.isFetching) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }

    if (
      (this.props.brandDetails.item &&
        this.props.brandDetails.item.code &&
        this.props.brandDetails.item.code === "O001") ||
      !this.props.brandDetails.item.id
    ) {
      return <NotFoundContainer />;
    }

    if (brandDetails && brandDetails.item) {
      return (
        <>
          <section className="container-fluid banner-wrapper">
            <div className="row no-gutters no-space">
              <div className="col-sm-12">
                <div className="banner dashboard d-flex flex-column align-items-center">
                  <img
                    src={brandDetails.item.brandPageHeader}
                    alt=""
                    className={
                      "dynamic-banner" +
                      this.prepareTagClass(brandDetails.item.name)
                    }
                  />
                </div>
              </div>
              <div className="tournamet-info-wrapper host-detail w-100">
                <div className="container">
                  <div className="row justify-content-end">
                    <div className="col-lg-12 col-xl-11">
                      <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-12 col-lg-auto">
                          <figure>
                            <img
                              src={
                                brandDetails.item.avatar
                                  ? brandDetails.item.avatar
                                  : AvatarDemo
                              }
                              className="mt-negative-50 profile-pic-140"
                              alt=""
                            />
                            {allOnlineUserList.includes(
                              "" + brandDetails.item.profileId + ""
                            ) ? (
                              <figcaption className="online-140" />
                            ) : null}
                          </figure>
                        </div>

                        <div className="col-12 col-lg text-center text-lg-left">
                          <div className="row">
                            <div className="col-12 col-lg host-heading">
                              <h1 className="mb-3">{brandDetails.item.name}</h1>
                              <h4>
                                <span className="bold">
                                  {brandDetails.item.followersCount}
                                </span>
                                {brandDetails.item.followersCount > 0
                                  ? t("followers")
                                  : t("follower")}
                                <span className="bold ml-3">
                                  {brandDetails.item.followingsCount}
                                </span>{" "}
                              </h4>
                            </div>
                            <div className="col-12 col-lg-auto d-flex flex-column align-items-center align-items-lg-end justify-content-center">
                              <div className="mb-4 d-flex w-100">
                                {brandDetails.item &&
                                brandDetails.item.profile &&
                                brandDetails.item.profile.length ? (
                                  brandDetails.item.profile.length == 1 ? (
                                    <span
                                      className={
                                        "a btn  rounded-xs w-100 minw-180  ml-3 btn-outline-" + platformParams['colorClassSufix']
                                      }
                                      onClick={e => {
                                        this.showChatListSidebar({
                                          id: brandDetails.item.profile[0].id,
                                          userName:
                                            brandDetails.item.profile[0].name,
                                          profileUrl:
                                            brandDetails.item.profile[0]
                                              .avatarUrl
                                        });
                                      }}
                                    >
                                      Message
                                    </span>
                                  ) : (
                                    <ProfileMessageDropdownComponent
                                      profiles={brandDetails.item.profile}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}

                                {parseInt(brandDetails.item.profileId) !==
                                parseInt(this.props.userId) ? (
                                  brandDetails.item.isFollowing > 0 ? (
                                    <span
                                      className={
                                        "a btn  text-white rounded-xs w-100 ml-2 minw-180 btn-" + platformParams['colorClassSufix']
                                      }
                                      onClick={() => {
                                        this.props.unfollowBrandFormSubmit({
                                          followeeId: brandDetails.item.id
                                        });
                                      }}
                                    >
                                      {t("unfollow")}
                                    </span>
                                  ) : (
                                    <span
                                      className={
                                        "a btn  text-white rounded-xs w-100 ml-2 minw-180 btn-" + platformParams['colorClassSufix']
                                      }
                                      onClick={() => {
                                        this.props.followBrandFormSubmit({
                                          followeeId: brandDetails.item.id
                                        });
                                      }}
                                    >
                                      {t("follow")}
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="d-flex align-items-center justify-content-start social-media">
                                <ul className="list-inline mb-0 d-flex">
                                  {brandDetails.item.socialFacebook ? (
                                    <li className="list-inline-item">
                                      <a
                                        href={brandDetails.item.socialFacebook}
                                      >
                                        {" "}
                                        <img
                                          src={platformParams['facebook']}
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {brandDetails.item.socialTwitter ? (
                                    <li className="list-inline-item">
                                      <a href={brandDetails.item.socialTwitter}>
                                        <img
                                          src={platformParams['twitter']}
                                          alt=""
                                        />{" "}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  {brandDetails.item.socialInstagram ? (
                                    <li className="list-inline-item">
                                      <a
                                        href={brandDetails.item.socialInstagram}
                                      >
                                        <img
                                          src={platformParams['instagram']}
                                          alt=""
                                        />{" "}
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                                {brandDetails.item.redirectUrl ? (
                                  <h6 className="semi-bold ml-4 ">
                                    <a
                                      href={brandDetails.item.redirectUrl}
                                      target="blank"
                                      className={"text-" + platformParams['colorClassSufix']}
                                    >
                                      {brandDetails.item.redirectUrl}
                                    </a>
                                  </h6>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="[ container-fluid ] live-tournament ">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">
                        {t("live")} {t("navTournaments")}
                      </h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {brandsLiveList.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/host/" +
                            this.props.match.params.id +
                            "/challenges/live"
                          }
                          target="_blank"
                          className={"view-all d-flex align-items-center " + platformParams['className']}
                        >
                          {t("viewAll")}
                          <span>
                            <img src={platformParams['navRightArrow']} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament" />
              {brandsLiveList.length > 0 ? (
                <TournamentCardContainer
                  list={brandsLiveList.map((brandLive, index) => {
                    return {
                      ...brandLive,
                      cardClass: "card popular-arcadex",
                      cardHeaderClass: "card-header light"
                    };
                  })}
                  showAd={
                    process.env.REACT_APP_PLATFORM !== "nickx" ? false : false
                  }
                  challengeType="Live"
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noTournamentFound")}</h6>
                </div>
              )}
              {/* <div className="row">
                <div className="col-sm-12  mt-6 d-flex d-lg-none justify-content-center align-items-start">
                  <img
                    src="images/adv/adv-14.png"
                    className="banner-advertising web-square"
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </section>

         {/* {
         process.env.REACT_APP_PLATFORM !== "mtn" &&  */}
          <section className="[ container-fluid ] live-tournament ">
            {brandDetails.item.prizeImage ? (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center ">
                    <div
                      className="banner-advertising xlg d-lg-block mt-6"
                      id="adv-2"
                    >
                      <img
                        src={brandDetails.item.prizeImage}
                        alt=""
                        className="d-lg-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-10 ">
                    <div className="col d-flex justify-content-start">
                      <h2 className="bold">{t("officialLeaderboard")}</h2>
                    </div>
                    <div className="col-auto text-right d-flex justify-content-end">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          ref={input => (this.search = input)}
                          onChange={this.handleInputChange}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text bg-white">
                            <img src={searchImg} width="14px" alt="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament" />

              <BrandLeaderboard
                type="challenge"
                userData={this.props.user}
                data={brandDetails.item}
                // leaderboard={[
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4095,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy1713",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4095,
                //     profileName: "dummy474747474",
                //     profileAvatarUrl: null
                //   },
                //   {
                //     id: 4644,
                //     points: 2579,
                //     profileId: 4053,
                //     profileName: "dummy85858585",
                //     profileAvatarUrl: null
                //   }
                // ]}
                currentUserLeaderBoard={currentUserLeaderboards}
                t={t}
                search={this.state.search}
              />
            </div>
          </section>
         {/* } */}
          <section className="[ container-fluid ] popular-host section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Top {t("challengePage.players")}</h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {brandsTopPlayersList.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/host/" +
                            this.props.match.params.id +
                            "/topPlayer"
                          }
                          target="_blank"
                          className={"view-all d-flex align-items-center " + platformParams['className']}
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={platformParams['navRightArrow']} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament" />
              {brandsTopPlayersList.length > 0 ? (
                <BrandsTopPlayerComponent
                  list={brandsTopPlayersList.map((topPlayer, index) => {
                    return {
                      ...topPlayer,
                      cardClass: "card popular-arcadex",
                      cardHeaderClass: "card-header light"
                    };
                  })}
                />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noPlayerFound")}</h6>
                </div>
              )}
              {/* {process.env.REACT_APP_PLATFORM !== "nickx" ? (
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 d-flex justify-content-center ">
                      <div
                        className="banner-advertising sm d-none d-lg-block mt-6"
                        id="adv-2"
                      >
                        <img
                          src={
                            process.env.REACT_APP_PLATFORM === "nickx"
                              ? adv11Nickx
                              : adv11
                          }
                          alt=""
                          className="d-none d-lg-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </section>

          <section className="[ container-fluid ] upcoming-tournament section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">
                        {t("upcoming")} {t("navTournaments")}
                      </h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {brandsUpcomingList.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/host/" +
                            this.props.match.params.id +
                            "/challenges/upcoming"
                          }
                          target="_blank"
                          className={"view-all d-flex align-items-center "  + platformParams['className']}
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={platformParams['navRightArrow']} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {brandsUpcomingList.length > 0 ? (
                  <TournamentCardContainer
                    list={brandsUpcomingList.map((brandUpcoming, index) => {
                      return {
                        ...brandUpcoming,
                        cardClass: "card popular-arcadex",
                        cardHeaderClass: "card-header light"
                      };
                    })}
                    showAd={
                      process.env.REACT_APP_PLATFORM !== "nickx" ? false : false
                    }
                    challengeType="Upcoming"
                  />
                ) : (
                  <div className="col">
                    <h6 className="text-center">{t("noTournamentFound")}</h6>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="[ container-fluid ] quick-play section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">{t("quickPlay")}</h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {brandsQuickPlayList.length > 0 ? (
                        <a
                          href={
                            "/" +
                            lang +
                            "/host/" +
                            this.props.match.params.id +
                            "/challenges/quickplay"
                          }
                          target="_blank"
                          className={"view-all d-flex align-items-center "  + platformParams['className']}
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={platformParams['navRightArrow']} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament" />
              {brandsQuickPlayList && brandsQuickPlayList.length > 0 ? (
                <DashboardQuickPlay list={brandsQuickPlayList} />
              ) : (
                <div className="col">
                  <h6 className="text-center">{t("noTournamentFound")}</h6>
                </div>
              )}
              {/* <div className="row">
                <div className="col-sm-12  my-5 d-flex d-lg-none justify-content-center align-items-start">
                  <img
                    src="images/adv/adv-14.png"
                    className="banner-advertising web-square"
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </section>

          <section className="[ container-fluid ] news section-border-top section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="mb-5-half bold">{t("navNews")}</h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      {newsList.length > 0 ? (
                        <a
                          href="news"
                          className={"view-all d-flex align-items-center mb-5-half "  + platformParams['className'] }
                        >
                          {t("viewAll")}{" "}
                          <span>
                            <img src={platformParams['navRightArrow']} alt="" />
                          </span>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {newsList && newsList.length > 0 ? (
                  <DashboardNews list={newsList} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">{t("noNewsFound")}</h6>
                  </div>
                )}
                {/* 
                {newsList.length > 0 ? (
                  <TournamentCardContainer
                    list={newsList.map((news, index) => {
                      return {
                        ...news,
                        cardClass: "card popular-arcadex",
                        cardHeaderClass: "card-header light"
                      };
                    })}
                  />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No tournaments found.</h6>
                  </div>
                )} */}
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return <></>;
    }
  }
}
export default withTranslation()(BrandComponent);
