import React, { Component } from "react";
import TournamentCardComponent from "../../Tournaments/TournamentCardComponent";
import loadinggif from "../../../assets/images/loading.gif";
import platformParams from "../../../utils/platformParams";

class ChallengesBrandViewAllComponent extends Component {

  componentDidMount() {
    const { fetchBrandChallengesViewAllIfNeeded, invalidateAllBrandChallengesViewAll, page } = this.props;
    invalidateAllBrandChallengesViewAll();
    fetchBrandChallengesViewAllIfNeeded({ brandId: this.props.match.params.id, page: 1 });
  }
  
  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    let { page } = this.props;    
    return (
      <section className="container-fluid my-tournament-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">{ this.props.match.params.description } Tournaments</h2>
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                {this.props.ChallengesBrandViewAll.items.length > 0
                  ? this.props.ChallengesBrandViewAll.items.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>
                        <TournamentCardComponent
                          {...tournament}
                          cardClass="card card-my-tournament"
                          brandAvatarClass="profile-pic-56"
                          challengeType="Brand"
                          order={i+1}
                        />
                      </div>
                    ))
                  : ""}
              </div>  
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {
                    this.props.ChallengesBrandViewAll.items.length > 0 ? (
                      !this.props.ChallengesBrandViewAll.isLastPage ? (
                        <button
                          onClick={() => {
                            this.props.invalidateBrandChallengesViewAll();
                            this.props.fetchBrandChallengesViewAllIfNeeded({
                              brandId: this.props.match.params.id,
                              page: page
                            });
                          }}
                          className={"btn btn-" + platformParams['baseButtonClassSufix'] + " dark btn-xs"}
                        >
                          Load More
                        </button>
                      ) : this.props.ChallengesBrandViewAll.isFetching ? (
                        <img src={loadinggif} alt="" />
                      ) : (
                        ""
                      )
                    ) : this.props.ChallengesBrandViewAll.isFetching ? (
                      <img className="text-center" src={loadinggif} alt="" />
                    ) : (
                      <span>No Tournament Found</span>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ChallengesBrandViewAllComponent;
