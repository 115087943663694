import CreateRaffleComponent from "../components/Raffles/CreateRaffleComponent";
import { connect } from "react-redux";

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRaffleComponent);
