import { connect } from "react-redux";
import DashboardFreeToPlayGamesViewAllComponent from "../components/Dashboard/DashboardFreeToPlayGames/DashboardFreeToPlayGamesViewAllComponent";
import { freeToPlayViewAllList } from "../actions/categories";

const mapDispatchToProps = {
  fetchFreeToPlayViewAllIfNeeded: freeToPlayViewAllList.fetchIfNeeded,
  invalidateFreeToPlayViewAll: freeToPlayViewAllList.invalidate,
  invalidateAllFreeToPlayViewAll: freeToPlayViewAllList.invalidateAll
};

const mapStateToProps = state => {
  const { categories } = state;
  return {
    freeToPlayViewAll: categories.freeToPlayViewAll,
    page: categories.freeToPlayViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardFreeToPlayGamesViewAllComponent);
