import React, { Component } from "react";
import { connect } from "react-redux";
import CardImage from "../../assets/images/card-sm.jpg";
import Avatar from "../../assets/images/avatar-demo.png";
import VisualTimerImgNick from "../../assets/images/svg/visual-timer-nick.svg";
import VisualTimerImg from "../../assets/images/svg/visual-timer.svg";
import { refactorTitle } from "./../../utils/misc";
import Countdown from "./Countdown";
import { JoinRafflesDataForm } from "../../actions/forms";
import { withTranslation } from "react-i18next";
import platformParams from "../../utils/platformParams";
class RaffleCardComponent extends Component {
  addDefaultCardImg(ev) {
    ev.target.src = CardImage;
  }
  addDefaultBrandAvatarImg(ev) {
    ev.target.src = Avatar;
  }

  startEndTimehandler() {
    window.location.reload();
  }

  prepareTagClass(orderNo) {
    return "gtmEnterRaffle-R" + orderNo;
  }

  render() {
    const { allOnlineUserList, t } = this.props;

    return (
      <div className="card enter-raffles">
        <div className="card-body">
          <img
            className="card-img"
            alt=""
            src={this.props.itemUrl}
            onError={this.addDefaultCardImg}
          />
          <div className="card-info text-center d-flex flex-column align-items-center justify-content-center">
            <figure className="w-100">
              <img
                src={this.props.brandAvatar}
                className="profile-pic-56"
                alt=""
                onError={this.addDefaultBrandAvatarImg}
              />
              {allOnlineUserList.includes(this.props.organizerId) ? (
                <figcaption className="online-56" />
              ) : null}
              <figcaption className="d-flex align-items-center justify-content-center ">
                <h5 className="bold text-white">
                  {refactorTitle(this.props.itemDescription, 50)}
                </h5>
              </figcaption>
            </figure>
            <span
              className={
                "btn btn-" +  platformParams['baseButtonClassSufix2']+ " btn-block dark " +
                this.prepareTagClass(this.props.index)
              }
              onClick={() => {
                this.props.joinRafflesDataFormSubmit({
                  raffleId: this.props.id
                });
                window.dataLayer &&  window.dataLayer.push({
                  'event': "gtmEnterRaffle-R" + this.props.id,
                  'Click Classes': "R" + (this.props.id),
                  'raffleId': this.props.id,
                  'brandId': this.props.brandId,
                  'brandName': this.props.name
                })
              }}
            >
              {this.props.entryFee} {t("tokens")}
            </span>
          </div>
        </div>
        <div className="card-footer text-center">
          <div className="raffle-timer">
            <img
              src={platformParams['visualTimer']}
              alt=""
            />
            <h5
              className={
                "bold text-" + platformParams['colorClassSufix'] +
                " mt-2"
              }
            >
              <Countdown
                action={this.startEndTimehandler}
                targetDate={this.props.timeOfEnd}
                format={{ hour: "HH", minute: "MM", second: "SS" }}
                totalHours={true}
                leadingZero
                t={t}
              />
            </h5>
            {this.props.displayEnteredCount ? (
              <p className="fs-12 semi-bold mt-3">
                {this.props.numberOfEntries} X Entered
              </p>
            ) : (
                ""
              )}
          </div>
        </div>
      </div>
    );
  }
}

const defaultProps = {
  displayEnteredCount: false,
  id: 602,
  timeOfStart: "2018-12-31 07:09:09+00",
  timeOfEnd: "2019-01-01 06:30:09+00",
  winner: null,
  brandId: "20",
  name: "Red Bull eSports ",
  duration: "Day",
  durationId: "1",
  entryFee: "100",
  itemDescription: "did i recreate myself?",
  itemUrl: CardImage,
  points: "0",
  brandAvatar: Avatar,
  isRepeating: "false",
  joinRafflesDataFormSubmit: JoinRafflesDataForm.submit
};
RaffleCardComponent.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    userId: state.user.item.id,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(
  mapStateToProps,
  defaultProps
)(withTranslation()(RaffleCardComponent));
