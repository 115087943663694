import { connect } from "react-redux";
import FriendsFollowingsViewAllComponent from "../../components/FriendsProfile/FriendsFollowers/FriendsFollowingsViewAllComponent";
import { friendsFollowingViewAll, friendsProfile } from "../../actions/profiles";

const mapDispatchToProps = {
  fetchFriendsProfileIfNeeded: friendsProfile.fetchIfNeeded,
  invalidateFriendsProfileDetails: friendsProfile.invalidate,

  fetchFriendsFollowersViewAllIfNeeded: friendsFollowingViewAll.fetchIfNeeded,
  invalidateAllFriendsFollowersViewAll: friendsFollowingViewAll.invalidate
};

const mapStateToProps = state => {
  const { profiles } = state;
  return {
    friendDetails : profiles.profileOfFriend || null,
    friendsFollowersList: profiles.friendsFollowingViewAll || null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsFollowingsViewAllComponent);
