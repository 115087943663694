import moment from "moment";

export function diffNow(diffDate, oneMetric = false) {
  const now = moment();
  const exp = moment(diffDate);

  const diffDuration = moment.duration(exp.diff(now));
  let days;

  const diffDaysRaw = diffDuration.asDays();

  if (diffDaysRaw < 0) {
    days = Math.ceil(diffDaysRaw);
  } else {
    days = Math.floor(diffDaysRaw);
  }

  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();
  if (days === 0)
    return (
      (hours !== 0 ? hours + (hours !== 1 ? " hours " : " hour ") : "") +
      (hours === 0
        ? minutes !== 0
          ? minutes + (minutes !== 1 ? " minutes" : " minute")
          : ""
        : "")
    );
  return (
    days +
    (days !== 1 ? " days " : " day ") +
    (oneMetric === false
      ? hours !== 0
        ? hours + (hours !== 1 ? " hours" : " hour")
        : ""
      : "")
  );
}

export function isAfter(date1, date2) {
  const now = moment();
  const d1 = date1 === "" ? now : moment(date1);
  const d2 = date2 === "" ? now : moment(date2);
  return d1.diff(d2) <= 0;
}


export function convertDateForMessage(dateTime, todayAgo = false) {
  const today = moment().startOf("day");
  const day = moment(dateTime);
  if (day.isSame(today, "day")) {
    return todayAgo ? day.fromNow() : day.format("HH:mm");
  } else {
    return day.format("MMM Do");
  }
}


export function isLater(date1, date2) {
  const now = moment();
  const d1 = date1 === "" ? now : moment(date1);
  const d2 = date2 === "" ? now : moment(date2);
  return d1.diff(d2) <= 0;
}

/**
 * Find number of day between two date
 */
export function getNoOfDay(timeOfStart, timeOfEnd) {
  const timeDiffStart = diffNow(timeOfStart, true);
  const timeDiffEnd = diffNow(timeOfEnd, true);
  const alreadyStarted = isAfter(timeOfStart, "");
  const alreadyFinished = isAfter(timeOfEnd, "");

  if (alreadyStarted && !alreadyFinished) {
    return "Ends in " + timeDiffEnd.split("-").join(" ");
  } else if (alreadyFinished) {
    return "Ended " + timeDiffEnd.split("-").join(" ");
  } else if (!alreadyStarted && !alreadyFinished) {
    return "Starting in " + timeDiffStart.split("-").join(" ");
  } else {
    return "";
  }
}

export function timeSince(date, oneMetric = false) {
  let date1 = +moment(date);

  let seconds = Math.floor((+moment() - date1) / 1000);

  var interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + " years";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes";
  }
  return Math.floor(seconds).toString() + " seconds";
}

export function ValidURL(str) {
  let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}


export function IntervalTimer(callback, interval) {
  var timerId, startTime, remaining = 0;
  var state = 0; //  0 = idle, 1 = running, 2 = paused, 3= resumed

  this.pause = function () {
    if (state != 1) return;
    
    remaining = interval - (new Date() - startTime);
    window.clearInterval(timerId);
    state = 2;
  };
  
  this.resume = function () {
    if (state != 2) return;

    state = 3;
    window.setTimeout(this.timeoutCallback, remaining);
  };

  this.timeoutCallback = function () {
    if (state != 3) return;

    callback();

    startTime = new Date();
    timerId = window.setInterval(callback, interval);
    state = 1;
  };
  
  this.stop = function(){
    if(state == 0) return;

    clearInterval(timerId);
    clearTimeout(this.timeoutCallback);
    state = 0;
  }

  startTime = new Date();
  timerId = window.setInterval(callback, interval);
  state = 1;
}