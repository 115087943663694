import React, { Component } from "react";
import { connect } from "react-redux";
import { unsubscribeSuccess } from "../../../actions/modals";
import { unfollowForm } from "../../../actions/forms";
import { AllMsgClear } from "../../../actions/alerts";
import "./UnsubscribeSuccessModal.scss";
import Modal from "../Modal";
import { APP_MESSAGES } from "../../../services/config/messages";
import avatar3x from "../../../assets/images/avatar-demo.png";
import { withTranslation } from "react-i18next";
import {
  billingForgotPasswordForm,
  billingUnsubscribeForm
} from "../../../actions/vas";

class UnsubscribeModal extends Component {
  constructor(props) {
    super(props);
    this.hideUnsubscribeSuccess = this.hideUnsubscribeSuccess.bind(this);

    this.state = {
      isLandScape: false
    };
  }

  hideUnsubscribeSuccess() {
    this.props.unsubscribeSuccessClose();
    // this.props.billingUnsubscribeFormResetError("phonenumber");
    this.props.AllMsgClear();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideUnsubscribeSuccess}
          isOpen={this.props.isUnsubscribeSuccessOpen}
          disableOuterClick={false}
          modalClass="modal modal-unfollow modal-change-password"
          withFooter={true}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4"></div>
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h5>{t("unsubscribeSuccess")}</h5>
              </div>
            </div>

          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  unsubscribeSuccessOpen: unsubscribeSuccess.open,
  unsubscribeSuccessClose: unsubscribeSuccess.close,
  AllMsgClear,
  // billingUnsubscribeFormSubmit: billingUnsubscribeForm.submit,

  // billingUnsubscribeFormReset: billingUnsubscribeForm.reset,
  // billingUnsubscribeFormResetField: billingUnsubscribeForm.resetField,
  // billingUnsubscribeFormUpdateField: billingUnsubscribeForm.updateField,
  // billingUnsubscribeFormSetError: billingUnsubscribeForm.setError,
  // billingUnsubscribeFormSetSuccess: billingUnsubscribeForm.setSuccess,
  // billingUnsubscribeFormResetError: billingUnsubscribeForm.resetError,
  // billingUnsubscribeFormResetSuccess: billingUnsubscribeForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isUnsubscribeSuccessOpen: state.modals.isUnsubscribeSuccessOpen,
    // phoneNumber: state.user.item ? state.user.item.phoneNumber : 0,
    isLogging: state.login.isLogging,
    // unSubscribeForm: state.forms.unsubscribeform
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UnsubscribeModal));
