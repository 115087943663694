import { connect } from "react-redux";
import HostTopPlayersViewAllComponent from "../../components/Brand/ViewAll/HostTopPlayersViewAllComponent";
import { hostsTopPlayersViewAll, brandDetails } from "../../actions/brands";

const mapDispatchToProps = {
  fetchBrandDetailsIfNeeded: brandDetails.fetchIfNeeded,
  invalidateBrandDetails: brandDetails.invalidate,
  
  fetchTopPlayersIfNeeded: hostsTopPlayersViewAll.fetchIfNeeded,
  invalidateTopPlayersIfNeeded: hostsTopPlayersViewAll.invalidate,
  invalidateAllTopPlayersIfNeeded: hostsTopPlayersViewAll.invalidateAll
};

const mapStateToProps = state => {
  return {
    userId: state.user.item ? state.user.item.id : null,
    brandDetails: state.brands.brandDetails || null,
    hostsTopPlayersList: state.brands.topPlayersViewAll
      ? state.brands.topPlayersViewAll
      : [],
    page: state.brands.topPlayersViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostTopPlayersViewAllComponent);
