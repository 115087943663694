import { connect } from "react-redux";
import BrandComponent from "../../components/Brand/BrandComponent";
import {
  brandDetails,
  brandsLive,
  brandsQuickPlay,
  brandsUpcoming,
  brandsTopPlayers
} from "../../actions/brands";
import { chatListSidebar } from "../../actions/sidebars";
import { openChatHistory } from "../../actions/chathistory";
import { newsList } from "../../actions/news";
import { followBrandForm, unfollowBrandForm } from "../../actions/forms";
import { brandLeaderboardsViewAll } from "../../actions/challenges";

const mapDispatchToProps = {
  fetchBrandDetailsIfNeeded: brandDetails.fetchIfNeeded,
  invalidateBrandDetails: brandDetails.invalidate,

  fetchChallengesLiveIfNeeded: brandsLive.fetchIfNeeded,
  invalidateChallengesLiveIfNeeded: brandsLive.invalidate,

  fetchChallengesUpcomingIfNeeded: brandsUpcoming.fetchIfNeeded,
  invalidateChallengesUpcomingIfNeeded: brandsUpcoming.invalidate,

  fetchChallengesQuickPlayIfNeeded: brandsQuickPlay.fetchIfNeeded,
  invalidateChallengesQuickPlayIfNeeded: brandsQuickPlay.invalidate,

  fetchTopPlayersIfNeeded: brandsTopPlayers.fetchIfNeeded,
  invalidateTopPlayersIfNeeded: brandsTopPlayers.invalidate,

  fetchNewsIfNeeded: newsList.fetchIfNeeded,
  invalidateNewsIfNeeded: newsList.invalidate,

  chatListSidebarOpen: chatListSidebar.open,
  openChatHistory: openChatHistory,

  followBrandFormSubmit: followBrandForm.submit,
  unfollowBrandFormSubmit: unfollowBrandForm.submit,

  fetchBrandsLeaderboardsViewAllIfNeeded:
    brandLeaderboardsViewAll.fetchIfNeeded,
  fetchBrandsLeaderboardsViewAllIfNeededAll:
    brandLeaderboardsViewAll.fetchIfNeededAll,
  invalidateBrandsLeaderboardsViewAll: brandLeaderboardsViewAll.invalidate,
  invalidateAllBrandsLeaderboardsViewAll: brandLeaderboardsViewAll.invalidateAll
};

const mapStateToProps = state => {
  return {
    user: state.user.item ? state.user : {},
    userId: state.user.item ? state.user.item.id : null,
    brandDetails: state.brands.brandDetails || null,

    // brandDetailsccc: state.brands.brandDetails &&
    // state.brands.brandDetails.items &&
    // state.brands.brandDetails.items.currentUserLeaderboards  &&
    // state.brands.brandDetails.items.currentUserLeaderboards[0]?state.brands.brandDetails.items.currentUserLeaderboards[0] : {},

    currentUserLeaderboards:
      state.brands.brandDetails &&
      state.brands.brandDetails.item &&
      state.brands.brandDetails.item.currentUserLeaderboards
        ? state.brands.brandDetails.item.currentUserLeaderboards[0]
        : {},

    brandsQuickPlayList: state.brands.quickPlay.items
      ? state.brands.quickPlay.items
      : [],
    brandsUpcomingList: state.brands.upcoming.items
      ? state.brands.upcoming.items
      : [],
    brandsLiveList: state.brands.live.items ? state.brands.live.items : [],
    brandsTopPlayersList: state.brands.topPlayers.items
      ? state.brands.topPlayers.items
      : [],
    newsList: state.news.newsList.items || [],
    followingsList: state.profiles.followingsList.items || [],
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandComponent);
