import { connect } from "react-redux";
import NewsComponent from "../../components/News/NewsComponent";
import { newsList, newsListByCategories } from "../../actions/news";
import { subscribeForm } from "../../actions/forms";

const mapDispatchToProps = {
  fetchnewsListIfNeeded: newsList.fetchIfNeeded,
  invalidatenewsList: newsList.invalidate,
  fetchNewsListByCategoriesIfNeeded: newsListByCategories.fetchIfNeeded,
  invalidateNewsListByCategories: newsListByCategories.invalidate,

  subscribeFormSubmit: subscribeForm.submit,
  subscribeFormReset: subscribeForm.reset,
  subscribeFormResetField: subscribeForm.resetField,
  subscribeFormUpdateField: subscribeForm.updateField,
  subscribeFormSetError: subscribeForm.setError,
  subscribeFormSetSuccess: subscribeForm.setSuccess
};

const mapStateToProps = state => {
  const { news } = state;
  return {
    newsListByCategories: news.newsListByCategories.items,
    newsList: news.newsList.items,
    subscribeForm: state.forms.subscribeForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsComponent);
