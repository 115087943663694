import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router, Link } from "react-router-dom";
import { Redirect } from "react-router";
import layouts from "./routes";
import AuthHoc from "./hoc/AuthHoc";
import LoginHoc from "./hoc/LoginHoc";
import Helmet from "react-helmet";

/* Start Analytics Code */
import ReactGa from "react-ga";
import TagManager from "react-gtm-module";
import i18n from "./i18n";
import { isNgCountry } from "./utils/misc";
import platformParams from "./utils/platformParams";

export const GA_TRACKING = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
};
ReactGa.initialize(GA_TRACKING);
ReactGa.pageview(window.location.pathname + window.location.search);
!isNgCountry() && TagManager.initialize(tagManagerArgs);

/* End Analytics Code */
const lang = i18n.language;

const changeLanguage = lng => {
  i18n.changeLanguage(lng);
  if (lng === "fr") {
    localStorage.setItem("language", 2);
  } else {
    localStorage.setItem("language", 1);
  }
};

let App = props => {
  if (lang != props.match.params.locale) {
    changeLanguage(props.match.params.locale);
    if (localStorage.getItem("language") == null) {
      localStorage.setItem("language", 1);
    }
  }

  let envTitle = platformParams['envTitle'];
  let favicon = platformParams['favicon'];
  return (
    <>
      <Helmet>
        <title>{envTitle}</title>
        <link rel="shortcut icon" href={favicon} />
      </Helmet>
      <Switch>
        {layouts.map((layout, i) => {
          return layout.routes.map((route, i) => (
            <Route
              key={i}
              path={props.match.url + route.path}
              // path={route.path}
              exact={route.exact}
              render={props => {
                let Auth = route.auth
                  ? AuthHoc(route.container)
                  : route.allowAfterLogin
                  ? route.container
                  : LoginHoc(route.container);
                return (
                  <layout.layout {...props}>
                    <Auth {...props} />
                  </layout.layout>
                );
              }}
            />
          ));
        })}
      </Switch>
    </>
  );
};

export default App;
