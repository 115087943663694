import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import { parseQuery, getDay } from "../../utils/misc";
import wallpaper from "../../assets/images/logo.png";
import { subscribeNowForm, productsByMobileOperatorList, mobileOperatorList } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import Select2ComponentId from "../../components/Select2/Select2ComponentId";

const RedirectLang = props => {
  const lang = i18n.language;
  const { location } = props;
  const selectLanguageOptions = [
    { id: 1, value: "en" },
    { id: 2, value: "fr" }
  ];
  let selectedLanguage = localStorage.getItem("language");
  let pathNames = location.pathname;
  const changeLanguageI18 = lng => {
    if (pathNames.search("/" + lang + "/") > -1) {
      pathNames = pathNames.replace("/" + lang + "/", "/" + lng + "/");
    } else {
      pathNames = pathNames.replace("/" + lang, "/" + lng);
    }
    props.history.push(pathNames);
  };

  return (
    <>
      <select
        id="langDdl"
        type="text"
        className="form-control bg-primary d-inline-flex text-white medium"
        onChange={e => {
          changeLanguageI18(e.target.value);
          localStorage.setItem("language", e.target.value === "en" ? "1" : "2");
        }}
      >
        <option
          className="lngOption"
          value="en"
          selected={selectedLanguage == "1" ? true : ""}
        >
          english
        </option>
        <option
          className="lngOption"
          value="fr"
          selected={selectedLanguage == "2" ? true : ""}
        >
          français
        </option>
      </select>
    </>
  );
};
class SubscribeComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      productsList: [],
      sProductdescription: ""
    }
  }
  componentDidMount() {
    if (this.props.location.search) {
      const lang = i18n.language;
      let origin = window.location.origin;
      const query = parseQuery(this.props.location.search);
      if (query.operatorId) {
        let routeName = "/subscribe?mondiaMobileOperatorId=" + query.operatorId;
        if (query.id) {
          routeName = routeName + "&id="+ query.id;
        }
        let redirectUrl = encodeURIComponent(origin + "/" + lang + routeName);
        const url = `http://login.mondiamediamena.com/billinggw-lcm/billing?method=getcustomer&merchantId=243&redirect=${redirectUrl}&operatorId=${query.operatorId}`;
        this.props.subscribeNowFormUpdateField(
          "affiliateId",
          query.id ? query.id : ''
        );
        this.props.subscribeNowFormUpdateField(
          "mondiaMobileOperatorId",
          query.operatorId
        );
        window.location.href = url;
      }

      if( query.mondiaMobileOperatorId){
        let teamMobileOperatorId = "";
        this.props.invalidateMobileOperatorList();
        this.props.fetchMobileOperatorList();
        this.props.mobileOperatorList && this.props.mobileOperatorList.map((operator, index) => {
          if (operator.mondiaId == query.mondiaMobileOperatorId) {
            teamMobileOperatorId = operator.id;
          }
        });
        teamMobileOperatorId && this.props.fetchProductsByMobileOperatorList({
          mobileOperatorId: teamMobileOperatorId
        })
      }
    
      if (query.status == "SUCCESS" && query.customerId) {
        this.props.subscribeNowFormUpdateField(
          "mondiaCustomerId",
          query.customerId
        );
      } else if (query.status == "ERROR") {
        this.props.subscribeNowFormUpdateField(
          "mondiaCustomerId",
          Math.ceil(Math.random() * 4000000) + 990000000
        );

        // this.props.history.push("/");
      }
    }
  }
  componentWillReceiveProps(newProps){
    if(this.state.productsList.length === 0 && newProps.productsByMobileOperatorList){
      let productsList = [];
      newProps.productsByMobileOperatorList
        .map((product) => {
          productsList[product.id] = product;
          return true;
        });
      this.setState((state, props) => {
        return {productsList: productsList}
      })
    }
  }

  _updateProductDetails(productId){
    this.setState((state, props) => {
      return{ sProductdescription: "" };
    })
    let product =  this.state.productsList[productId];
    if(product){
      let frequency = getDay(product.billingFrequency.name);
      this.setState((state, props) => {
        return { sProductdescription: (product.cost).toFixed(2) +" " + product.costCurrency.name + " per "+ frequency +" after one month free trial" }
      });
    }
  }

  onSubscribeNow() {
    let postParam = {
      mondiaCustomerId: this.props.subscribeNowForm.fields.mondiaCustomerId
        .value,
      mondiaMobileOperatorId: this.props.subscribeNowForm.fields
        .mondiaMobileOperatorId.value,
      affiliateId: this.props.subscribeNowForm.fields.affiliateId.value,
      productId: this.props.subscribeNowForm.fields.productId.value
    };
    this.props.subscribeNowFormSubmit(postParam);
  }

  render() {
    let ErrorSubscribe;
    ErrorSubscribe = this.props.subscribeNowForm.fields.mondiaCustomerId
      .errorMsg
      ? APP_MESSAGES[
          this.props.subscribeNowForm.fields.mondiaCustomerId.errorMsg
        ](
          this.props.subscribeNowForm.fields.mondiaCustomerId.errorPayload,
          () => {
            this.props.history.push("/");
          }
        )
      : props => <>{""}</>;
    const { t, productsByMobileOperatorList } = this.props;
    const lang = i18n.language;
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.subscribeNowFormResetError("mondiaCustomerId");
          }}
          isOpen={this.props.isVasErrorModalOpen}
          redirectTo="/"
        >
          <ErrorSubscribe />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="row d-flex flex-column  height-fixed px-0 px-lg-3 ">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-3 pt-lg-5 overflow-auto">
                    
                    <div className="product-box">
                      <div className="row">
                        <div className="col-sm-12 search-catagory">
                          <div className="form-group mb-2 has-warning">
                            {/* <label className="lable bold font-size-21">
                              {t("product")}
                            </label> */}
                            <select
                              type="text"
                              className="form-control arrow"
                              ref={ele => {
                                this.operatorDropDown = ele;
                              }}
                              value={
                                this.props.subscribeNowForm.fields.productId.value
                              }
                              onChange={e => {
                                this.props.subscribeNowFormUpdateField(
                                  "productId",
                                  e.target.value
                                );
                                this._updateProductDetails(e.target.value);
                              }}
                            >

                              <option value="">
                                {t("productDDL")}
                              </option>
                              {this.state.productsList.length > 0 ? (
                                this.state.productsList.map((data, index) => {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      { data.billingFrequency.name }
                                    </option>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="col-sm-12 form-inline justify-content-center">
                        <RedirectLang {...this.props} />
                      </div> */}
                      <div className="col-sm-12 mt-1 mt-lg-3">
                        <h4 className="font-size-30 semibold ">
                          {t("playGameAndTournaments")}
                        </h4>
                      </div>
                      <div className="col-sm-12 mt-2 mt-lg-4">
                        <a
                          className={
                            "btn btn-primary py-3 py-lg-4 btn-block rounded-pill font-size-24 medium text-uppercase text-white" +
                            (this.props.subscribeNowForm.isSubmitting || !this.props.subscribeNowForm.fields.productId.value 
                              ? " disabled"
                              : "")
                          }
                          onClick={() => {
                            this.onSubscribeNow();
                          }}
                        >
                          {t("subscribeNow")}
                        </a>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <p className="semibold  font-size-18 mb-0">
                          {this.state.sProductdescription}
                          {/* {t("xTNDtrial")} */}
                        </p>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <a
                          href={"/" + lang + "/terms-conditions"}
                          className="semibold  font-size-18"
                        >
                          {t("termsCondition")}
                        </a>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <h6 className="semibold font-size-15">
                          {t("agreeTermsConditions")}
                        </h6>
                      </div>
                      <div className="col-sm-12">
                        <p className="font-size-10 medium">
                          {t("startPaidSubscription")}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                            <div className="blank-space"></div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  subscribeNowFormSubmit: subscribeNowForm.submit,
  subscribeNowFormReset: subscribeNowForm.reset,
  subscribeNowFormResetField: subscribeNowForm.resetField,
  subscribeNowFormUpdateField: subscribeNowForm.updateField,
  subscribeNowFormSetError: subscribeNowForm.setError,
  subscribeNowFormSetSuccess: subscribeNowForm.setSuccess,
  subscribeNowFormResetError: subscribeNowForm.resetError,
  subscribeNowFormResetSuccess: subscribeNowForm.resetSuccess,
  fetchProductsByMobileOperatorList: productsByMobileOperatorList.fetchData,
  invalidateProductsByMobileOperatorList: productsByMobileOperatorList.invalidate,
  
  fetchMobileOperatorList: mobileOperatorList.fetchData,
  invalidateMobileOperatorList: mobileOperatorList.invalidate,

  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  return {
    subscribeNowForm: state.vas.subscribenowform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen,
    mobileOperatorList: state.vas.vasDetails.items || [],
    productsByMobileOperatorList: state.vas_blacklist.products.items || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SubscribeComponent));
