import { connect } from "react-redux";
import ChallengesUpcomingViewAllComponent from "../../components/Dashboard/DashboardUpcoming/ChallengesUpcomingViewAllComponent";
import { challengesUpcomingViewAll } from "../../actions/challenges";

const mapDispatchToProps = {
  fetchUpcomingChallengesViewAllIfNeeded:
    challengesUpcomingViewAll.fetchIfNeeded,
  invalidateUpcomingChallengesViewAll: challengesUpcomingViewAll.invalidate,
  invalidateAllUpcomingChallengesViewAll:
    challengesUpcomingViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { challenges, user } = state;
  return {
    upcomingViewAll: challenges.upcomingViewAll,
    page: challenges.upcomingViewAll.page,
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesUpcomingViewAllComponent);
