import React, { Component } from "react";
import { connect } from "react-redux";
import { activateUserIfNeeded,deactivateRequest } from "../actions/redirect";


class UserActivateContainer extends Component {
  componentDidMount() {
    const { email, hash } = this.props.match.params;
    this.props.activateUserIfNeeded(email, hash);
  }
  componentWillReceiveProps(nextProps) {    
    if (
      this.props.isUserActivated === false &&
      nextProps.isUserActivated === true
    ) {
      this.props.deactivateRequest();
      this.props.history.push("/");
    }
  }
  render() {
    return <>{this.props.isUserActivated ? "" : <h1>Activating....</h1>}</>;
  }
}


const mapDispatchToProps = {
  activateUserIfNeeded,
  deactivateRequest
};

const mapStateToProps = state => {
  const { redirect } = state;
  const { isUserActivated } = redirect || {
    isUserActivated: false
  };
  return {
    isUserActivated
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActivateContainer);
