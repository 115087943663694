import React, { Component } from "react";
import CardImage from "../../assets/images/card-sm.jpg";

class GameCardComponent extends Component {
  render() {
    const { cardClass, gameCardImage, gameCardName } = this.props;
    return (
      <div className={cardClass}>
        <img className="card-img" src={gameCardImage} alt="" />
        {/* <div className="card-img-overlay d-flex justify-content-center align-items-end">
          <p className="text-white info">{gameCardName}</p>
        </div> */}
      </div>
    );
  }
}
const defaultProps = {
  id: 1,
  gameCardName: "Racer",
  gameCardImage: CardImage,
  cardClass: "card"
};
GameCardComponent.defaultProps = defaultProps;
export default GameCardComponent;
