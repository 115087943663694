import React, { Component } from "react";
import MobileGameComponent from "../components/Games/MobileGameComponent";

class MobileGameContainer extends Component {
  render() {
    return <MobileGameComponent gameId={this.props.match.params.gameName} />;
  }
}

export default MobileGameContainer;
