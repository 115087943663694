import React, { Component } from "react";
import DashboardQuickPlayCardComponent from "./DashboardQuickPlayCardComponent";
import loadinggif from "../../../assets/images/loading.gif";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class TournamentsQuickPlayComponent extends Component {
  componentDidMount() {
    const {
      fetchQuickPlayChallengesViewAllIfNeeded,
      invalidateAllQuickPlayChallengesViewAll
    } = this.props;
    invalidateAllQuickPlayChallengesViewAll();
    fetchQuickPlayChallengesViewAllIfNeeded({
      brandId: "",
      page: 1
    });
  }
  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    let { page , t, history} = this.props;
    return (
      <section className="container-fluid my-tournament-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">{t("quickPlay")}</h2>
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                {this.props.quickPlayViewAll.items.length > 0
                  ? this.props.quickPlayViewAll.items.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>
                        <DashboardQuickPlayCardComponent
                          // history={history}
                          // tagPart1="gtmJoinQuickPlay-"
                          // tagPart2={"QuickPlay" + (i + 1)}
                          {...tournament}
                          key={i}
                          order={i + 1}
                        />
                      </div>
                    ))
                  : ""}
              </div>
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {this.props.quickPlayViewAll.items.length > 0 ? (
                    !this.props.quickPlayViewAll.isLastPage ? (
                      <button
                        onClick={() => {
                          this.props.invalidateQuickPlayChallengesViewAll();
                          this.props.fetchQuickPlayChallengesViewAllIfNeeded({
                            brandId: "",
                            page: page
                          });
                        }}
                        className={"btn btn-"+ platformParams['colorClassSufix'] +" dark btn-xs"}
                      >
                        {t("loadMore")}
                      </button>
                    ) : this.props.quickPlayViewAll.isFetching ? (
                      <img src={loadinggif} alt="" />
                    ) : (
                      ""
                    )
                  ) : this.props.quickPlayViewAll.isFetching ? (
                    <img className="text-center" src={loadinggif} alt="" />
                  ) : (
                    <span>{t("noTournamentFound")}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(TournamentsQuickPlayComponent);
