import { connect } from "react-redux";
import ArcadeGamesByCategoryViewAllComponent from "../components/Dashboard/DashboardFreeToPlayGames/ArcadeGamesByCategoryViewAllComponent";
import {  
  arcadeGamesByCategoryViewAll
} from "../actions/categories";

const mapDispatchToProps = {
  fetchArcadeGamesByCategoryViewAllIfNeeded: arcadeGamesByCategoryViewAll.fetchIfNeeded,
  invalidateArcadeGamesByCategoryViewAll: arcadeGamesByCategoryViewAll.invalidate,    
  invalidateAllArcadeGamesByCategoryViewAll: arcadeGamesByCategoryViewAll.invalidateAll    
};

const mapStateToProps = state => {  
  const { categories } = state;  
  return {
    ArcadeGamesByCategoryViewAll : categories.ArcadeGamesByCategoryViewAll,
    page: categories.ArcadeGamesByCategoryViewAll.page,  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArcadeGamesByCategoryViewAllComponent);
