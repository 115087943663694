import { connect } from "react-redux";
import FriendsChallengesViewAllComponent from "../../components/FriendsProfile/FriendsChallengesViewAllComponent";
import { friendsChallengesViewAll, friendsProfile } from "../../actions/profiles";

const mapDispatchToProps = {
  fetchFriendsProfileIfNeeded: friendsProfile.fetchIfNeeded,
  invalidateFriendsProfileDetails: friendsProfile.invalidate,

  fetchFriendsTournamentsViewAllIfNeeded: friendsChallengesViewAll.fetchIfNeeded,
  invalidateFriendsTournamentsViewAll: friendsChallengesViewAll.invalidate,
  invalidateAllFriendsTournamentsViewAll: friendsChallengesViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { profiles } = state;
  return {
    friendDetails : profiles.profileOfFriend || null,
    friendsTournamentsList: profiles.friendsChallengesViewAll || null,
    page: profiles.friendsChallengesViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsChallengesViewAllComponent);
