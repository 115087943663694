import { connect } from "react-redux";
import MyTournamentsComponent from "../../components/MyTournaments/MyTournamentsComponent";
import {
  mytournamentsFinished,
  mytournamentsPlaying
} from "../../actions/challenges";
import { toggleMyTournamenPlaying } from "../../actions/toggles";

const mapDispatchToProps = {
  fetchMyTournamentsIfNeeded: mytournamentsPlaying.fetchIfNeeded,
  fetchMyTournamentsEndedIfNeeded: mytournamentsFinished.fetchIfNeeded,

  invalidateMyTournaments: mytournamentsPlaying.invalidate,
  invalidateMyTournamentsEnded: mytournamentsFinished.invalidate,
  invalidateAllMyTournaments: mytournamentsPlaying.invalidateAll,
  invalidateAllMyTournamentsEnded: mytournamentsFinished.invalidateAll,
  myTournamentToggle: toggleMyTournamenPlaying.toggle
};
const mapStateToProps = state => {
  const { challenges, user } = state;
  const {
    isFetching: isFetchingPlaying,
    isLastPage: isPlayingRecordFinish,
    items: mytournamentPlayingList
  } = challenges.mplaying || {
    isFetching: true,
    isLastPage: false,
    items: []
  };

  const {
    isFetching: isFetchingEnded,
    isLastPage: isEndedRecordFinish,
    items: mytournamentEndedList
  } = challenges.mfinished || {
    isFetching: true,
    isLastPage: false,
    items: []
  };
  return {
    mytournamentPlayingList,
    mytournamentEndedList,
    isFetchingPlaying,
    isFetchingEnded,
    isPlayingRecordFinish,
    isEndedRecordFinish,
    mpage: state.challenges.mplaying.page,
    fpage: state.challenges.mfinished.page,
    isMyTournamentsPlaying: state.toggles.myTournamenPlaying,
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTournamentsComponent);
