import { connect } from "react-redux";
import FriendsProfileComponent from "../../components/FriendsProfile/FriendsProfileComponent";
import {
  friendsProfile,
  friendsFollowers,
  friendsTournaments,
  friendsFollowings,
  friendsChallengesViewAll
} from "../../actions/profiles";
import { chatListSidebar } from "../../actions/sidebars";
import { openChatHistory } from "../../actions/chathistory";
import { followForm, unfollowForm } from "../../actions/forms";

const mapDispatchToProps = {
  fetchFriendsProfileIfNeeded: friendsProfile.fetchIfNeeded,
  invalidateFriendsProfileDetails: friendsProfile.invalidate,

  fetchFriendsFollowersIfNeeded: friendsFollowers.fetchIfNeeded,
  invalidateFriendsFollowersDetails: friendsFollowers.invalidate,

  fetchFriendsFollowingsIfNeeded: friendsFollowings.fetchIfNeeded,
  invalidateFriendsFollowingsDetails: friendsFollowings.invalidate,

  fetchFriendsTournamentsIfNeeded: friendsTournaments.fetchIfNeeded,
  invalidateFriendsTournamentsDetails: friendsTournaments.invalidate,
  chatListSidebarOpen: chatListSidebar.open,
  openChatHistory: openChatHistory,
  followFormSubmit: followForm.submit,
  unfollowFormSubmit: unfollowForm.submit,

  fetchFriendsTournamentsViewAllIfNeeded: friendsChallengesViewAll.fetchIfNeeded,
  invalidateFriendsTournamentsViewAll: friendsChallengesViewAll.invalidate,
  invalidateAllFriendsTournamentsViewAll: friendsChallengesViewAll.invalidateAll
};

const mapStateToProps = state => {
  return {
    userId: state.user.item ? state.user.item.id : null,
    isFetching: state.profiles.profileOfFriend.isFetching,
    profile: state.profiles.profileOfFriend.item || [],
    friendsFollowersList: state.profiles.friendsFollowersList || null,
    friendsFollowingsList: state.profiles.friendsFollowingList || null,
    //friendsTournamentsList: state.profiles.friendsTournamentsList.items || null,
    allOnlineUserList: state.socketusers.allOnlineUserList,

    friendsTournamentsList: state.profiles.friendsChallengesViewAll || null,
    page: state.profiles.friendsChallengesViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsProfileComponent);
