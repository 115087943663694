import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../components/navbars/NavBar";
import Footer from "../components/navbars/Footer/Footer";
import IncludeModals from "../components/IncludeModals";
import ToastrContainer from "../../components/Toastr/ToastrContainer";
import platformParams from "../../utils/platformParams";

class MainLayout extends Component {
  render() {
    if (
      this.props.isAnyModalOpen ||
      this.props.isAnySidebarOpen ||
      this.props.isGameFullScreen ||
      process.env.REACT_APP_DEACTIVATED == "ON"
    ) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
    return (
      <>
        <section
          className={
            "mainSection" + (!this.props.isLoggedIn ? " premium" : " premium")
          }
        >
          {this.props.fetchCount > 0 ? (
            <div
              className={
                "load-bar " +
                platformParams['baseColor']
              }
            >
              <div className="bar" />
              <div className="bar" />
            </div>
          ) : (
            ""
          )}
          {/* <div className="go-premium">
            sfjksopdgj dfjg pdjfgopjdfopj 
          </div> */}
          <NavBar />
          <div
            className={
              (this.props.isAnyModalOpen ||
              this.props.isAnySidebarOpen ||
              this.props.isGameFullScreen
                ? "disable-scroll"
                : "") +
              " " +
              (this.props.isAnyModalOpen ? "d-none d-lg-block" : "")
            }
          >
            <div className={"mainContainer"}>{this.props.children}</div>
            <Footer />
            <section className="container-fluid">
              <div className="row">
                <div className="blank-space d-block d-lg-none" />
              </div>
            </section>
          </div>
          <IncludeModals />
          <div
            className={this.props.fetchCount > 0 ? "ui-blocker" : "d-none"}
          />
          <div
            className={
              "overlay" + (this.props.isAnySidebarOpen ? " active" : "")
            }
          />
        </section>
        <ToastrContainer />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { modals, sidebars } = state;

  const { isAnyModalOpen } = modals || {
    isAnyModalOpen: false
  };
  const { isAnySidebarOpen } = sidebars || {
    isAnySidebarOpen: false
  };
  return {
    isLoggedIn: state.login.isLoggedIn,
    isAnyModalOpen,
    isAnySidebarOpen,
    fetchCount: state.loaders.fetchCount,
    submitCount: state.loaders.submitCount,
    isGameFullScreen: state.toggles.gameFullScreen
  };
};

export default connect(mapStateToProps)(MainLayout);
