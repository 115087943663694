import { connect } from "react-redux";
import ChallengesBrandViewAllComponent from "../../components/Dashboard/DashboardFreeToPlayGames/ChallengesBrandViewAllComponent";
import {  
  challengesBrandViewAll
} from "../../actions/challenges";

const mapDispatchToProps = {
  fetchBrandChallengesViewAllIfNeeded: challengesBrandViewAll.fetchIfNeeded,
  invalidateBrandChallengesViewAll: challengesBrandViewAll.invalidate,    
  invalidateAllBrandChallengesViewAll: challengesBrandViewAll.invalidateAll,    
};

const mapStateToProps = state => {  
  const { challenges } = state;  
  return {
    ChallengesBrandViewAll : challenges.ChallengesBrandViewAll,
    page: challenges.ChallengesBrandViewAll.page,  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesBrandViewAllComponent);
