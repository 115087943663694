import React, { Component } from "react";
import { connect } from "react-redux";
import { createAvatar } from "../../../actions/modals";
import "./CreateAvtarModal.scss";
import ModalV2 from "../ModalV2";
import avtar from "../../../assets/images/avatar-demo.png";
import camerasvg from "../../../assets/images/svg/camera.svg";
import { createAvatarForm } from "../../../actions/forms";
import { fileUpload } from "../../../services/fileUpload.service";
import { APP_MESSAGES } from "../../../services/config/messages";
import navClose from "../../../assets/images/svg/nav-close.svg";
import { withTranslation, Trans } from "react-i18next";

class CreateAvtarModal extends Component {
  constructor(props) {
    super(props);
    this.showCreateAvtar = this.showCreateAvtar.bind(this);
    this.hideCreateAvtar = this.hideCreateAvtar.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.state = {
      isLandScape: false
    };
  }
  showCreateAvtar() {
    this.props.createAvatarOpen();
  }
  hideCreateAvtar() {
    this.props.createAvatarClose();
  }
  uploadFile(event) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.createAvatarFormSetError("avatar", "FILE_TYPE");
        return false;
      } else if (fileSize > 2) {
        this.props.createAvatarFormSetError("avatar", "FILE_SIZE");
        return false;
      }
      this.props.createAvatarFormResetError("avatar");
      fileUpload(event.target.files)
        .then(url => {
          this.props.createAvatarFormUpdateField("avatar", url);
        })
        .catch(() => {
          console.log(this);
        });
      this.props.createAvatarFormReset(createAvatarForm);
    } else {
      this.props.createAvatarFormUpdateField("avatar", "");
    }
  }
  render() {
    let Error;
    Error = this.props.createAvatarForm.fields.avatar.errorMsg
      ? APP_MESSAGES[this.props.createAvatarForm.fields.avatar.errorMsg](
          this.props.createAvatarForm.fields.avatar.errorPayload,
          () => {
            this.props.createAvatarFormResetError("avatar");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <ModalV2
          showCloseBtn={false}
          modalCrossSvgClass={true}
          handleHide={this.hideCreateAvtar}
          isOpen={this.props.isCreateAvatarOpen}
          disableOuterClick={true}
          withError={this.props.createAvatarForm.fields.avatar.error}
        >
          <div className="card-popup create-avatar">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="mb-3 bold">{t("createYourAvatar")}</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Error />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 text-center">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.props.createAvatarForm.isSubmitting) {
                      this.props.createAvatarFormSubmit({
                        avatarUrl: this.props.createAvatarForm.fields.avatar
                          .value
                      });
                    }
                  }}
                >
                  <div className="form-group avatar d-flex justify-content-center">
                    {!this.props.createAvatarForm.fields.avatar.value ? (
                      <input
                        type="file"
                        accept="image/*"
                        name="img[]"
                        className="file"
                        onChange={this.uploadFile}
                        ref={input => (this.inputElement = input)}
                      />
                    ) : (
                      ""
                    )}

                    <div className="avatar-wrapper">
                      <img
                        src={
                          this.props.createAvatarForm.fields.avatar.value
                            ? this.props.createAvatarForm.fields.avatar.value
                            : avtar
                        }
                        className="profile-pic-200"
                        alt=""
                      />
                      {this.props.createAvatarForm.fields.avatar.value ? (
                        <span
                          className="a remove-avatar"
                          onClick={e => {
                            e.preventDefault();
                            this.props.createAvatarFormReset(createAvatarForm);
                          }}
                        >
                          <img src={navClose} alt="" />
                        </span>
                      ) : (
                        ""
                      )}

                      {!this.props.createAvatarForm.fields.avatar.value ? (
                        <div
                          className="upload-button"
                          onClick={() => this.inputElement.click()}
                        >
                          <img src={camerasvg} className="a img-fluid" alt="" />
                          <p className="a choose-photo">{t("choosePhoto")}</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className={
                      "btn btn-pink test123 dark btn-block mt-5 iphone-SE-top" +
                      (this.props.createAvatarForm.isSubmitting
                        ? " disable"
                        : "")
                    }
                  >
                    {t("next")}
                  </button>
                </form>
              </div>
            </div>
            <div className="row privacy-policy">
              <div className="col-sm-12 text-center mt-2 pb-3 pb-sm-0 mt-sm-5">
                <small className="text-white">
                  <Trans i18nKey="max2MbJpgFormat">
                    Max. 2 MB (JPG or PNG formats) <br /> Recommended size:
                    210px x 210px
                  </Trans>
                </small>
              </div>
            </div>
          </div>
        </ModalV2>
      </>
    );
  }
}
const mapDispatchToProps = {
  createAvatarOpen: createAvatar.open,
  createAvatarClose: createAvatar.close,
  createAvtarClose: createAvatar.modalclose,

  createAvatarFormSubmit: createAvatarForm.submit,
  createAvatarFormReset: createAvatarForm.reset,
  createAvatarFormResetField: createAvatarForm.resetField,
  createAvatarFormUpdateField: createAvatarForm.updateField,
  createAvatarFormSetError: createAvatarForm.setError,
  createAvatarFormSetSuccess: createAvatarForm.setSuccess,
  createAvatarFormResetError: createAvatarForm.resetError,
  createAvatarFormResetSuccess: createAvatarForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    userId: state.user.item ? state.user.item.id : 0,
    createAvatarForm: state.forms.createAvatarForm,
    isCreateAvatarOpen: state.modals.isCreateAvatarOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateAvtarModal));
