import React, { Component } from "react";
import SwiperContainer from "../../Slider/SwiperContainer";
import FriendsFollowingsCardComponent from "./FriendsFollowingsCardComponent";

class FriendsFollowingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      conw: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }

  calculateWidth() {
    var conw = document
      .getElementsByClassName("container")[0]
      .getBoundingClientRect().width;
    var winw = document.body.getBoundingClientRect().width;
    var mleft = Math.floor((winw - conw) / 2 + 15);

    this.setState((state, props) => {
      return {
        slidesOffsetBefore: mleft,
        conw: conw,
        calculating: false
      };
    });
  }

  render() {
    const { list } = this.props;
    const params = {
      fixDrag:true,
      speed: 1000,
      slidesPerView: "auto",
      loop: false,
      spaceBetween: 5,
      slidesOffsetBefore: this.state.slidesOffsetBefore,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: this.state.slidesOffsetBefore
        },
        768: {
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          spaceBetween: 18,
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          spaceBetween: 18,
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        }
      }
    };
    return !this.state.calculating && list !== undefined && list.length > 0 ? (
      <SwiperContainer {...params}>
        {list.map((item, index) => (
          <FriendsFollowingsCardComponent {...item} key={index} />
        ))}
      </SwiperContainer>
    ) : (
      ""
    );
  }
}

export default FriendsFollowingsComponent;
