import React, { Component } from "react";
import CardImage from "../../assets/images/card-sm.jpg";
import { refactorTitle } from "./../../utils/misc";
import i18n from "../../i18n";

class ArcadeGamesCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }

  render() {
    const { id, name, thumb, freeGameImageUrl } = this.props;
    const lang = i18n.language;
    return (
      <a href={"/" + lang + "/free-game-detail/" + id}>
        <div className={"card " + this.props.cardClass}>
          <img
            className="card-img"
            src={ freeGameImageUrl }
            alt={name}
            onError={this.addDefaultSrc}
          />
          <h4 className="mt-3 bold">{refactorTitle(name, 20)}</h4>
        </div>
      </a>
    );
  }
}

const defaultProps = {
  id: 1,
  name: "MTN",
  thumb: CardImage,
  freeGameImageUrl: CardImage,
  thumbLarge: CardImage
};
ArcadeGamesCardComponent.defaultProps = defaultProps;
export default ArcadeGamesCardComponent;
