import React, { Component } from "react";
import { connect } from "react-redux";
import { famobi_detection } from "../../utils/famobiDetect";
import { finishGameChallengeForm } from "../../actions/forms";
import "./PlayGameCardComponent.scss";
import { toggleGameFullScreen } from "../../actions/toggles";
import fullScreenOpen from "../../assets/images/svg/full-screen.svg";
import fullScreenClose from "../../assets/images/svg/full-screen-exit.svg";
import UpdateDimension from "../Utils/UpdateDimension";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";

class PlayGameCardComponent extends Component {
  constructor(props) {
    super(props);
    this.deviceInfo = famobi_detection;
    this.onPostMessageReceive = this.onPostMessageReceive.bind(this);
    this.finishChallenge = this.finishChallenge.bind(this);
    this.urlTimeStamp = Math.random();
    this.fullScreenToggle = this.fullScreenToggle.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.entered = this.entered.bind(this);
    this.left = this.left.bind(this);
    this.state = {
      count: 0,
      showFullScreen: false,
      gameScreenHeight: "390px",
      paddingLeft: "250px",
      paddingRight: "250px"
    };
  }

  componentDidMount() {
    window.removeEventListener("message", this.onPostMessageReceive);
    window.addEventListener("message", this.onPostMessageReceive);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.gameId !== this.props.gameId ||
      (!this.props.isGameFullScreen && prevProps.isGameFullScreen)
    ) {
      if (
        this.props.gameId == "nickx-tmnt-totally-turtles" ||
        this.props.gameId == "nickx-capture-the-slime" ||
        this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
        this.props.gameId == "nickx-loud-house-germ-squirmish"
      ) {
        this.setState({
          gameScreenHeight: (this.gameScreen.offsetWidth * 7) / 12 + "px"
        });
      }
    }
  }
  updateDimensions() {
    if (
      this.props.gameId == "nickx-tmnt-totally-turtles" ||
      this.props.gameId == "nickx-capture-the-slime" ||
      this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
      this.props.gameId == "nickx-loud-house-germ-squirmish"
    ) {
      this.setState({
        paddingLeft: "0px"
      });
      setTimeout(() => {
        this.setState({
          paddingLeft:
            (window.innerWidth - (this.gameObject.offsetHeight * 12) / 7) / 2 +
            "px",
          gameScreenHeight: (this.gameScreen.offsetWidth * 7) / 12 + "px"
        });
      }, 0);
    }
  }
  componentWillUnmount() {
    this.props.gameFullScreenReset();
    window.removeEventListener("message", this.onPostMessageReceive);
  }

  finishChallenge() {
    var CryptoJS = require("crypto-js");
    var passphrase = this.props.challengeDetail.token;
    var salt = CryptoJS.lib.WordArray.random(256);
    var iv = CryptoJS.lib.WordArray.random(16);
    var key = CryptoJS.PBKDF2(passphrase, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: 64 / 8,
      iterations: 999
    });

    var totalScore = this.props.finishGameChallengeForm.fields.score.value;
    var encrypted = CryptoJS.AES.encrypt(totalScore, key, { iv: iv });
    var encryptedData = {
      ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv)
    };

    let profileId = this.props.userDetail.id;
    let startOfTime = this.props.finishGameChallengeForm.fields.startGameTime
      .value;
    let date = new Date();
    let endOfTime =
      date
        .toJSON()
        .slice(0, 10)
        .replace(new RegExp("-", "g"), "/")
        .split("/")
        .join("-") +
      " " +
      date.toJSON().slice(11, 19) +
      "+00";
    let challengeId = this.props.challengeDetail.id;
    let casualGameId = this.props.challengeDetail.casualGameId;
    let postData = {
      profileId: profileId,
      challengeId: challengeId,
      casualGameId: casualGameId,
      timeOfStart: startOfTime,
      timeOfEnd: endOfTime,
      token: passphrase,
      cipherText: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv)
    };
    // this.props.finishGameChallengeFormUpdateField("score", "");
    this.props.finishGameChallengeFormSubmit(postData);
  }

  onPostMessageReceive(e) {
    let postScore = false;
    let messageObject = e.data;
    if (
      messageObject.scope === "famobi" &&
      messageObject.event === "EVENT_LEVELSTART" &&
      window.location.href.indexOf("/free-game-detail/") > -1
    ) {
      this.props.gameFullScreenReset();
      this.props.gameFullScreenToggle();
    }

    if (window.location.href.indexOf("/challenge/") > -1) {
      postScore = true;
    }
    if (postScore === true) {
      let date = new Date();
      let startOfTime =
        date
          .toJSON()
          .slice(0, 10)
          .replace(new RegExp("-", "g"), "/")
          .split("/")
          .join("-") +
        " " +
        date.toJSON().slice(11, 19) +
        "+00";
      switch (messageObject.event) {
        case "EVENT_TOTALSCORE":
          this.props.finishGameChallengeFormUpdateField(
            "startGameTime",
            startOfTime
          );
          if (messageObject.gameID == "gold-miner-tom") {
            this.props.finishGameChallengeFormUpdateField(
              "score",
              messageObject.params.totalScore.toString()
            );
          } else {
            this.props.finishGameChallengeFormUpdateField(
              "score",
              messageObject.params.totalScore.toString()
            );
            this.finishChallenge();
          }
          break;

        case "EVENT_LEVELSCORE":
          if (messageObject.gameID == "candy-bubble") {
            this.props.finishGameChallengeFormUpdateField(
              "score",
              messageObject.params.levelScore.toString()
            );
          }
          break;
        case "EVENT_LEVELSTART":
          this.props.finishGameChallengeFormUpdateField(
            "startGameTime",
            startOfTime
          );
          this.props.gameFullScreenReset();
          this.props.gameFullScreenToggle();
          break;
        case "EVENT_LEVELRESTART":
          this.props.finishGameChallengeFormUpdateField(
            "startGameTime",
            startOfTime
          );
          break;
        case "EVENT_LEVELFAIL":
          if (
            messageObject.gameID == "gold-miner-tom" ||
            messageObject.gameID == "candy-bubble"
          ) {
            if (this.props.finishGameChallengeForm.fields.score.value) {
              this.finishChallenge();
            } else {
              this.props.finishGameChallengeFormUpdateField("score", "0");
              this.finishChallenge();
            }
          }
        default:
          if (messageObject.event === undefined) {
          }
      }
    }
  }

  checkIframeLoaded() {
    if (this.state.count === 1) {
      setTimeout(() => {
        this.setState({
          showFullScreen: true
        });
      }, 500);
    }
    this.setState({
      count: this.state.count + 1
    });
  }

  fullScreenToggle() {
    this.updateDimensions();
    this.props.gameFullScreenToggle();
  }

  entered() {
    this.props.changeTimer("entered");
  }

  left() {
    this.props.changeTimer("left");
  }

  render() {
    const { t } = this.props;

    return (
      <UpdateDimension onUpdate={this.updateDimensions} hasSetState={true}>
        <>
          <div
            className={
              "game-screen " +
              ((this.props.gameId == "nickx-tmnt-totally-turtles" ||
                this.props.gameId == "nickx-capture-the-slime" ||
                this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
                this.props.gameId == "nickx-loud-house-germ-squirmish") &&
              this.props.isGameFullScreen
                ? "nickx-tmnt-totally-turtles"
                : "")
            }
            ref={el => {
              this.gameScreen = el;
            }}
            style={{
              height: this.state.gameScreenHeight,
              maxHeight: this.props.isGameFullScreen
                ? "100%"
                : this.state.gameScreenHeight
            }}
          >
            <div
              ref={el => {
                this.gameObject = el;
              }}
              id="gameObject"
              className={
                this.props.gameId == "nickx-tmnt-totally-turtles" ||
                this.props.gameId == "nickx-capture-the-slime" ||
                this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
                this.props.gameId == "nickx-loud-house-germ-squirmish"
                  ? ""
                  : this.props.isGameFullScreen
                  ? "full-screen-yes"
                  : ""
              }
              style={
                this.props.isGameFullScreen &&
                (this.props.gameId == "nickx-tmnt-totally-turtles" ||
                  this.props.gameId == "nickx-capture-the-slime" ||
                  this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
                  this.props.gameId == "nickx-loud-house-germ-squirmish")
                  ? {
                      paddingLeft: this.state.paddingLeft
                    }
                  : {}
              }
            >
              {this.props.gameId != "" ? (
                <object
                  aria-label=""
                  aria-labelledby=""
                  data={
                    this.props.gameId == "nickx-tmnt-totally-turtles" ||
                    this.props.gameId == "nickx-capture-the-slime" ||
                    this.props.gameId == "nickx-dont-touch-the-bubble-wrap" ||
                    this.props.gameId == "nickx-loud-house-germ-squirmish"
                      ? "http://" +
                        this.props.gameId +
                        ".s3-website-ap-southeast-2.amazonaws.com/"
                      : "https://play.famobi.com/" +
                        this.props.gameId +
                        "/" +
                        this.props.affiliateId +
                        "/?" +
                        this.urlTimeStamp
                  }
                  onError={e => {
                    console.log(e, "object -errror");
                  }}
                  onLoad={e => {
                    this.checkIframeLoaded();
                  }}
                  onMouseEnter={() => this.entered()}
                  onMouseLeave={() => this.left()}
                />
              ) : (
                ""
              )}
              <div
                className={
                  this.props.isGameFullScreen
                    ? "gameExitButton d-md-block"
                    : "gameExitButton d-none"
                }
                onClick={() => {
                  this.props.gameFullScreenToggle();
                }}
              >
                <img src={fullScreenClose} alt="" />
                <span className="text-dark bold">Exit</span>
              </div>
            </div>
          </div>
          <div
            className={
              "a fullScreenText" +
              (this.state.showFullScreen
                ? " d-flex justify-content-center align-items-center"
                : "")
            }
            onClick={this.fullScreenToggle}
          >
            <img src={fullScreenOpen} className="fullScreenIcon mr-2" alt="" />
            {t("fullscreen")}
          </div>
        </>
      </UpdateDimension>
    );
  }
}
const defaultProps = {
  gameId: "",
  affiliateId: "A-INY7M",
  hasFamobiEvent: true
};
PlayGameCardComponent.defaultProps = defaultProps;

const mapDispatchToProps = {
  finishGameChallengeFormSubmit: finishGameChallengeForm.submit,
  finishGameChallengeFormUpdateField: finishGameChallengeForm.updateField,
  gameFullScreenReset: toggleGameFullScreen.reset,
  gameFullScreenToggle: toggleGameFullScreen.toggle
};

const mapStateToProps = state => {
  return {
    userDetail: state.user.item,
    gameData: state.challenges.gameData,
    challengeDetail: state.challenges.challengeDetails.item,
    finishGameChallengeForm: state.forms.finishGameChallengeForm,
    isGameFullScreen: state.toggles.gameFullScreen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PlayGameCardComponent));
