import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import { parseQuery, isEnabledLanguageChange } from "../../utils/misc";
import wallpaper from "../../assets/images/logo.png";
import { subscribeWrapmeForm } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import Select2ComponentId from "../Select2/Select2ComponentId";
import loadinggif from "../../assets/images/loading.gif";

const RedirectLang = props => {
  const lang = i18n.language;
  const { location } = props;
  const selectLanguageOptions = [
    { id: 1, value: "en" },
    { id: 2, value: "fr" }
  ];
  let selectedLanguage = localStorage.getItem("language");
  let pathNames = location.pathname;
  const changeLanguageI18 = lng => {
    if (pathNames.search("/" + lang + "/") > -1) {
      pathNames = pathNames.replace("/" + lang + "/", "/" + lng + "/");
    } else {
      pathNames = pathNames.replace("/" + lang, "/" + lng);
    }
    props.history.push(pathNames);
  };

  return (
    <>
      <select
        id="langDdl"
        type="text"
        className="form-control bg-primary d-inline-flex text-white medium"
        onChange={e => {
          changeLanguageI18(e.target.value);
          localStorage.setItem("language", e.target.value === "en" ? "1" : "2");
        }}
      >
        <option
          className="lngOption"
          value="en"
          selected={selectedLanguage == "1" ? true : ""}
        >
          english
        </option>
        <option
          className="lngOption"
          value="fr"
          selected={selectedLanguage == "2" ? true : ""}
        >
          français
        </option>
      </select>
    </>
  );
};
class SubscribeWrapmeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
    }
  }

  componentDidMount() {
    this.props.subscribeNowFormReset();
    if (this.props.location.search) {
      const lang = i18n.language;
      let origin = window.location.origin;
      let redirectUrl = encodeURIComponent(origin + "/" + lang + "/subscribe");
      const query = parseQuery(this.props.location.search);

      if (query.token) {        
        this.props.subscribeNowFormUpdateField("token", query.token);
        this.props.subscribeNowFormSubmit({token: query.token});
      }
    }
  }

  onSubscribeNow() {
    let postParam = {
      token: this.props.subscribeNowForm.fields.token.value
    };
    this.props.subscribeNowFormSubmit(postParam);
  }

  render() {
    let ErrorSubscribe;
    ErrorSubscribe = this.props.subscribeNowForm.fields.token.errorMsg
      ? APP_MESSAGES[this.props.subscribeNowForm.fields.token.errorMsg](
          this.props.subscribeNowForm.fields.token.errorPayload,
          () => {
            this.props.history.push("/");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    const lang = i18n.language;
    let redirectTo = "/";
    if(this.props.subscribeNowForm.fields.token.errorMsg == "SUB02"){
      redirectTo =  "/" + lang + "/billing-login";
    }
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.subscribeNowFormResetError("token");
          }}
          isOpen={this.props.isVasErrorModalOpen}
          redirectTo={redirectTo}
        >
          <ErrorSubscribe />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="row d-flex flex-column  height-fixed px-0 px-lg-3 ">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-3 pt-lg-5 overflow-auto">
                  { 
                    this.state.showLoader ? <div>
                      <div className="text-center billing-loader">
                        <div className="col-sm-12">
                          <img src={loadinggif} alt="" />
                        </div>
                      </div>
                    </div> 
                    : 
                    <>
                    <div className="row">
                      <div className="col-sm-12 form-inline justify-content-center">
                        { isEnabledLanguageChange() ? <RedirectLang {...this.props} /> : <></> }
                      </div>
                      <div className="col-sm-12 mt-1 mt-lg-3">
                        <h4 className="font-size-30 semibold ">
                          {t("playGameAndTournaments")}
                        </h4>
                      </div>
                      <div className="col-sm-12 mt-2 mt-lg-4">
                        <a
                          className={
                            "btn btn-primary py-3 py-lg-4 btn-block rounded-pill font-size-24 medium text-uppercase text-white" +
                            (this.props.subscribeNowForm.isSubmitting
                              ? " disabled"
                              : "")
                          }
                          onClick={() => {
                            this.onSubscribeNow();
                          }}
                        >
                          {t("subscribeNow")}
                        </a>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <p className="semibold  font-size-18 mb-0">
                          {t("xTNDtrial")}
                        </p>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <a
                          href={"/" + lang + "/terms-conditions"}
                          className="semibold  font-size-18"
                        >
                          {t("termsCondition")}
                        </a>
                      </div>
                      <div className="col-sm-12 mt-3 mt-lg-4">
                        <h6 className="semibold font-size-15">
                          {t("agreeTermsConditions")}
                        </h6>
                      </div>
                      <div className="col-sm-12">
                        <p className="font-size-10 medium">
                          {t("startPaidSubscription")}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                            <div className="blank-space"></div>
                        </div>
                    </div>
                    </>
                     }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  subscribeNowFormSubmit: subscribeWrapmeForm.submit,
  subscribeNowFormReset: subscribeWrapmeForm.reset,
  subscribeNowFormResetField: subscribeWrapmeForm.resetField,
  subscribeNowFormUpdateField: subscribeWrapmeForm.updateField,
  subscribeNowFormSetError: subscribeWrapmeForm.setError,
  subscribeNowFormSetSuccess: subscribeWrapmeForm.setSuccess,
  subscribeNowFormResetError: subscribeWrapmeForm.resetError,
  subscribeNowFormResetSuccess: subscribeWrapmeForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  return {
    subscribeNowForm: state.vas.subscribewrapmeform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SubscribeWrapmeComponent));
