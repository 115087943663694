import React, { Component } from "react";
import { connect } from "react-redux";
import SwiperContainer from "../Slider/SwiperContainer";
import RaffleCardComponent from "./RaffleCardComponent";
import ActiveRaffleCardComponent from "./ActiveRaffleCardComponent";
import { register } from "../../actions/modals";
import { getContainerLeftWidth } from "../../utils/misc";

class RafflesCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }
  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth(),
        calculating: false
      };
    });
  }
  render() {
    const params = {
      fixDrag: true,
      slidesPerView: "auto",
      containerClass: "swiper-container",
      spaceBetween: 25,
      loop: false,
      centeredSlides: false,
      slidesOffsetBefore: this.state.slidesOffsetBefore + 20,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          initialSlide: 0
        },
        768: {
          initialSlide: 0,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15,
          touchRatio: 1
        }
      }
    };
    const { list } = this.props;    

    if (this.props.raffleType === "enter") {
      return !this.state.calculating && list && list.length > 0 ? (
        <SwiperContainer {...params}>
          {list.map((item, index) => (
            <RaffleCardComponent
              {...item}
              key={index}
              index={index+1}
              cardLgClass="popular-arcadex"
            />
          ))}
        </SwiperContainer>
      ) : (
        ""
      );
    } else {
      return !this.state.calculating && list && list.length > 0 ? (
        <SwiperContainer {...params}>
          {list.map((item, index) => (
            <ActiveRaffleCardComponent
              {...item}
              key={index}
              index={index+1}
              cardLgClass="popular-arcadex"
            />
          ))}
        </SwiperContainer>
      ) : (
        ""
      );
    }
  }
}
const mapDispatchToProps = {
  registerOpen: register.open
};
export default connect(
  null,
  mapDispatchToProps
)(RafflesCardContainer);
