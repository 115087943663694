import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import wallpaper from "../../assets/images/logo.png";
import { billingNGHoldForm } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import loadinggif from "../../assets/images/loading.gif";

class NGBillingHoltComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let postParam = {
      mondiaSubscriptionId: this.props.billingNGHoldForm.fields.subscriptionId
        .value
    };
    this.props.billingNGHoldFormSubmit(postParam);
    this.timer = setInterval(() => this.getHoldScreenCall(), 5000);
    setTimeout(() => {
      clearInterval(this.timer);
        if(this.props.billingNGHoldForm.fields.status.value !== "verified"){
            this.props.billingNGHoldFormSetError("onhold", "ST001");
            this.props.vasError();
        }
    }, 180000);
  }

  getHoldScreenCall() {
    let postParam = {
      mondiaSubscriptionId: this.props.billingNGHoldForm.fields.subscriptionId
        .value
    };

    if (this.props.billingNGHoldForm.fields.status.value == "pending") {
      this.props.billingNGHoldFormSubmit(postParam);
    } else if (this.props.billingNGHoldForm.fields.status.value == "verified") {
      // billing registration page
      clearInterval(this.timer);
    } else {
      // console.log("ELSE..............====>");
    }
  }

  render() {
    let ErrorHold;
    ErrorHold = this.props.billingNGHoldForm.fields.onhold.errorMsg
      ? APP_MESSAGES[this.props.billingNGHoldForm.fields.onhold.errorMsg](
          this.props.billingNGHoldForm.fields.onhold.errorPayload,
          () => {
            this.props.history.push("/");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    const lang = i18n.language;

    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingNGHoldFormResetError("onhold");
          }}
          isOpen={this.props.isVasErrorModalOpen}
          redirectTo="/"
        >
          <ErrorHold />
        </ModalERROR>

        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 ">
                <div className="row d-flex flex-column height-fixed px-0 px-lg-3">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-2 pt-lg-4 overflow-auto">
                    <div>
                      <div className="text-center billing-loader">
                        <div className="col-sm-12">
                          <img src={loadinggif} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingNGHoldFormSubmit: billingNGHoldForm.submit,
  billingNGHoldFormReset: billingNGHoldForm.reset,
  billingNGHoldFormResetField: billingNGHoldForm.resetField,
  billingNGHoldFormUpdateField: billingNGHoldForm.updateField,
  billingNGHoldFormSetError: billingNGHoldForm.setError,
  billingNGHoldFormSetSuccess: billingNGHoldForm.setSuccess,
  billingNGHoldFormResetError: billingNGHoldForm.resetError,
  billingNGHoldFormResetSuccess: billingNGHoldForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  return {
    billingNGHoldForm: state.vas.billingNGHoldform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NGBillingHoltComponent));
