import { connect } from "react-redux";
import FriendsFollowersViewAllComponent from "../../components/FriendsProfile/FriendsFollowers/FriendsFollowersViewAllComponent";
import { friendsFollowersViewAll, friendsProfile } from "../../actions/profiles";

const mapDispatchToProps = {
  fetchFriendsProfileIfNeeded: friendsProfile.fetchIfNeeded,
  invalidateFriendsProfileDetails: friendsProfile.invalidate,

  fetchFriendsFollowersViewAllIfNeeded: friendsFollowersViewAll.fetchIfNeeded,
  invalidateAllFriendsFollowersViewAll: friendsFollowersViewAll.invalidate
};

const mapStateToProps = state => {
  const { profiles } = state;
  return {
    friendDetails : profiles.profileOfFriend || null,
    friendsFollowersList: profiles.friendsFollowersViewAll || null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsFollowersViewAllComponent);
