import { connect } from "react-redux";
import NewsDetailsComponent from "../../components/News/NewsDetailsComponent";
import { newsDetails } from "../../actions/news";

const mapDispatchToProps = {
  fetchNewsDetailsIfNeeded: newsDetails.fetchIfNeeded,
  invalidateNewsDetails: newsDetails.invalidate
};

const mapStateToProps = state => {
  const { news } = state;
  return {
    newsDetails: news.newsDetails.item
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsDetailsComponent);
