import { ListActionCreator, PaginationListActionCreator, DetailActionCreator } from "./creators";
import { getArcadeCategoriesViewAll, getArcadeGamesByCategoryViewAll, getArcadeGameDetail } from "../services/categories.service";
import { FreeToPlayList } from "../redux-config/listActionMap";
import { FreeToPlayViewAll, ArcadeGamesByCategoryViewAll } from "../redux-config/plistActionMap";
import { ArcadeGameDetail } from "../redux-config/detailActionMap";

export const freeToPlayList = ListActionCreator({
  ...FreeToPlayList,
  service: getArcadeCategoriesViewAll,
  dataMapper: json => json.data.data
});

export const freeToPlayViewAllList = PaginationListActionCreator({
  ...FreeToPlayViewAll,
  service: getArcadeCategoriesViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const arcadeGamesByCategoryViewAll = PaginationListActionCreator({
  ...ArcadeGamesByCategoryViewAll,
  service: getArcadeGamesByCategoryViewAll,
  dataMapper: json => json.data.data,
  lastPageMapper: json => json.data.isLastPage
});

export const arcadeGameDetail = DetailActionCreator({
  ...ArcadeGameDetail,
  service: getArcadeGameDetail,
  dataMapper: json => json.data.data,
  onResponse: (json, dispatch) => {
    if (json.data.success){
      // if (json.data.data.hasAlreadyJoined === false) {
      //   dispatch(quickChallengeJoin.open());
      // }
      //dispatch(challengesByBrand.fetchIfNeeded(json.data.data.brandId));
    }
  }
});
