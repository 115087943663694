import "babel-polyfill";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import App from "./App";
import SocketHoc from "./hoc/SocketHoc";
import "./styles/master.scss";
import { axios } from "./utils/axios";
import { logoutUserIfNeeded } from "./actions/user";
import i18n from './i18n';
import { I18nextProvider } from "react-i18next";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Redirect, withRouter } from "react-router";
import socket from "./socket";
import { messageHistoryRequest } from "./actions/messagehistory";
import { notificationsRequest } from "./actions/notification";

const { store, persistor } = createStore();

axios.interceptors.response.use(response => {
  if (
    response.data &&
    response.data.error &&
    response.data.error.code &&
    (response.data.error.code === "A002" ||
    response.data.error.code === "A001" ||
      response.data.error.code === "JWT1" ||
      response.data.error.code === "JWT2" ||
      response.data.error.code === "JWT3" || 
      response.data.error.code === "JWT5" )
    // && axios.defaults.headers.common["Authorization"]
  ) {
    store.dispatch(logoutUserIfNeeded());
  }
  return response;
});
socket.on('auth complete',()=>{
  store.dispatch(messageHistoryRequest());
  store.dispatch(notificationsRequest());
})
const MyRedirect = withRouter((props) =>
<Redirect {...props} to={props.to + props.location.search} />
)
const AppWithSocket = SocketHoc(App);
render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route path="/:locale" component={AppWithSocket} />
            <MyRedirect to="/en" />
          </Switch>
        </Router>
        {/* <AppWithSocket /> */}
      </PersistGate>
    </Provider>
  </I18nextProvider>,
  document.getElementById("root")
);
