import { connect } from "react-redux";
import HostChallengesUpcomingViewAllComponent from "../../components/Brand/ViewAll/HostChallengesUpcomingViewAllComponent";
import {
  brandDetails,
  hostChallengesUpcomingViewAll
} from "../../actions/brands";

const mapDispatchToProps = {
  fetchBrandDetailsIfNeeded: brandDetails.fetchIfNeeded,
  invalidateBrandDetails: brandDetails.invalidate,

  fetchHostChallengesUpcomingViewAllIfNeeded:
    hostChallengesUpcomingViewAll.fetchIfNeeded,
  invalidateHostChallengesUpcomingViewAll:
    hostChallengesUpcomingViewAll.invalidate,
  invalidateAllHostChallengesUpcomingViewAll:
    hostChallengesUpcomingViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { brands } = state;
  return {
    userId: state.user.item ? state.user.item.id : null,
    brandDetails: brands.brandDetails || null,
    hostChallengesUpcomingList: brands.upcomingViewAll || null,
    page: brands.upcomingViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostChallengesUpcomingViewAllComponent);
