import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import wallpaper from "../../assets/images/logo.png";
import {
  billingForgotPasswordForm,
  mobileOperatorList
} from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import {
  validateEmail,
  validateMsisdn,
  validatePassword,
  validateNumber
} from "../../utils/validators";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import { lang } from "moment";
import { getAllowedCountries } from "../../utils/misc";

const allowedCountries = getAllowedCountries();

class BillingForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.invalidateMobileOperatorList();
    this.props
      .fetchMobileOperatorList()
      .then(() => {
        if (allowedCountries.length === 1) {
          this.props.billingForgotPasswordFormUpdateField(
            "mondiaCountryId",
            allowedCountries[0]
          );
        }
      });
  }

  render() {
    let ErrorPhoneNum;
    let ErrorCountry;

    ErrorPhoneNum = this.props.billingForgotPasswordForm.fields.phonenumber
      .errorMsg
      ? APP_MESSAGES[
        this.props.billingForgotPasswordForm.fields.phonenumber.errorMsg
      ](
        this.props.billingForgotPasswordForm.fields.phonenumber.errorPayload,
        () => {
          this.props.billingForgotPasswordFormResetError("phonenumber");
        }
      )
      : props => <>{""}</>;

    ErrorCountry = this.props.billingForgotPasswordForm.fields.mondiaCountryId
      .errorMsg
      ? APP_MESSAGES[
        this.props.billingForgotPasswordForm.fields.mondiaCountryId.errorMsg
      ](
        this.props.billingForgotPasswordForm.fields.mondiaCountryId
          .errorPayload,
        () => {
          this.props.billingForgotPasswordFormResetError("mondiaCountryId");
        }
      )
      : props => <>{""}</>;

    const { t, mobileOperatorList } = this.props;
    const lang = i18n.language;

    let countryArr = [];
    let countryIds = [];

    const countryAll = [{ id: "", name: t("selectCountryDDL"), isAll: true }];

    mobileOperatorList.map((operator, index) => {
      if (operator.id && operator.mondiaStatus && operator.mondiaStatus.name == "ACTIVE") {
        if (!countryIds.includes(operator.country.id)) {
          countryArr.push({
            id: operator.id,
            name: operator.country.name,
            countryCode: operator.countryCode,
            isAll: false
          });
          countryIds.push(operator.country.id);
        }
      }
    });
    const countryOptionList = [...countryAll, ...countryArr];

    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingForgotPasswordFormResetError("phonenumber");
            this.props.billingForgotPasswordFormResetError("mondiaCountryId");
          }}
          isOpen={this.props.isVasErrorModalOpen}
        >
          <ErrorPhoneNum />
          <ErrorCountry />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 ">
                <div className="row d-flex flex-column height-fixed px-0 px-lg-3">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-2 pt-lg-4 overflow-auto">
                    <div className="row">
                      <div className="col-sm-12">
                        <p className="bold font-size-30">
                          {t("forgotArcadeX")}
                        </p>
                      </div>
                    </div>
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        if (
                          !this.props.billingForgotPasswordForm.isSubmitting
                        ) {
                          if (
                            !this.props.billingForgotPasswordForm.fields
                              .phonenumber.value
                          ) {
                            this.props.billingForgotPasswordFormSetError(
                              "phonenumber",
                              "VAS_NOT_EMPTY"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            !validateNumber(
                              this.props.billingForgotPasswordForm.fields
                                .phonenumber.value
                            )
                          ) {
                            this.props.billingForgotPasswordFormSetError(
                              "phonenumber",
                              "VAS_NUMBER_LENGTH"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            !this.props.billingForgotPasswordForm.fields
                              .mondiaCountryId.value
                          ) {
                            this.props.billingForgotPasswordFormSetError(
                              "mondiaCountryId",
                              "VAS_NOT_SELECT"
                            );
                            this.props.vasError();
                            return false;
                          }

                          let postParam = {
                            phoneNumber: this.props.billingForgotPasswordForm
                              .fields.phonenumber.value,
                            mondiaCountryId: this.props
                              .billingForgotPasswordForm.fields.mondiaCountryId
                              .value
                          };
                          this.props.billingForgotPasswordFormSubmit(postParam);
                        }
                      }}
                    >
                      <div className="box">
                        <div className="row">
                          {allowedCountries.length > 1 ? (
                            <div className="col-sm-12  search-catagory">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("selectCountry")}
                                </label>
                                <select
                                  type="text"
                                  className="form-control"
                                  ref={ele => {
                                    this.countryDropDown = ele;
                                  }}
                                  onChange={e => {
                                    // this.getOperator(e.target.value);
                                    this.props.billingForgotPasswordFormUpdateField(
                                      "mondiaCountryId",
                                      e.target.value
                                    );
                                  }}
                                >
                                  {countryOptionList.length > 0
                                    ? countryOptionList.map((data, index) => {
                                      return allowedCountries.indexOf(
                                        data.countryCode
                                      ) > -1 || data.isAll ? (
                                          <option
                                            key={index}
                                            value={data.countryCode}
                                          >
                                            {data.name}
                                          </option>
                                        ) : null;
                                    })
                                    : null}
                                </select>
                              </div>
                            </div>
                          ) : null}

                          <div className="col-sm-12">
                            <div className="form-group mb-2 has-warning">
                              <label className="lable bold font-size-21">
                                {t("enterPhoneNumber")}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder={t("placePhoneNumber")}
                                onChange={event => {
                                  this.props.billingForgotPasswordFormUpdateField(
                                    "phonenumber",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4 custom-padding">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary py-2 py-lg-3 btn-block rounded-pill font-size-24 bold text-shadow " +
                              (this.props.billingForgotPasswordForm.isSubmitting
                                ? " disabled"
                                : "")
                            }
                          >
                            {t("Confirm")}
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                          <div className="blank-space"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingForgotPasswordFormSubmit: billingForgotPasswordForm.submit,
  billingForgotPasswordFormReset: billingForgotPasswordForm.reset,
  billingForgotPasswordFormResetField: billingForgotPasswordForm.resetField,
  billingForgotPasswordFormUpdateField: billingForgotPasswordForm.updateField,
  billingForgotPasswordFormSetError: billingForgotPasswordForm.setError,
  billingForgotPasswordFormSetSuccess: billingForgotPasswordForm.setSuccess,
  billingForgotPasswordFormResetError: billingForgotPasswordForm.resetError,
  billingForgotPasswordFormResetSuccess: billingForgotPasswordForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close,

  fetchMobileOperatorList: mobileOperatorList.fetchIfNeeded,
  invalidateMobileOperatorList: mobileOperatorList.invalidate
};

const mapStateToProps = state => {
  return {
    billingForgotPasswordForm: state.vas_blacklist.billingforgotpasswordform,
    billingSubscriptionForm: state.vas.billingsubscriptionform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen,
    mobileOperatorList: state.vas.vasDetails.items || []
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BillingForgotPasswordComponent));
