import React, { Component } from "react";
import helpSupportBannerImg from "../../assets/images/help-support-banner.jpg";
import "./PrivacyPolicyComponent.scss";

class PrivacyContentFr extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div
            className={
              this.props.setclass !== "container-fluid" ? "padder-main" : ""
            }
          >
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12">
                    <h6
                      className={
                        "mb-4 bold  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      EMERGE GAMING LIMITED – POLITIQUE DE CONFIDENTIALITÉ
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      La présente Politique de confidentialité énonce notre engagement à protéger la confidentialité des renseignements personnels qui nous sont fournis, ou autrement recueillis par nous, hors ligne ou en ligne, y compris par l'intermédiaire de notre site Web et de notre application mobile{" "}
                        <span className="text-black bold">(“Site”)</span>. Dans la présente Politique de confidentialité, {" "}
                        <span className="text-black bold">“ nous, notre</span> ou{" "}
                        <span className="text-black bold">nos”</span> désignent Emerge Gaming Limited (ABN 31 004 766 376).
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Données personnelles
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Les types de renseignements personnels que nous pouvons recueillir à votre sujet comprennent :
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">votre nom ;</li>
                      <li className="mb-2">des images de vous ;</li>
                      <li className="mb-2">
                      vos coordonnées, y compris votre adresse électronique, votre adresse postale, votre adresse municipale et/ou votre numéro de téléphone ;
                      </li>
                      <li className="mb-2">votre âge et/ou votre date de naissance ;</li>
                      <li className="mb-2">
                      vos détails de carte de crédit ou de paiement (par l'intermédiaire de notre processeur de paiement tiers) ;
                      </li>
                      <li className="mb-2">
                      vos préférences et/ou opinions;
                      </li>
                      <li className="mb-2">
                      les informations que vous nous fournissez par le biais de sondages auprès de la clientèle ;
                      </li>
                      <li className="mb-2">
                      vos informations sensibles comme indiqué ci-dessous ;
                      </li>
                      <li className="mb-2">
                      des détails sur les produits et services que nous vous avons fournis et/ou sur lesquels vous avez posé des questions, et notre réponse à votre demande ;
                      </li>
                      <li className="mb-2">
                      les données de session et de géolocalisation de votre navigateur, les informations sur les appareils et le réseau, les statistiques sur les pages vues et les sessions, les sources d'acquisition, les requêtes de recherche et/ou le comportement de navigation ;
                      </li>
                      <li className="mb-2">
                      des informations sur votre accès et votre utilisation du Site, y compris par l'utilisation de cookies Internet, vos communications avec notre Site, le type de navigateur que vous utilisez, le type de système d'exploitation que vous utilisez et le nom de domaine de votre fournisseur de services Internet ;
                      </li>
                      <li className="mb-2">
                      les renseignements personnels supplémentaires que vous nous fournissez, directement ou indirectement, par votre utilisation du Site, des applications associées, des plateformes de médias sociaux associées et/ou des comptes à partir desquels vous nous autorisez à recueillir des informations ; et
                      </li>
                      <li className="mb-2">
                      toute autre information personnelle demandée par nous et/ou fournie par vous ou un tiers.
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous pouvons recueillir ces types de renseignements personnels directement auprès de vous ou de tierces parties.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Collecte et utilisation des renseignements personnels
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous pouvons recueillir, détenir, utiliser et divulguer des renseignements personnels aux fins suivantes :
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                      pour vous permettre d'accéder et d'utiliser le Site, les applications associées et les plateformes de médias sociaux associées ;
                      </li>
                      <li className="mb-2">
                      pour vous contacter et communiquer avec vous ;
                      </li>
                      <li className="mb-2">
                      pour la tenue des dossiers internes, à des fins administratives et de facturation ;
                      </li>
                      <li className="mb-2">
                      pour l'analyse, les études de marché et le développement commercial, y compris pour exploiter et améliorer le Site, les applications associées et les plateformes de médias sociaux associées ;
                      </li>
                      <li className="mb-2">
                      pour organiser des concours et/ou vous offrir des avantages supplémentaires ;
                      </li>
                      <li className="mb-2">
                      pour la publicité et le marketing, y compris pour vous envoyer des informations promotionnelles sur nos produits et services et des informations sur des tiers que nous considérons susceptibles de vous intéresser;
                      </li>
                      <li className="mb-2">
                      pour nous conformer à nos obligations juridiques et résoudre tout litige que nous pourrions avoir ; et
                      </li>
                      <li className="mb-2">
                      pour étudier votre demande d'emploi.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Divulgation de renseignements personnels à des tiers
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous pouvons divulguer des informations personnelles à :
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                      les prestataires de services tiers afin de leur permettre de fournir leurs services, y compris (entre autres) les prestataires de services informatiques, les fournisseurs de stockage de données, d'hébergement Web et de serveurs, les agents de recouvrement de créances, les prestataires de maintenance ou de résolution de problèmes, les prestataires marketing ou publicitaires, les conseillers professionnels et les opérateurs de systèmes de paiement ;
                      </li>
                      <li className="mb-2">
                      nos employés, sous-traitants et/ou entités apparentées ;
                      </li>
                      <li className="mb-2">
                      nos agents ou partenaires d'affaires existants ou potentiels ;
                      </li>
                      <li className="mb-2">
                      commanditaires ou promoteurs de tous les concours que nous organisons ;
                      </li>
                      <li className="mb-2">
                      toute personne à qui notre entreprise ou nos actifs (ou toute partie de ceux-ci) sont, ou peuvent (de bonne foi) être, transférés ;
                      </li>
                      <li className="mb-2">
                      agences d'évaluation de crédit, cours, tribunaux et organismes de réglementation, dans l'éventualité où vous ne paieriez pas les biens ou services que nous vous avons fournis ;
                      </li>
                      <li className="mb-2">
                      cours, tribunaux, autorités de réglementation et agents de la force publique, tel que requis par la loi, dans le cadre de toute procédure judiciaire actuelle ou future, ou afin d'établir, d'exercer ou de défendre nos droits légaux ; tiers, y compris les agents ou sous-traitants, qui nous aident à vous fournir des informations, produits, services ou marketing direct. Cela peut inclure des parties situées, ou qui stockent des données, en dehors de l'Australie, y compris, entre autre, en Afrique du Sud ; et
                      </li>
                      <li className="mb-2">
                      des tiers pour collecter et traiter des données, telles que Google Analytics ou d'autres entreprises pertinentes. Cela peut inclure des parties qui stockent des données en dehors de l'Australie, y compris, entre autres, en Afrique du Sud.
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      En nous fournissant des informations personnelles, vous consentez à la divulgation de vos informations en dehors de l'Australie et reconnaissez que nous ne sommes pas tenus de nous assurer que les destinataires étrangers traitent ces informations personnelles conformément à la Loi australienne sur la vie privée. Vous reconnaissez que certains tiers à l'étranger peuvent ne pas être régis par la Loi sur la protection des renseignements personnels et les principes de la Loi sur la protection des renseignements personnels de l'Australie et que si un tiers se livre à un acte ou à une pratique qui contrevient aux Principes australiens concernant la protection des renseignements personnels, il ne sera pas responsable en vertu de cette loi et vous ne pourrez demander réparation en vertu de cette dernière. 
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Comment nous traitons les renseignements personnels qui sont aussi des renseignements délicats
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Les renseignements sensibles font partie des renseignements personnels qui bénéficient d'un niveau de protection plus élevé en vertu des Principes australiens de confidentialité. {" "}
                        <span className="bold text-black">
                        Les renseignements sensibles
                        </span>{" "}
                        sont des informations relatives à votre origine raciale ou ethnique, vos opinions politiques, votre religion, vos convictions philosophiques, votre orientation ou vos pratiques sexuelles, votre casier judiciaire, vos informations relatives à la santé ou à la biométrie, votre appartenance à une association ou à un syndicat ou à d'autres associations professionnelles, vos convictions philosophiques ou vos pratiques sexuelles.
                      </li>
                      <li className="mb-2">
                      Sous réserve de votre consentement, vos informations sensibles ne peuvent être utilisées et divulguées qu'à des fins liées à l'objectif principal pour lequel elles ont été recueillies.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Vos droits et le contrôle de vos informations personnelles
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <span className="bold text-black">
                        Choix et consentement :
                        </span>{" "}
                        Veuillez lire la présente politique de confidentialité attentivement. En nous fournissant des renseignements personnels, vous consentez à ce que nous recueillions, conservions, utilisions et divulguions vos renseignements personnels conformément à la présente Politique de confidentialité. Vous n'êtes pas tenu de nous fournir des renseignements personnels ; toutefois, si vous ne le faites pas, cela pourrait avoir une incidence sur votre utilisation du présent Site ou des produits ou services offerts sur celui-ci ou par son entremise.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                        Informations provenant de tiers : 
                        </span>{" "}
                        Si nous recevons des renseignements personnels vous concernant d'un tiers, nous les protégerons conformément à la présente Politique de confidentialité. Si vous êtes une tierce partie fournissant des renseignements personnels sur quelqu'un d'autre, vous déclarez et garantissez que vous avez le consentement de cette personne pour nous fournir de tels renseignements personnels.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Restreindre : </span>Si vous avez déjà accepté que nous utilisions vos informations personnelles à des fins de marketing direct, vous pouvez changer d'avis à tout moment en nous contactant aux coordonnées suivantes.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Accès : </span> Vous pouvez demander des détails sur les renseignements personnels que nous détenons à votre sujet. Des frais administratifs peuvent être exigibles pour la communication de ces renseignements.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Correction : </span>Si vous pensez que les informations que nous détenons à votre sujet sont inexactes, périmées, incomplètes, non pertinentes ou trompeuses, veuillez nous contacter en utilisant les coordonnées ci-dessous. Nous prendrons des mesures raisonnables pour corriger toute information jugée inexacte, incomplète, trompeuse ou périmée.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Plaintes : </span> Si vous souhaitez déposer une plainte au sujet de la façon dont nous avons traité vos renseignements personnels, veuillez communiquer avec nous en utilisant les coordonnées ci-dessous et nous fournir tous les détails de la plainte. Nous enquêterons rapidement sur votre plainte et vous répondrons par écrit, en exposant les résultats de notre enquête et les mesures que nous prendrons pour traiter votre plainte.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Se désabonner :</span> Pour vous désabonner de notre base de données e-mail ou vous désabonner de nos communications (y compris les communications afférentes au marketing), veuillez nous contacter en utilisant les coordonnées ci-dessous ou vous désabonner en utilisant les options de retrait fournies dans la communication.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Stockage et sécurité
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous nous engageons à assurer la sécurité des renseignements personnels que nous recueillons. Afin d'empêcher tout accès ou divulgation non autorisé, nous avons mis en place des procédures physiques, électroniques et de gestion appropriées pour sauvegarder et sécuriser les informations personnelles et les protéger contre toute utilisation abusive, interférence, perte et accès, modification et divulgation non autorisés.
                      </li>
                      <li className="mb-2">
                      Nous ne pouvons pas garantir la sécurité des informations qui sont transmises à ou par nous sur Internet. La transmission et l'échange d'informations s'effectuent à vos propres risques. Bien que nous prenions des mesures de protection contre la divulgation non autorisée de renseignements, nous ne pouvons pas vous assurer que les renseignements personnels que nous recueillons ne seront pas divulgués d'une manière non conforme à la présente Politique de confidentialité.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Cookies et balises Web
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous pouvons utiliser des cookies sur le Site de temps à autre. Les cookies sont des fichiers texte placés dans le navigateur de votre ordinateur pour stocker vos préférences. Les cookies, par eux-mêmes, ne nous divulguent pas votre adresse e-mail ou d'autres informations personnelles identifiables. Cependant, ils permettent à des tiers, tels que Google et Facebook, de faire apparaître nos publicités sur vos médias sociaux et vos flux de médias en ligne dans le cadre de nos campagnes de reciblage. Si et quand vous choisissez de fournir au Site des informations personnelles, ces informations peuvent être liées aux données stockées dans le cookie.
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                        <span className="bold text-black">
                        Cookies strictement nécessaires. 
                        </span>{" "}
                        Il s'agit de cookies qui sont nécessaires au fonctionnement de notre Site. Ils comprennent, par exemple, les cookies qui vous permettent de vous connecter à des zones sécurisées de notre Site, d'utiliser un panier d'achat ou d'utiliser des services de facturation électronique.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                        Cookies d'analyse ou de performance. 
                        </span>{" "} Il s'agit de cookies qui nous permettent de reconnaître et de compter le nombre de visiteurs sur le Site et de voir comment les visiteurs naviguent sur le Site lorsqu'ils l'utilisent. Cela nous aide à améliorer le fonctionnement du Site, par exemple, en nous assurant que les utilisateurs trouvent facilement ce qu'ils cherchent.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                        Cookies de fonctionnalité.
                        </span>{" "}
                        Elles sont utilisées pour vous reconnaître lorsque vous revenez sur notre Site. Cela nous permet de personnaliser notre contenu pour vous et de mémoriser vos préférences (par exemple, votre choix de langue ou de région).
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Vous trouverez plus d'informations sur les différents cookies que nous utilisons et les raisons pour lesquelles nous les utilisons dans le tableau ci-dessous :
                      </li>
                    </ul>
                    <table className="table privacy-table-border">
                      <thead>
                        <tr
                          className={
                            process.env.REACT_APP_PLATFORM === "nickx"
                              ? "background-orange"
                              : "bg-secondary"
                          }
                        >
                          <th scope="col" className={"w-25 "+(process.env.REACT_APP_PLATFORM === "nickx"? "":"text-white")}>
                            Cookie
                          </th>
                          <th scope="col" className={"w-25 "+(process.env.REACT_APP_PLATFORM === "nickx"? "":"text-white")}>
                          Objectif
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Devise</td>
                          <td>
                          Ce cookie stocke la devise utilisée pour le Site (AUD)
                          </td>
                        </tr>
                        <tr>
                          <td>Langue</td>
                          <td>
                          Ce cookie stocke la langue utilisée pour le Site (English)
                          </td>
                        </tr>
                        <tr>
                          <td>PHPSESSIONID</td>
                          <td>
                          Ce cookie contient un identifiant unique pour prendre en charge des fonctions (par exemple, les derniers produits consultés) afin d'améliorer l'expérience de l'utilisateur
                          </td>
                        </tr>
                        <tr>
                          <td>newsbcsub</td>
                          <td>
                          Ce cookie est stocké une fois que l'utilisateur a complété ou fermé la fenêtre d'enregistrement, l'empêchant de réapparaître avant 7 jours
                          </td>
                        </tr>
                        <tr>
                          <td>TLSCookiesEU</td>
                          <td>
                          Ce cookie est enregistré lorsqu'un utilisateur accepte que le site utilise des cookies afin d'empêcher que la fenêtre contextuelle ne s'affiche à nouveau pendant cette session
                          </td>
                        </tr>
                        <tr>
                          <td>_ga / _gid / _gat</td>
                          <td>
                          Ces cookies sont utilisés par notre compte Google Analytics pour suivre le trafic de nos clients sur le site Web afin de nous aider à comprendre comment le Site est utilisé par nos clients
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Vous bloquez les cookies en activant le paramètre de votre navigateur qui vous permet de refuser le paramétrage de l’ensemble ou d’une partie des cookies. Toutefois, si vous utilisez les paramètres de votre navigateur pour bloquer tous les cookies (y compris les cookies essentiels), il se peut que vous ne puissiez pas accéder à l’ensemble ou à une partie de notre site.
                      </li>
                      <li className="mb-2">
                      Nous pouvons utiliser des balises Web sur le Site de temps à autre. Les balises Web (aussi appelées pixels invisibles) sont de petits morceaux de code placés sur une page Web pour surveiller le comportement du visiteur et recueillir des données sur la consultation d'une page Web par le visiteur. Par exemple, les balises Web peuvent être utilisés pour compter le nombre d'utilisateurs qui visitent une page Web ou pour transmettre un témoin au navigateur d'un visiteur qui visite cette page.
                      </li>
                      <li className="mb-2">
                      Nous pouvons utiliser Google Analytics pour collecter et traiter des données. Pour savoir comment Google utilise les données lorsque vous utilisez des sites Web ou des applications de tiers, veuillez consulter www.google.com/policies/privacy/partners/ ou toute autre URL que Google peut utiliser de temps à autre.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Liens vers d'autres sites Web
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Notre site peut contenir des liens vers d'autres sites Web. Nous n'avons aucun contrôle sur ces sites Web et nous ne sommes pas responsables de la protection et de la confidentialité des renseignements personnels que vous fournissez lorsque vous visitez ces sites Web. Ces sites Web ne sont pas régis par la présente Politique de confidentialité.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Amendements
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                      Nous pouvons, à tout moment et à notre discrétion, modifier la présente Politique de confidentialité en publiant la Politique de confidentialité modifiée sur le site. Nous vous recommandons de consulter le site régulièrement pour vous assurer que vous êtes au courant de notre Politique de confidentialité actuelle.
                      </li>
                    </ul>
                    <p className="text-black mb-4 bold">
                    Pour toute question ou avis, veuillez communiquer avec notre Agent de protection de la confidentialité à l'adresse suivante :
                    </p>
                    <p className="text-muted mb-4">
                    Emerge Gaming Limited ABN 31 004 766 376
                    </p>
                    <p className="text-muted mb-4">
                      Email:{" "}
                      <a
                        href="mailto:support@arcadex.co"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        support@arcadex.co
                      </a>
                    </p>
                    <p className="text-muted mb-4">
                      <span className="bold text-black">Dernière mise à jour :</span> 10 mai 2019
                    </p>
                    <p className="text-muted mb-4">
                      ©{" "}
                      <a
                        href="https://legalvision.com.au"
                        target="_blank"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        LegalVision
                      </a>{" "}
                      ILP Pty Ltd
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PrivacyContentFr;
