import { connect } from "react-redux";
import HostChallengesLiveViewAllComponent from "../../components/Brand/ViewAll/HostChallengesLiveViewAllComponent";
import { brandDetails, hostChallengesLiveViewAll } from "../../actions/brands";

const mapDispatchToProps = {
  fetchBrandDetailsIfNeeded: brandDetails.fetchIfNeeded,
  invalidateBrandDetailsDetails: brandDetails.invalidate,

  fetchHostChallengesLiveViewAllIfNeeded:
    hostChallengesLiveViewAll.fetchIfNeeded,
  invalidateHostChallengesLiveViewAll: hostChallengesLiveViewAll.invalidate,
  invalidateAllHostChallengesLiveViewAll:
    hostChallengesLiveViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { brands } = state;
  return {
    userId: state.user.item ? state.user.item.id : null,
    brandDetails: brands.brandDetails || null,
    hostChallengesLiveList: brands.liveViewAll || null,
    page: brands.liveViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostChallengesLiveViewAllComponent);
