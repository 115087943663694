import { connect } from "react-redux";
import ChallengesCategoryViewAllComponent from "../../components/Dashboard/DashboardFreeToPlayGames/ChallengesCategoryViewAllComponent";
import { challengesCategoryViewAll } from "../../actions/challenges";

const mapDispatchToProps = {
  fetchCategoryChallengesViewAllIfNeeded:
    challengesCategoryViewAll.fetchIfNeeded,
  invalidateCategoryChallengesViewAll: challengesCategoryViewAll.invalidate,
  invalidateAllCategoryChallengesViewAll:
    challengesCategoryViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { challenges, user } = state;
  return {
    ChallengesCategoryViewAll: challenges.ChallengesCategoryViewAll,
    page: challenges.ChallengesCategoryViewAll.page,
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesCategoryViewAllComponent);
