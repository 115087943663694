import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import wallpaper from "../../assets/images/logo.png";
import { mobileOperatorList, productsByMobileOperatorList, billingInfoForm } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import { validateNumber } from "../../utils/validators";
import UpdateDimension from "../Utils/UpdateDimension";
import { getAllowedCountries, parseQuery, isNgCountry, isZaCountry, getDay } from "../../utils/misc";

const allowedCountries = getAllowedCountries();
class BillingInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      productsList: [],
      sProductdescription: ""
    }
  }
  componentWillMount() {
    if (allowedCountries.length === 1) {
        this.props.billingInfoFormUpdateField(
            "mondiaCountryId",
            allowedCountries[0]
        );
    }
    // this.props.invalidateMobileOperatorList();
    this.props.fetchMobileOperatorList();
  }
  updateDimensions() {
    if (this.countryDropDown) {
      this.countryDropDown.blur();
    }
    if (this.operatorDropDown) {
      this.operatorDropDown.blur();
    }
  }
  _updateProduct(mobileOperatorId){
    this.setState((state, props) => {
      return{ productsList: [], sProductdescription: "" };
    })
    this.props.billingInfoFormResetField("productId");
    if(mobileOperatorId){
      this.props.fetchProductsByMobileOperatorList({
        mobileOperatorId: mobileOperatorId
      });
    }
  }
  _updateProductDetails(productId){
    this.setState((state, props) => {
      return{ sProductdescription: "" };
    })
    let product =  this.state.productsList[productId];
    if(product){
      let frequency = getDay(product.billingFrequency.name);
      this.setState((state, props) => {
        return { sProductdescription:  product.costCurrency.sign + (product.cost).toFixed(2) + " per "+ frequency +" subscription" }
      });
    }
  }

  componentWillReceiveProps(newProps){
    if(this.state.productsList.length === 0){
      let productsList = [];
      newProps.productsByMobileOperatorList
        .map((product) => {
          productsList[product.id] = product;
          return true;
        });
      this.setState((state, props) => {
        return {productsList: productsList}
      })
    }
  }

  render() {
    const lang = i18n.language;
    const { mobileOperatorList } = this.props;
    const { t } = this.props;
    let countryArr = [];
    let countryIds = [];
    const countryAll = [{ id: "", name: t("selectCountryDDL"), isAll: true }];
    const operatorAll = [{ id: "", teamId: "" ,name: t("mobileOperatorDDL") }];

    mobileOperatorList.map((operator, index) => {
      if (operator.id && operator.mondiaStatus && operator.mondiaStatus.name == "ACTIVE") {
        if (!countryIds.includes(operator.country.id)) {
          countryArr.push({
            id: operator.country.id,
            name: operator.country.name,
            countryCode: operator.countryCode,
            isAll: false
          });
          countryIds.push(operator.country.id);
        }
      }
    });
    const countryOptionList = [...countryAll, ...countryArr];

    const operators = mobileOperatorList
      .filter(
        e =>
          e["mondiaStatus"]["name"] == "ACTIVE" &&
          e.countryCode ==
            this.props.billingInfoForm.fields.mondiaCountryId.value
      )
      .map(e => {
        return { id: e.mondiaId, teamId: e.id, name: e.name };
      });
    const operatorOptionList = [...operatorAll, ...operators];

    let ErrorPhoneNum;
    let ErrorCountry;
    let ErrorMobileOperator;
    let ErrorProductId;
    ErrorPhoneNum = this.props.billingInfoForm.fields.phonenumber.errorMsg
      ? APP_MESSAGES[this.props.billingInfoForm.fields.phonenumber.errorMsg](
          this.props.billingInfoForm.fields.phonenumber.errorPayload,
          () => {
            this.props.billingInfoFormResetError("phonenumber");
          }
        )
      : props => <>{""}</>;

    ErrorCountry = this.props.billingInfoForm.fields.mondiaCountryId.errorMsg
      ? APP_MESSAGES[
          this.props.billingInfoForm.fields.mondiaCountryId.errorMsg
        ](
          this.props.billingInfoForm.fields.mondiaCountryId.errorPayload,
          () => {
            this.props.billingInfoFormResetError("mondiaCountryId");
          }
        )
      : props => <>{""}</>;

    ErrorMobileOperator = this.props.billingInfoForm.fields
      .mondiaMobileOperatorId.errorMsg
      ? APP_MESSAGES[
          this.props.billingInfoForm.fields.mondiaMobileOperatorId.errorMsg
        ](
          this.props.billingInfoForm.fields.mondiaMobileOperatorId.errorPayload,
          () => {
            this.props.billingInfoFormResetError("mondiaMobileOperatorId");
          }
        )
      : props => <>{""}</>;
    ErrorProductId = this.props.billingInfoForm.fields
      .productId.errorMsg
      ? APP_MESSAGES[
          this.props.billingInfoForm.fields.productId.errorMsg
        ](
          this.props.billingInfoForm.fields.productId.errorPayload,
          () => {
            this.props.billingInfoFormResetError("productId");
          }
        )
      : props => <>{""}</>;
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingInfoFormResetError("phonenumber");
            this.props.billingInfoFormResetError("mondiaCountryId");
            this.props.billingInfoFormResetError("mondiaMobileOperatorId");
            this.props.billingInfoFormResetError("productId");
          }}
          isOpen={this.props.isVasErrorModalOpen}
        >
          <ErrorPhoneNum />
          <ErrorCountry />
          <ErrorMobileOperator />
          <ErrorProductId />
        </ModalERROR>
        <section className="billing-background">
          <UpdateDimension onUpdate={this.updateDimensions}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7 ">
                  <div className="row d-flex flex-column px-0 px-lg-3 height-fixed">
                    <div className="col-auto d-flex justify-content-center">
                      <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                        <a href={"/" + lang}>
                          {" "}
                          <img src={wallpaper} className="img-fluid" alt="" />
                        </a>
                      </div>
                    </div>
                    <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-2 pt-lg-4 overflow-auto">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="bold font-size-30">{t("regArcadeX")}</p>
                        </div>
                      </div>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (!this.props.billingInfoForm.isSubmitting) {
                            if (
                              !this.props.billingInfoForm.fields.phonenumber
                                .value
                            ) {
                              this.props.billingInfoFormSetError(
                                "phonenumber",
                                "VAS_NOT_EMPTY"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !validateNumber(
                                this.props.billingInfoForm.fields.phonenumber
                                  .value
                              )
                            ) {
                              this.props.billingInfoFormSetError(
                                "phonenumber",
                                "VAS_NUMBER_LENGTH"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !this.props.billingInfoForm.fields.mondiaCountryId
                                .value
                            ) {
                              this.props.billingInfoFormSetError(
                                "mondiaCountryId",
                                "VAS_NOT_SELECT"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !this.props.billingInfoForm.fields
                                .mondiaMobileOperatorId.value
                            ) {
                              this.props.billingInfoFormSetError(
                                "mondiaMobileOperatorId",
                                "VAS_NOT_SELECT"
                              );
                              this.props.vasError();
                              return false;
                            }
                            if (
                              !this.props.billingInfoForm.fields.productId.value
                            ) {
                              this.props.billingInfoFormSetError(
                                "productId",
                                "VAS_NOT_SELECT"
                              );
                              this.props.vasError();
                              return false;
                            }
                            const query = parseQuery(this.props.location.search);
                            let postParam = {
                              phoneNumber: this.props.billingInfoForm.fields
                                .phonenumber.value,
                              mondiaMobileOperatorId: this.props.billingInfoForm
                                .fields.mondiaMobileOperatorId.value,
                              affiliateId: query.id ? query.id : '',
                              productId: this.props.billingInfoForm.fields.productId.value,
                            };
                            this.props.billingInfoFormSubmit(postParam);
                          }
                        }}
                      >
                        <div className="box">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("enterPhoneNumber")}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={t("placePhoneNumber")}
                                  onChange={event => {
                                    this.props.billingInfoFormUpdateField(
                                      "phonenumber",
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            {allowedCountries.length > 1 ? (
                              <div className="col-sm-12  search-catagory">
                                <div className="form-group mb-2 has-warning">
                                  <label className="lable bold font-size-21">
                                    {t("selectCountry")}
                                  </label>
                                  <select
                                    type="text"
                                    className="form-control"
                                    ref={ele => {
                                      this.countryDropDown = ele;
                                    }}
                                    onChange={e => {
                                      // this.getOperator(e.target.value);
                                      this.props.billingInfoFormUpdateField(
                                        "mondiaCountryId",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {countryOptionList.length > 0 ? (
                                      countryOptionList.map((data, index) => {
                                        return allowedCountries.indexOf(
                                          data.countryCode
                                        ) > -1 || data.isAll ? (
                                          <option
                                            key={index}
                                            value={data.countryCode}
                                          >
                                            {data.name}
                                          </option>
                                        ) : null;
                                      })
                                    ) : (
                                      <option value="">
                                        {t("selectCountryDDL")}
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </div>
                            ) : null}

                            <div className="col-sm-12 search-catagory">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("mobileOperator")}
                                </label>
                                <select
                                  type="text"
                                  className="form-control arrow"
                                  ref={ele => {
                                    this.operatorDropDown = ele;
                                  }}
                                  value={
                                    this.props.billingInfoForm.fields
                                      .mondiaMobileOperatorId.value
                                  }
                                  onChange={e => {
                                    this.props.billingInfoFormUpdateField(
                                      "mondiaMobileOperatorId",
                                      e.target.value
                                    );
                                    this._updateProduct( operatorOptionList[e.target.selectedIndex]['teamId'] ?  operatorOptionList[e.target.selectedIndex]['teamId'] : '');
                                    
                                  }}
                                >
                                  {operatorOptionList.length > 0 ? (
                                    operatorOptionList.map((data, index) => {
                                      return (
                                        <option key={index} value={data.id}>
                                          {data.name}
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <option value="">
                                      {t("mobileOperatorDDL")}
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          
                            <div className="col-sm-12 search-catagory">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("product")}
                                </label>
                                <select
                                  type="text"
                                  className="form-control arrow"
                                  ref={ele => {
                                    this.operatorDropDown = ele;
                                  }}
                                  value={
                                    this.props.billingInfoForm.fields
                                      .productId.value
                                  }
                                  onChange={e => {
                                    this.props.billingInfoFormUpdateField(
                                      "productId",
                                      e.target.value
                                    );
                                    this._updateProductDetails(e.target.value);
                                  }}
                                >

                                  <option value="">
                                    {t("productDDL")}
                                  </option>
                                  {this.state.productsList.length > 0 ? (
                                    this.state.productsList.map((data, index) => {
                                      return (
                                        <option key={data.id} value={data.id}>
                                          { data.billingFrequency.name }
                                        </option>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </select>
                              </div>
                            </div>
                          
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-3 mt-lg-4">
                            <button
                              type="submit"
                              className="btn btn-primary py-2 py-lg-3 btn-block rounded-pill font-size-24 bold text-shadow "
                            >
                              {t("Confirm")}
                            </button>
                          </div>
                          <div className="col-sm-12 mt-2">
                            <span className="text-pink-light font-size-11 bold">
                              { this.state.sProductdescription }
                            </span>
                          </div>
                          <div className="col-sm-12">
                            <h3 className="text-charcole-light bold font-size-21 mt-1 mt-lg-2">
                              {t("alreadyHaveAccount")}{" "}
                              <a
                                className="text-charcole-light"
                                href={"/" + lang + "/billing-login"}
                              >
                                {" "}
                                {t("signin")}{" "}
                              </a>
                            </h3>
                          </div>
                          <div className="col-sm-12">
                            { isZaCountry() ? 
                                <a
                                    className="text-pink-light bold font-size-18"
                                    href={"/" + lang + "/terms-conditions"}
                                >
                                    {" "}
                                    {t("termsCondition")}{" "}
                                </a>
                            : <></> }
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-3 mt-lg-3">
                            <div className="blank-space"></div>
                          </div>
                        </div>
                      </form>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UpdateDimension>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingInfoFormSubmit: billingInfoForm.submit,
  billingInfoFormReset: billingInfoForm.reset,
  billingInfoFormResetField: billingInfoForm.resetField,
  billingInfoFormUpdateField: billingInfoForm.updateField,
  billingInfoFormSetError: billingInfoForm.setError,
  billingInfoFormSetSuccess: billingInfoForm.setSuccess,
  billingInfoFormResetError: billingInfoForm.resetError,
  billingInfoFormResetSuccess: billingInfoForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close,

  fetchMobileOperatorList: mobileOperatorList.fetchData,
  invalidateMobileOperatorList: mobileOperatorList.invalidate,
  fetchProductsByMobileOperatorList: productsByMobileOperatorList.fetchData,
  invalidateProductsByMobileOperatorList: productsByMobileOperatorList.invalidate,
};

const mapStateToProps = state => {
  return {
    billingInfoForm: state.vas_blacklist.billinginfoform,
    mobileOperatorList: state.vas.vasDetails.items || [],
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen,
    productsByMobileOperatorList: state.vas_blacklist.products.items || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BillingInfoComponent));
