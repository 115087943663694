import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import wallpaper from "../../assets/images/logo.png";
import { billingLoginForm, mobileOperatorList } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { validateNumber } from "../../utils/validators";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import { lang } from "moment";
import { getAllowedCountries } from "../../utils/misc";

const allowedCountries = getAllowedCountries();
class BillingLoginComponent extends Component {
  componentWillMount() {
    this.props.invalidateMobileOperatorList();
    this.props
      .fetchMobileOperatorList()
      .then(() => {
        if (allowedCountries.length === 1) {
          this.props.billingLoginFormUpdateField(
            "mondiaCountryId",
            allowedCountries[0]
          );
        }
      });
      this.props.billingLoginFormUpdateField(
        "phonenumber",
        this.props.subscribewrapmeform.fields.phonenumber.value
      );
  }

  render() {
    let ErrorPhoneNum;
    let ErrorPassword;
    let ErrorCountry;
    ErrorPhoneNum = this.props.billingLoginForm.fields.phonenumber.errorMsg
      ? APP_MESSAGES[this.props.billingLoginForm.fields.phonenumber.errorMsg](
          this.props.billingLoginForm.fields.phonenumber.errorPayload,
          () => {
            this.props.billingLoginFormResetError("phonenumber");
          }
        )
      : props => <>{""}</>;

    ErrorPassword = this.props.billingLoginForm.fields.password.errorMsg
      ? APP_MESSAGES[this.props.billingLoginForm.fields.password.errorMsg](
          this.props.billingLoginForm.fields.password.errorPayload,
          () => {
            this.props.billingLoginFormResetError("password");
          }
        )
      : props => <>{""}</>;

    ErrorCountry = this.props.billingLoginForm.fields.mondiaCountryId.errorMsg
      ? APP_MESSAGES[
          this.props.billingLoginForm.fields.mondiaCountryId.errorMsg
        ](
          this.props.billingLoginForm.fields.mondiaCountryId.errorPayload,
          () => {
            this.props.billingLoginFormResetError("mondiaCountryId");
          }
        )
      : props => <>{""}</>;

    const { t, mobileOperatorList } = this.props;
    const lang = i18n.language;

    let countryArr = [];
    let countryIds = [];

    const countryAll = [{ id: "", name: t("selectCountryDDL"), isAll: true }];

    mobileOperatorList.map((operator, index) => {
      if (operator.id && operator.mondiaStatus && operator.mondiaStatus.name == "ACTIVE") {
        if (!countryIds.includes(operator.country.id)) {
          countryArr.push({
            id: operator.id,
            name: operator.country.name,
            countryCode: operator.countryCode,
            isAll: false
          });
          countryIds.push(operator.country.id);
        }
      }
    });
    const countryOptionList = [...countryAll, ...countryArr];

    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingLoginFormResetError("phonenumber");
            this.props.billingLoginFormResetError("password");
          }}
          isOpen={this.props.isVasErrorModalOpen}
        >
          <ErrorPhoneNum />
          <ErrorPassword />
          <ErrorCountry />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 ">
                <div className="row d-flex flex-column height-fixed px-0 px-lg-3">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-2 pt-lg-4 overflow-auto">
                    <div>
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="bold font-size-30">
                            {t("loginArcadeX")}
                          </p>
                        </div>
                      </div>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (!this.props.billingLoginForm.isSubmitting) {
                            if (
                              !this.props.billingLoginForm.fields.phonenumber
                                .value
                            ) {
                              this.props.billingLoginFormSetError(
                                "phonenumber",
                                "VAS_NOT_EMPTY"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !validateNumber(
                                this.props.billingLoginForm.fields.phonenumber
                                  .value
                              )
                            ) {
                              this.props.billingLoginFormSetError(
                                "phonenumber",
                                "VAS_NUMBER_LENGTH"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !this.props.billingLoginForm.fields.password.value
                            ) {
                              this.props.billingLoginFormSetError(
                                "password",
                                "VAS_NOT_EMPTY"
                              );
                              this.props.vasError();
                              return false;
                            }

                            if (
                              !this.props.billingLoginForm.fields
                                .mondiaCountryId.value
                            ) {
                              this.props.billingLoginFormSetError(
                                "mondiaCountryId",
                                "VAS_NOT_SELECT"
                              );
                              this.props.vasError();
                              return false;
                            }

                            let postParam = {
                              mondiaCountryId: this.props.billingLoginForm
                                .fields.mondiaCountryId.value,
                              phoneNumber: this.props.billingLoginForm.fields
                                .phonenumber.value,
                              password: this.props.billingLoginForm.fields
                                .password.value
                            };
                            this.props.billingLoginFormSubmit(postParam);
                          }
                        }}
                      >
                        <div className="box">
                          <div className="row">
                            {allowedCountries.length > 1 ? (
                              <div className="col-sm-12  search-catagory">
                                <div className="form-group mb-2 has-warning">
                                  <label className="lable bold font-size-21">
                                    {t("selectCountry")}
                                  </label>
                                  <select
                                    type="text"
                                    className="form-control"
                                    ref={ele => {
                                      this.countryDropDown = ele;
                                    }}
                                    onChange={e => {
                                      // this.getOperator(e.target.value);
                                      this.props.billingLoginFormUpdateField(
                                        "mondiaCountryId",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    {countryOptionList.length > 0 ? (
                                      countryOptionList.map((data, index) => {
                                        return allowedCountries.indexOf(
                                          data.countryCode
                                        ) > -1 || data.isAll ? (
                                          <option key={index} value={data.countryCode}>
                                            {data.name}
                                          </option>
                                        ) : null;
                                      })
                                    ) : (
                                      <option value="">
                                        {t("selectCountryDDL")}
                                      </option>
                                    )}
                                    ;
                                  </select>
                                </div>
                              </div>
                            ) : null}

                            <div className="col-sm-12">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("enterPhoneNumber")}
                                </label>
                                <input
                                  value={this.props.billingLoginForm.fields.phonenumber.value}
                                  type="number"
                                  className="form-control"
                                  placeholder={t("placePhoneNumber")}
                                  onChange={event => {
                                    this.props.billingLoginFormUpdateField(
                                      "phonenumber",
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("enterPassword")}
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder={t("labelPassword")}
                                  onChange={event => {
                                    this.props.billingLoginFormUpdateField(
                                      "password",
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12 mt-3 mt-lg-4">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary py-2 py-lg-3 btn-block rounded-pill font-size-24 bold text-shadow " +
                                (this.props.billingLoginForm.isSubmitting
                                  ? " disabled"
                                  : "")
                              }
                            >
                              {t("Confirm")}
                            </button>
                          </div>

                          <div className="col-sm-12">
                            <h3 className="text-charcole-light bold font-size-21 mt-2 mt-lg-3">
                              <a
                                className="text-charcole-light"
                                href={"/" + lang + "/billing-forgotpassword"}
                              >
                                {" "}
                                {t("forgotPasswordBilling")}{" "}
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-3 mt-lg-4">
                            <div className="blank-space"></div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingLoginFormSubmit: billingLoginForm.submit,
  billingLoginFormReset: billingLoginForm.reset,
  billingLoginFormResetField: billingLoginForm.resetField,
  billingLoginFormUpdateField: billingLoginForm.updateField,
  billingLoginFormSetError: billingLoginForm.setError,
  billingLoginFormSetSuccess: billingLoginForm.setSuccess,
  billingLoginFormResetError: billingLoginForm.resetError,
  billingLoginFormResetSuccess: billingLoginForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close,

  fetchMobileOperatorList: mobileOperatorList.fetchIfNeeded,
  invalidateMobileOperatorList: mobileOperatorList.invalidate
};

const mapStateToProps = state => {
  return {
    billingLoginForm: state.vas_blacklist.billingloginform,
    subscribewrapmeform: state.vas.subscribewrapmeform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen,
    mobileOperatorList: state.vas.vasDetails.items || []
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BillingLoginComponent));
