import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import { parseQuery, getAllowedCountries, getDay } from "../../utils/misc";
import wallpaper from "../../assets/images/logo.png";
import { mobileOperatorList, billingInfoForm, ngBillingInfoForm, billingNGAutoLoginForm, ngLoginForm, ngGetDOIUrlForm, productsByMobileOperatorList } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import loadinggif from "../../assets/images/loading.gif";

const allowedCountries = getAllowedCountries();
class NGSubscribeComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      showLoader: false,
      DOIRequestSent: false,
      productsList: [],
      sProductdescription: "",
      stopValue: "",
    }
  }
  componentDidMount() {
    if (this.props.location.search) {
      const query = parseQuery(this.props.location.search);
      if (allowedCountries.length === 1) {
        this.props.billingInfoFormUpdateField(
            "mondiaCountryId",
            allowedCountries[0]
          );
      }
      if (query.msisdn != "undefined" ) {
        this.props.billingInfoFormUpdateField(
          "phonenumber",
          query.msisdn
        );
        this.props.billingInfoFormUpdateField(
          "mondiaMobileOperatorId",
          query.mobileOperatorID
        );
        this.props.billingInfoFormUpdateField(
          "affiliateId",
          query.affiliateId
        );
        if(!(this.props.userDetail).length ){
          let postParam = {
            phoneNumber: query.msisdn,
            mondiaMobileOperatorId: query.mobileOperatorID,
            affiliateId: query.affiliateId ? query.affiliateId : '',
          };
          this.props.ngLoginFormSubmit(postParam);
        }
      } else if (query.status == "ERROR") {
        this.setState((state, props)=> {
          return {
            showLoader: true
          }
        })
        const lang = i18n.language;
        this.props.history.push( '/' + lang +"/ng-billing-info");
      }
    }
  }
  componentWillReceiveProps(newProps){
    const query = parseQuery(this.props.location.search);
    if(!this.state.DOIRequestSent && !newProps.ngGetDOIUrlForm.isSubmitting && newProps.ngLoginForm.fields.phoneNumber.error){
      this.props.invalidateMobileOperatorList();
      this.props.fetchMobileOperatorList();

      this.props.mobileOperatorList && this.props.mobileOperatorList.map((operator, index) => {
        if (operator.mondiaId == query.mobileOperatorID) {
          this.props.fetchProductsByMobileOperatorList({
            mobileOperatorId:  operator.id
          })
          if(this.props.productsByMobileOperatorList) {
            this.setState((state, props) => {
              return {
                showLoader: false,
                DOIRequestSent: true
              }
            })
          }
        }
      });
    }
    if(this.state.productsList.length === 0 && newProps.productsByMobileOperatorList){
      let productsList = [];
      newProps.productsByMobileOperatorList
        .map((product) => {
          productsList[product.id] = product;
          return true;
        });
      this.setState((state, props) => {
        return {productsList: productsList}
      })
    }
  }
  _updateProductDetails(productId){
    this.setState((state, props) => {
      return{ sProductdescription: "",stopValue:"" };
    })
    let product =  this.state.productsList[productId];
    if(product){
      let frequency = getDay(product.billingFrequency.name);
      this.setState((state, props) => {
        return { 
          sProductdescription: (product.cost).toFixed(2) +" " + product.costCurrency.name + " per "+ frequency ,
          stopValue: "stop sub"+frequency.substring(0,1)
        }
      });
    }
  }

  onSubscribeNow(productId) {
    this.setState((state, props) => {
      return {
        showLoader: true
      }
    })
    this._updateProductDetails(productId);
    const query = parseQuery(this.props.location.search);
    const lang = i18n.language;
    if(query.msisdn != "undefined" && query.mobileOperatorID){
      let origin = window.location.origin;
      let routeName = "/ng-doi-process";
      let redirectUrl = origin + "/" + lang + routeName;
      redirectUrl += "?msisdn=" + query.msisdn + "&mobileOperatorID=" + query.mobileOperatorID + "&productId=" + productId;
      let postParam = {
        msisdn: query.msisdn,
        mondiaMobileOperatorId: query.mobileOperatorID,
        redirectUrl: encodeURIComponent(redirectUrl),
        productId: productId
      };
      this.props.ngGetDOIUrlFormSubmit(postParam);
    }else{
      this.props.history.push( '/' + lang +"/ng-billing-info");
    }
  }

  render() {
    let ErrorSubscribe;
    ErrorSubscribe = this.props.billingInfoForm.fields.phonenumber.errorMsg
      ? APP_MESSAGES[
        this.props.billingInfoForm.fields.phonenumber.errorMsg
      ](
        this.props.billingInfoForm.fields.phonenumber.errorPayload,
        () => {
          this.props.history.push("/");
        }
      )
      : props => <>{""}</>;
    const { t, productsByMobileOperatorList } = this.props;
    const lang = i18n.language;
    const {stopValue} = this.state;
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingInfoFormResetError("phonenumber");
          }}
          isOpen={this.props.isVasErrorModalOpen}
          redirectTo="/"
        >
          <ErrorSubscribe />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="row d-flex flex-column  height-fixed px-0 px-lg-3 ">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-3 pt-lg-5 overflow-auto">
                    { 
                    this.state.showLoader ? <div>
                      <div className="text-center billing-loader">
                        <div className="col-sm-12">
                          <img src={loadinggif} alt="" />
                        </div>
                      </div>
                    </div> 
                    : 
                    <>
                      <div className="row">
                        <div className="col-sm-12 mt-1 mt-lg-3">
                          <h4 className="font-size-30 semibold ">
                            {t("playGameAndTournaments")}
                          </h4>
                        </div>
                        {
                          this.state.productsList.length > 0 ? (
                            this.state.productsList.map((data, index) => {
                              return(
                                <div className="col-sm-12 mt-2 mt-lg-4" key={data.id}>
                                  <button
                                    className={
                                      "btn btn-primary py-3 py-lg-4 btn-block rounded-pill font-size-24 medium text-uppercase text-white product-btn" + (this.props.billingInfoForm.isSubmitting ? " disabled" : "")
                                    }
                                    onClick={() => {
                                      this.onSubscribeNow(data.id);
                                    }}
                                  >
                                    { data.costCurrency.sign +""+ data.cost + " / " +getDay(data.billingFrequency.name)}
                                  </button>
                                </div>
                              );
                            }
                          )) : (<></>)
                        }
                        <div className="col-sm-12 mt-3 mt-lg-4">
                          <p className="semibold  font-size-18 mb-0">
                            {this.state.sProductdescription}
                            {/* {t("ngXTNDtrial")} */}
                          </p>
                        </div>
                        <div className="col-sm-12 mt-3 mt-lg-4">
                          <a
                            href={"/" + lang + "/terms-conditions"}
                            className="semibold  font-size-18"
                          >
                            {t("termsCondition")}
                          </a>
                        </div>
                        <div className="col-sm-12 mt-3 mt-lg-4">
                          <h6 className="semibold font-size-15">
                            {t("agreeTermsConditions")}
                          </h6>
                        </div>
                        {
                          this.state.productsList.length > 0 ? (
                            this.state.productsList.map((data, index) => {
                              let frequency = data.billingFrequency.name;
                              frequency = frequency.substring(0,1) + (frequency.substring(1, frequency.length)).toLowerCase();
                              let stopValue = "stop sub"+(frequency.substring(0,1)).toLowerCase();
                              return(
                                <>
                                  <div className="col-lg-3 col-4">
                                    <span className="font-size-10 medium" >For { frequency }:</span>
                                  </div>
                                  <div className="col-sm-12">
                                    <p className="font-size-10 medium text-center mb-0">
                                      <Trans i18nKey="ngStartPaidSubscription" stopValue={stopValue}>
                                        You can cancel anytime by sending {{ stopValue }} to 7772
                                      </Trans>
                                    </p>
                                  </div>
                                </>
                              );
                            }
                          )) : (<></>)
                        }
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                          <div className="blank-space"></div>
                        </div>
                      </div>
                    </>
                     }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  ngLoginFormSubmit: ngLoginForm.submit,
  ngGetDOIUrlFormSubmit: ngGetDOIUrlForm.submit,
  billingInfoFormSubmit: billingInfoForm.submit,
  ngBillingInfoFormSubmit: ngBillingInfoForm.submit,
  billingInfoFormUpdateField: billingInfoForm.updateField,
  billingInfoFormResetError: billingInfoForm.resetError,
  billingInfoFormResetSuccess: billingInfoForm.resetSuccess,
  billingNGAutoLoginFormSubmit: billingNGAutoLoginForm.submit,
  billingNGAutoLoginFormUpdateField: billingNGAutoLoginForm.updateField,
  billingNGAutoLoginFormResetError: billingNGAutoLoginForm.resetError,
  billingNGAutoLoginFormResetSuccess: billingNGAutoLoginForm.resetSuccess,

  fetchProductsByMobileOperatorList: productsByMobileOperatorList.fetchData,
  invalidateProductsByMobileOperatorList: productsByMobileOperatorList.invalidate,

  fetchMobileOperatorList: mobileOperatorList.fetchData,
  invalidateMobileOperatorList: mobileOperatorList.invalidate,
  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  const { user  } = state;  
  const {
    item: userDetail
  } = user.item || {
    item: []
  };

  return {
    billingInfoForm: state.vas_blacklist.billinginfoform,
    ngAutoLoginForm: state.vas_blacklist.ngAutoLoginForm,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen,
    ngGetDOIUrlForm: state.vas_blacklist.ngGetDOIUrlForm,
    productsByMobileOperatorList: state.vas_blacklist.products.items || [],
    mobileOperatorList: state.vas.vasDetails.items || [],
    ngLoginForm: state.vas_blacklist.ngLoginForm,
    userDetail:user.item || [],
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NGSubscribeComponent));
