import PrivacyPolicyNewComponent from "../components/PrivacyPolicy/PrivacyPolicyNewComponent";
import { connect } from "react-redux";

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicyNewComponent);
