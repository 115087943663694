import React, { Component } from "react";
import HostTopPlayerCardViewAllComponent from "./HostTopPlayersCardViewAllComponent";
import loadinggif from "../../../assets/images/loading.gif";
import NotFoundContainer from "../../../containers/NotFoundContainer";
import platformParams from "../../../utils/platformParams";

class HostTopPlayersViewAllComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.invalidateBrandDetails();

    const {
      fetchTopPlayersIfNeeded,
      invalidateAllTopPlayersIfNeeded,
      page
    } = this.props;
    invalidateAllTopPlayersIfNeeded();

    this.props.fetchBrandDetailsIfNeeded({
      brandId: this.props.match.params.id,
      profileId: this.props.userId
    });
    fetchTopPlayersIfNeeded({
      brandId: this.props.match.params.id,
      page: page
    });
  }
  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { hostsTopPlayersList, brandDetails, page } = this.props;
    
    if (brandDetails.isFetching) {
      return (
        <div className="text-center">
          <div className="col-sm-12">
            <img src={loadinggif} alt="" />
          </div>
        </div>
      );
    }
    if (!(hostsTopPlayersList.items.length > 0)) {
      return <NotFoundContainer />;
    }
    if (
      hostsTopPlayersList &&
      brandDetails &&
      brandDetails.item &&
      hostsTopPlayersList.items
    ) {
      return (
        <section className="container-fluid my-tournament-wrapper">
          <div className="row">
            <div className="container">
              <div className="padder-main">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">
                      {brandDetails.item.name}'s Top Players
                    </h2>
                  </div>
                </div>

                <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                  {this.props.hostsTopPlayersList.items.length > 0
                    ? this.props.hostsTopPlayersList.items.map(
                        (tournament, i) => (
                          <div className="col-auto mb-4" key={i}>
                            <HostTopPlayerCardViewAllComponent
                              {...tournament}
                              key={i}
                            />
                          </div>
                        )
                      )
                    : ""}
                </div>
                <div className="row mb-30 text-center">
                  <div className="col-sm-12">
                    {this.props.hostsTopPlayersList.items.length > 0 ? (
                      !this.props.hostsTopPlayersList.isLastPage ? (
                        <button
                          onClick={() => {
                            this.props.invalidateTopPlayersIfNeeded();
                            this.props.fetchTopPlayersIfNeeded({
                              brandId: this.props.match.params.id,
                              page: page
                            });
                          }}
                          className={"btn btn-" +platformParams['baseButtonClassSufix'] + " dark btn-xs"}
                        >
                          Load More
                        </button>
                      ) : this.props.hostsTopPlayersList.isFetching ? (
                        <img src={loadinggif} alt="" />
                      ) : (
                        ""
                      )
                    ) : this.props.hostsTopPlayersList.isFetching ? (
                      <img className="text-center" src={loadinggif} alt="" />
                    ) : (
                      <span>No Tournament Found</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return <></>;
    }
  }
}
export default HostTopPlayersViewAllComponent;
