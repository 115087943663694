import React, { Component } from "react";
import helpSupportBannerImg from "../../assets/images/help-support-banner.jpg";
import "./PrivacyPolicyComponent.scss";
import platformParams from "../../utils/platformParams";

class PrivacyContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div
            className={
              this.props.setclass !== "container-fluid" ? "padder-main" : ""
            }
          >
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12">
                    <h6
                      className={
                        "mb-4 bold  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      EMERGE GAMING LIMITED – PRIVACY POLICY
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        This Privacy Policy sets out our commitment to
                        protecting the privacy of personal information provided
                        to us, or otherwise collected by us, offline or online,
                        including through our website and mobile application{" "}
                        <span className="text-black bold">(“Site”)</span>. In
                        this Privacy Policy{" "}
                        <span className="text-black bold">“us, we</span> or{" "}
                        <span className="text-black bold">our”</span> means
                        Emerge Gaming Limited (ABN 31 004 766 376).
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Personal information
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        The types of personal information we may collect about
                        you include:
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">your name;</li>
                      <li className="mb-2">images of you;</li>
                      <li className="mb-2">
                        your contact details, including email address, mailing
                        address, street address and/or telephone number;
                      </li>
                      <li className="mb-2">your age and/or date of birth;</li>
                      <li className="mb-2">
                        your credit card or payment details (through our third
                        party payment processor);
                      </li>
                      <li className="mb-2">
                        your preferences and/or opinions;
                      </li>
                      <li className="mb-2">
                        information you provide to us through customer surveys;
                      </li>
                      <li className="mb-2">
                        your sensitive information as set out below;
                      </li>
                      <li className="mb-2">
                        details of products and services we have provided to you
                        and/or that you have enquired about, and our response to
                        you;
                      </li>
                      <li className="mb-2">
                        your browser session and geo-location data, device and
                        network information, statistics on page views and
                        sessions, acquisition sources, search queries and/or
                        browsing behaviour;
                      </li>
                      <li className="mb-2">
                        information about your access and use of the Site,
                        including through the use of internet cookies, your
                        communications with our Site, the type of browser you
                        are using, the type of operating system you are using
                        and the domain name of your internet service provider;
                      </li>
                      <li className="mb-2">
                        additional personal information that you provide to us,
                        directly or indirectly, through your use of the Site,
                        associated applications, associated social media
                        platforms and/or accounts from which you permit us to
                        collect information; and
                      </li>
                      <li className="mb-2">
                        any other personal information requested by us and/or
                        provided by you or a third party.
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We may collect these types of personal information
                        directly from you or from third parties.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Collection and use of personal information
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We may collect, hold, use and disclose personal
                        information for the following purposes:
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                        to enable you to access and use the Site, associated
                        applications and associated social media platforms;
                      </li>
                      <li className="mb-2">
                        to contact and communicate with you;
                      </li>
                      <li className="mb-2">
                        for internal record keeping, administrative purposes,
                        invoicing and billing purposes;
                      </li>
                      <li className="mb-2">
                        for analytics, market research and business development,
                        including to operate and improve the Site, associated
                        applications and associated social media platforms;
                      </li>
                      <li className="mb-2">
                        to run competitions and/or offer additional benefits to
                        you;
                      </li>
                      <li className="mb-2">
                        for advertising and marketing, including to send you
                        promotional information about our products and services
                        and information about third parties that we consider may
                        be of interest to you;
                      </li>
                      <li className="mb-2">
                        to comply with our legal obligations and resolve any
                        disputes that we may have; and
                      </li>
                      <li className="mb-2">
                        to consider your employment application.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Disclosure of personal information to third parties
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We may disclose personal information to:
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                        third party service providers for the purpose of
                        enabling them to provide their services, including
                        (without limitation) IT service providers, data storage,
                        web-hosting and server providers, debt collectors,
                        maintenance or problem-solving providers, marketing or
                        advertising providers, professional advisors and payment
                        systems operators;
                      </li>
                      <li className="mb-2">
                        our employees, contractors and/or related entities;
                      </li>
                      <li className="mb-2">
                        our existing or potential agents or business partners;
                      </li>
                      <li className="mb-2">
                        sponsors or promoters of any competitions we run;
                      </li>
                      <li className="mb-2">
                        anyone to whom our business or assets (or any part of
                        them) are, or may (in good faith) be, transferred;
                      </li>
                      <li className="mb-2">
                        credit reporting agencies, courts, tribunals and
                        regulatory authorities, in the event you fail to pay for
                        goods or services we have provided to you;
                      </li>
                      <li className="mb-2">
                        courts, tribunals, regulatory authorities and law
                        enforcement officers, as required by law, in connection
                        with any actual or prospective legal proceedings, or in
                        order to establish, exercise or defend our legal rights;
                        third parties, including agents or sub-contractors, who
                        assist us in providing information, products, services
                        or direct marketing to you. This may include parties
                        located, or that store data, outside of Australia,
                        including without limitation, South Africa; and
                      </li>
                      <li className="mb-2">
                        third parties to collect and process data, such as
                        Google Analytics or other relevant businesses. This may
                        include parties that store data outside of Australia,
                        including without limitation, South Africa.
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        By providing us with personal information, you consent
                        to the disclosure of your information outside of
                        Australia and acknowledge that we are not required to
                        ensure that overseas recipients handle that personal
                        information in compliance with Australian Privacy law.
                        You acknowledge that some overseas third parties may not
                        be regulated by the Privacy Act and the Australian
                        Privacy Principles in the Privacy Act and if any third
                        party engages in any act or practice that contravenes
                        the Australian Privacy Principles, it would not be
                        accountable under the Privacy Act and you will not be
                        able to seek redress under the Privacy Act.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      How we treat personal information that is also sensitive
                      information
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        Sensitive information is a sub-set of personal
                        information that is given a higher level of protection
                        under the Australian Privacy Principles.{" "}
                        <span className="bold text-black">
                          Sensitive information
                        </span>{" "}
                        means information relating to your racial or ethnic
                        origin, political opinions, religion, trade union or
                        other professional associations or memberships,
                        philosophical beliefs, sexual orientation or practices,
                        criminal records, health information or biometric
                        information.
                      </li>
                      <li className="mb-2">
                        Provided you consent, your sensitive information may
                        only be used and disclosed for purposes relating to the
                        primary purpose for which the sensitive information was
                        collected.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Your rights and controlling your personal information
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <span className="bold text-black">
                          Choice and consent:
                        </span>{" "}
                        Please read this Privacy Policy carefully. By providing
                        personal information to us, you consent to us
                        collecting, holding, using and disclosing your personal
                        information in accordance with this Privacy Policy. You
                        do not have to provide personal information to us,
                        however, if you do not, it may affect your use of this
                        Site or the products and/or services offered on or
                        through it.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                          Information from third parties:
                        </span>{" "}
                        If we receive personal information about you from a
                        third party, we will protect it as set out in this
                        Privacy Policy. If you are a third party providing
                        personal information about somebody else, you represent
                        and warrant that you have such person’s consent to
                        provide the personal information to us.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Restrict:</span> If
                        you have previously agreed to us using your personal
                        information for direct marketing purposes, you may
                        change your mind at any time by contacting us using the
                        details below.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Access:</span> You may
                        request details of the personal information that we hold
                        about you. An administrative fee may be payable for the
                        provision of such information.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Correction:</span> If
                        you believe that any information we hold about you is
                        inaccurate, out of date, incomplete, irrelevant or
                        misleading, please contact us using the details below.
                        We will take reasonable steps to correct any information
                        found to be inaccurate, incomplete, misleading or out of
                        date.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Complaints:</span> If
                        you wish to make a complaint about how we have handled
                        your personal information, please contact us using the
                        details below and provide us with full details of the
                        complaint. We will promptly investigate your complaint
                        and respond to you, in writing, setting out the outcome
                        of our investigation and the steps we will take to deal
                        with your complaint.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">Unsubscribe:</span> To
                        unsubscribe from our e-mail database or opt-out of
                        communications (including marketing communications),
                        please contact us using the details below or opt-out
                        using the opt-out facilities provided in the
                        communication.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Storage and security
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We are committed to ensuring that the personal
                        information we collect is secure. In order to prevent
                        unauthorised access or disclosure, we have put in place
                        suitable physical, electronic and managerial procedures
                        to safeguard and secure the personal information and
                        protect it from misuse, interference, loss and
                        unauthorised access, modification and disclosure.
                      </li>
                      <li className="mb-2">
                        We cannot guarantee the security of any information that
                        is transmitted to or by us over the internet. The
                        transmission and exchange of information is carried out
                        at your own risk. Although we take measures to safeguard
                        against unauthorised disclosures of information, we
                        cannot assure you that the personal information we
                        collect will not be disclosed in a manner that is
                        inconsistent with this Privacy Policy.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Cookies and web beacons
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We may use cookies on the Site from time to time.
                        Cookies are text files placed in your computer's browser
                        to store your preferences. Cookies, by themselves, do
                        not tell us your email address or other personally
                        identifiable information. However, they do allow third
                        parties, such as Google and Facebook, to cause our
                        advertisements to appear on your social media and online
                        media feeds as part of our retargeting campaigns. If and
                        when you choose to provide the Site with personal
                        information, this information may be linked to the data
                        stored in the cookie.
                      </li>
                    </ul>
                    <ul className="w-100 mb-4 text-muted">
                      <li className="mb-2">
                        <span className="bold text-black">
                          Strictly necessary cookies.
                        </span>{" "}
                        These are cookies that are required for the operation of
                        our Site. They include, for example, cookies that enable
                        you to log into secure areas of our Site, use a shopping
                        cart or make use of e-billing services.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                          Analytical or performance cookies.
                        </span>{" "}
                        These are cookies that allow us to recognise and count
                        the number of visitors to the Site and to see how
                        visitors move around the Site when they are using it.
                        This helps us to improve the way the Site works, for
                        example, by ensuring that users are finding what they
                        are looking for easily.
                      </li>
                      <li className="mb-2">
                        <span className="bold text-black">
                          Functionality cookies.
                        </span>{" "}
                        These are used to recognise you when you return to our
                        Site. This enables us to personalise our content for you
                        and remember your preferences (for example, your choice
                        of language or region).
                      </li>
                    </ul>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        You can find more information about the individual
                        cookies we use and the purposes for which we use them in
                        the table below:
                      </li>
                    </ul>
                    <table className="table privacy-table-border">
                      <thead>
                        <tr
                        className={platformParams['privacyTableBgColor']}
                          // className={
                          //   process.env.REACT_APP_PLATFORM === "nickx"
                          //     ? "background-orange"
                          //     : "bg-secondary"
                          // }
                        >
                          <th scope="col" className={"w-25 "+(process.env.REACT_APP_PLATFORM === "nickx"? "":"text-white")}>
                            Cookie
                          </th>
                          <th scope="col" className={"w-25 "+(process.env.REACT_APP_PLATFORM === "nickx"? "":"text-white")}>
                            Purpose
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Currency</td>
                          <td>
                            This cookie stores the currency used for the Site
                            (AUD)
                          </td>
                        </tr>
                        <tr>
                          <td>Language</td>
                          <td>
                            This cookie stores the language used for the Site
                            (English)
                          </td>
                        </tr>
                        <tr>
                          <td>PHPSESSIONID</td>
                          <td>
                            This cookie contains a unique ID to support
                            functions (for example last viewed products) to
                            improve user experience
                          </td>
                        </tr>
                        <tr>
                          <td>newsbcsub</td>
                          <td>
                            This cookie is stored once the user has completed or
                            closed the register pop up, preventing it from
                            re-appearing until after 7 days
                          </td>
                        </tr>
                        <tr>
                          <td>TLSCookiesEU</td>
                          <td>
                            This cookie tracks when a user has accepted that the
                            site uses cookies preventing the popup from being
                            displayed again during that session
                          </td>
                        </tr>
                        <tr>
                          <td>_ga / _gid / _gat</td>
                          <td>
                            These cookies are used by our Google Analytics
                            account to track customer traffic through the
                            website to help us understand how the Site is being
                            used by our customers
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        You block cookies by activating the setting on your
                        browser that allows you to refuse the setting of all or
                        some cookies. However, if you use your browser settings
                        to block all cookies (including essential cookies) you
                        may not be able to access all or parts of our site.
                      </li>
                      <li className="mb-2">
                        We may use web beacons on the Site from time to time.
                        Web beacons (also known as Clear GIFs) are small pieces
                        of code placed on a web page to monitor the visitor’s
                        behaviour and collect data about the visitor’s viewing
                        of a web page. For example, web beacons can be used to
                        count the users who visit a web page or to deliver a
                        cookie to the browser of a visitor viewing that page.
                      </li>
                      <li className="mb-2">
                        We may use Google Analytics to collect and process data.
                        To find out how Google uses data when you use third
                        party websites or applications, please see
                        www.google.com/policies/privacy/partners/ or any other
                        URL Google may use from time to time.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Links to other websites
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        Our Site may contain links to other websites. We do not
                        have any control over those websites and we are not
                        responsible for the protection and privacy of any
                        personal information which you provide whilst visiting
                        those websites. Those websites are not governed by this
                        Privacy Policy.
                      </li>
                    </ul>
                    <h6
                      className={
                        "mb-4 bold " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      Amendments
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        We may, at any time and at our discretion, vary this
                        Privacy Policy by publishing the amended Privacy Policy
                        on the Site. We recommend you check the Site regularly
                        to ensure you are aware of our current Privacy Policy.
                      </li>
                    </ul>
                    <p className="text-black mb-4 bold">
                      For any questions or notices, please contact our Privacy
                      Officer at:
                    </p>
                    <p className="text-muted mb-4">
                      Emerge Gaming Limited ABN 31 004 766 376
                    </p>
                    <p className="text-muted mb-4">
                      Email:{" "}
                      <a
                        href="mailto:support@arcadex.co"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        support@arcadex.co
                      </a>
                    </p>
                    <p className="text-muted mb-4">
                      <span className="bold text-black">Last update:</span> 10
                      May 2019
                    </p>
                    <p className="text-muted mb-4">
                      ©{" "}
                      <a
                        href="https://legalvision.com.au"
                        target="_blank"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        LegalVision
                      </a>{" "}
                      ILP Pty Ltd
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default PrivacyContent;
