import React, { Component } from "react";
import { connect } from "react-redux";
import LoginModal from "../../components/Modals/Login/LoginModal";
import RegisterModal from "../../components/Modals/Register/RegisterModal";
import CreateNameModal from "../../components/Modals/CreateName/CreateNameModal";
import CreateAvtarModal from "../../components/Modals/CreateAvtar/CreateAvtarModal";
import CreateTutorialModal from "../../components/Modals/CreateTutorial/card-containers/CreateTutorialCardContainer";
import JoinChallengePasswordModal from "../../components/Modals/JoinChallengePassword/JoinChallengePasswordModal";
import QuickChallengeJoinModal from "../../components/Modals/QuickChallengeJoin/QuickChallengeJoinModal";
import EndGameModal from "../../components/Modals/EndGame/EndGameModal";
import ForgotPasswordModal from "../../components/Modals/Password/ForgotPassword/ForgotPasswordModal";
import ResetPasswordModal from "../../components/Modals/Password/ResetPassword/ResetPasswordModal";
import ChangePasswordModal from "../../components/Modals/Password/ChangePassword/ChangePasswordModal";
import ReportPlayerModal from "../../components/Modals/ReportPlayer/ReportPlayerModal";
import UnfollowModal from "../../components/Modals/Unfollow/UnfollowModal";
import VerifyPrizeModal from "../../components/Modals/VerifyPrize/VerifyPrizeModal";
import ActivateUserModal from "../../components/Modals/ActivateUser/ActivateUserModal";
import UnsubscribeModal from "../../components/Modals/Unsubscribe/UnsubscribeModal";
import UnsubscribeSuccessModal from "../../components/Modals/UnsubscribeSuccess/UnsubscribeSuccessModal";


class IncludeModals extends Component {
  render() {
    return !this.props.isLoggedIn ? (
      <>
        <LoginModal />
        <ForgotPasswordModal />
        <ResetPasswordModal />
        <RegisterModal />
        <ActivateUserModal />
      </>
    ) : (
      <>
        <CreateNameModal />
        <CreateAvtarModal />
        <CreateTutorialModal />
        <QuickChallengeJoinModal />
        <JoinChallengePasswordModal />
        <EndGameModal />
        <ChangePasswordModal />
        <ReportPlayerModal />
        <UnfollowModal />
        <UnsubscribeModal />
        <UnsubscribeSuccessModal />
        <VerifyPrizeModal />
       
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn
  };
};

export default connect(mapStateToProps)(IncludeModals);
