import React, { Component } from "react";
import navArrowRightBlue from "../../assets/images/svg/nav-arrow-right-blue.svg";
import PlayGameCardComponent from "../Games/PlayGameCardComponent";
import DashboardNews from "../Dashboard/DashboardNews/DashboardNewsComponents";
import CardImage from "../../assets/images/card-sm.jpg";
import ChallengeBannerNick from "../../assets/images/adv/challenge-banner-nick.png";
import ChallengeBanner from "../../assets/images/adv/banner-1.jpeg";
import platformParams from "../../utils/platformParams";

class DetailComponent extends Component {
  constructor(props) {
    super(props);

    this.loadChallenge = this.loadChallenge.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.changeTimerStatus = this.changeTimerStatus.bind(this);
  }

  handleRefresh() {
    this.loadChallenge();
  }

  loadChallenge() {
    const {
      fetchnewsCategoriesListIfNeeded,
      invalidateNewsCategoriesList,
      invalidateArcadeGameDetail,
      fetchArcadeGameDetailIfNeeded
    } = this.props;

    invalidateNewsCategoriesList();
    fetchnewsCategoriesListIfNeeded({
      categoryId: "3",
      page: 1
    });

    invalidateArcadeGameDetail();
    fetchArcadeGameDetailIfNeeded({
      casualGameId: this.props.match.params.id
    });
  }

  componentDidMount() {
    this.loadChallenge();
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }

  changeTimerStatus(timerStatus){

  }

  render() {
    const { newsListAll, arcadeGameDetail } = this.props;
    return (
      <>
        <section className="container-fluid banner-wrapper">
          <div className="row">            
            <div className="banner dashboard d-flex flex-column align-items-center">                
                <img src={process.env.REACT_APP_PLATFORM === "nickx" ? ChallengeBannerNick : ChallengeBanner } alt="" className="dynamic-banner gtmBrandBanner-1"/>
            </div>            
            <div className="tournamet-info-wrapper w-100">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-xl-10">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-auto">
                          <img
                            src={arcadeGameDetail.item.thumb}
                            className="mt-negative-50 profile-pic-140"
                            alt=""
                            onError={this.addDefaultSrc}
                          />
                        </div>
                        <div className="col">
                          <h1 className="d-lg-block">
                            {arcadeGameDetail.item.name}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="[ container-fluid ] todays-challange">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div
                    className="col-lg-12 col-xl-9 text-center"
                    style={{ zIndex: 9 }}
                  >
                    <PlayGameCardComponent
                      gameId={arcadeGameDetail.item.packageId}
                      hasFamobiEvent={false}
                      changeTimer={this.changeTimerStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*News*/}
        {newsListAll.length > 0 ? (
          <section className="[ container-fluid ] news section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">News</h2>
                    </div>
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="/news"
                        className={"view-all d-flex align-items-center " + platformParams['className']}
                      >
                        View All{" "}
                        <span>
                          <img src={platformParams['navRightArrow']} alt="" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                <DashboardNews list={newsListAll} />
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default DetailComponent;
