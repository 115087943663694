import React, { Component } from "react";
import { connect } from "react-redux";
import "./ChallengeBannerComponent.scss";
import Countdown from "./Countdown";
import moment from "moment";
import { quickChallengeJoin } from "../../actions/modals";
import { joinChallengePasswordForm } from "../../actions/forms";
import profilePic from "../../assets/images/profile-pic-80.png";
import adv5 from "../../assets/images/adv/adv-5.png";
import platformParams from "../../utils/platformParams";

class ChallengeBannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubscriptionPopup: false
    };
    this.getChallengeButton = this.getChallengeButton.bind(this);
  }

  prepareTagClass(brandName) {
    // if(brandName != "" && brandName){
    //   return brandName.split(' ').join('-');
    // }
    //return "";
    return "1";
  }

  startEndTimehandler() {
    window.location.reload();
  }

  getChallengeButton() {
    const { challengeDetails, t } = this.props;
    let btnTxt = t("challengePage.join");
    if (
      challengeDetails.hasAlreadyJoined === true &&
      (challengeDetails.isSubscribed === true ||
        challengeDetails.subscriptionTypeId === "0")
    ) {
      btnTxt = t("challengePage.joined");
    }

    if (
      challengeDetails.challengeStatusId === "3" ||
      challengeDetails.challengeStatusId === "2"
    ) {
      return (
        <>
          <button
            className={
              "btn  rounded-xs w-50 ml-2 gtmJoinTournament-" +
              this.prepareTagClass(challengeDetails.brandName) +
              " btn-" + platformParams['colorClassSufix']
            }
            type="button"
            disabled={
              this.props.joinChallengePasswordForm.isSubmitting ||
              challengeDetails.hasAlreadyJoined
                ? true
                : false
            }
            onClick={e => {
              e.preventDefault();
              if (
                challengeDetails.isSubscribed === true ||
                challengeDetails.subscriptionTypeId === "0"
              ) {
                if (challengeDetails.hasAlreadyJoined === true) {
                  return false;
                } else {
                  if (challengeDetails.hasPassword) {
                    this.props.joinChallengePasswordFormReset();
                    this.props.quickChallengeJoinOpen();
                  } else {
                    this.props.joinChallengePasswordFormSubmit({
                      password: "",
                      challengeId: challengeDetails.id
                    });
                  }
                }
              } else {
                this.setState({ showSubscriptionPopup: true });
              }
            }}
          >
            {btnTxt}
          </button>
          {/*showDisplayAllSeatsTaken ? this.allSeatTaken() : "" */}
          {this.state.showSubscriptionPopup ? this.checkSubscription() : ""}
        </>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      challengeTitle,
      logoUrl,
      challengeStatusId,
      timeOfStart,
      timeOfEnd,
      seatsTaken,
      organizerId,
      brandName
    } = this.props.challengeDetails;

    const { allOnlineUserList, t } = this.props;
    return (
      <div>
        <div className="row no-gutters">
          <div className="col-sm-12">
            <div className="banner d-flex justify-content-center align-items-center">
              <img
                src={
                  platformParams['challengeDefaultBannerImage']
                }
                alt=""
                className={
                  "dynamic-banner gtmTournamentBanner-" +
                  this.prepareTagClass(brandName)
                }
              />
            </div>
          </div>
        </div>

        <div className="tournamet-info-wrapper w-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-xl-10">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-12 col-sm-auto">
                    <figure>
                      <img
                        src={logoUrl ? logoUrl : profilePic}
                        className="mt-negative-50 profile-pic-140"
                        alt=""
                      />
                      {allOnlineUserList.includes(organizerId) ? (
                        <figcaption className="online-140" />
                      ) : null}
                    </figure>
                  </div>
                  <div className="col-12 col-sm d-flex flex-column align-items-center align-items-sm-start justify-content-center justify-content-sm-start">
                    <h1 className="mb-3 title-small-responsive">
                      {challengeTitle}
                    </h1>
                    <div className="row responsive-adjust w-100">
                      {-1 * moment().diff(timeOfEnd, "minutes") >= 1 ? (
                        <div className="col-12 col-sm order-2 order-lg-1 ms-flex d-flex justify-content-center justify-content-sm-start">
                          <div className="media">
                            <img
                              className="mr-2 timer"
                              src={platformParams['visualTimer']}
                              alt="Timer"
                            />
                            <div className="media-body align-self-center">
                              <h6
                                className={
                                  "bold  d-lg-block text-" + platformParams['textColorClassSufix']
                                }
                              >
                                <Countdown
                                  action={() => {
                                    this.startEndTimehandler();
                                  }}
                                  targetDate={
                                    challengeStatusId == "2"
                                      ? timeOfStart
                                      : timeOfEnd
                                  }
                                  format={{
                                    day: "DD",
                                    hour: "HH",
                                    minute: "MM",
                                    second: "SS"
                                  }}
                                  challengeStatus={challengeStatusId}
                                  leadingZero
                                  t={t}
                                />
                              </h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 col-sm order-2 order-lg-1 ms-flex d-flex justify-content-center justify-content-sm-start">
                          <div className="media">
                            <img
                              className="mr-2 timer"
                              src={platformParams['visualTimer']}
                              alt="Timer"
                            />
                            <div className="media-body align-self-center">
                              <h6
                                className={
                                  "bold  d-lg-block text-" + platformParams['textColorClassSufix']
                                }
                              >
                                <div className="countDown-timer">
                                  <span>{t("ends")}:</span>
                                  <span className="react-cntdwn-timer">
                                    <span className="react-cntdwn-day">
                                      0<span>{t("day").charAt(0)}, </span>
                                    </span>
                                    <span className="react-cntdwn-hour">
                                      0<span>Hrs, </span>
                                    </span>
                                    <span className="react-cntdwn-minute">
                                      0<span>Min, </span>
                                    </span>
                                    <span className="react-cntdwn-second">
                                      0<span>Sec</span>
                                    </span>
                                  </span>
                                </div>
                              </h6>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-12 col-sm d-flex align-items-center justify-content-center justify-content-sm-start counter-btn-wraper order-1 order-lg-2">
                        {-1 * moment().diff(timeOfEnd, "minutes") >= 1 ? (
                          this.getChallengeButton()
                        ) : (
                          <button
                            className={
                              "btn  rounded-xs w-50 ml-2 gtmJoinTournament-" +
                              this.prepareTagClass(brandName) +
                               " btn-" + platformParams['colorClassSufix']
                            }
                            type="button"
                            disabled
                          >
                            {t("challengePage.joined")}
                          </button>
                        )}
                        <h6 className="bold ml-2">
                          {seatsTaken}{" "}
                          <span> {t("challengePage.players")}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  quickChallengeJoinOpen: quickChallengeJoin.open,
  joinChallengePasswordFormSubmit: joinChallengePasswordForm.submit,
  joinChallengePasswordFormReset: joinChallengePasswordForm.reset
};

const mapStateToProps = state => {
  return {
    userDetail: state.user.item,
    joinChallengePasswordForm: state.forms.joinChallengePasswordForm,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeBannerComponent);

// export default ChallengeBannerComponent;
