import React, { Component } from "react";
import { connect } from "react-redux";
import {
  messageListSidebar,
  userListSidebar,
  chatListSidebar
} from "../../../../actions/sidebars";
import { openChatHistory } from "../../../../actions/chathistory";
import arrowRightSvg from "../../../../assets/images/svg/nav-arrow-right.svg";
import avatar3x from "../../../../assets/images/avatar@3x.png";
import searchImg from "../../../../assets/images/svg/search.svg";
//search
import { searchProfilesRequest } from "../../../../actions/socketusers";
import { refactorTitle } from "../../../../utils/misc";
import navClose from "../../../../assets/images/svg/nav-close.svg";
import { withTranslation, Trans } from "react-i18next";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedWord: ""
    };
    this.hideRightSidebar = this.hideRightSidebar.bind(this);
    this.showMessageListSidebar = this.showMessageListSidebar.bind(this);
    this.showChatListSidebar = this.showChatListSidebar.bind(this);
  }

  showMessageListSidebar() {
    this.props.userListSidebarClose();
    this.props.messageListSidebarOpen();
  }

  componentWillReceiveProps() {
    if (this.props.isUserListSidebarOpen === false) {
      this.setState({ searchedWord: "" });
    }
  }

  hideRightSidebar() {
    this.props.userListSidebarClose();
  }

  showChatListSidebar = profileDetail => e => {
    this.props.userListSidebarClose();
    this.props.chatListSidebarOpen();
    this.props.openChatHistory(profileDetail);
  };

  addDefaultSrc(ev) {
    ev.target.src = avatar3x;
  }

  handleInputChange = value => {
    this.setState({ searchedWord: value });
    if (this.state.searchedWord != "") {
      this.props.searchProfilesRequest(this.state.searchedWord);
    }
  };

  render() {
    const { socketusers, allOnlineUserList } = this.props;
    const { t } = this.props;
    return (
      <>
        <nav
          className={
            "my-friend-slider" +
            (this.props.isUserListSidebarOpen ? " active" : "")
          }
        >
          <div className="fixed-sidebar-scroll-landscap chat-screen">
            <div className="dismiss">
              <span className="icon-close">
                <img src={navClose} alt="" onClick={this.hideRightSidebar} />
              </span>
            </div>
            <section className="d-flex flex-column ">
              <section className="row no-gutters">
                <div className="col-sm-12 text-center">
                  <div className="sidebar-header">
                    <div className="d-flex  justify-content-between align-items-center message-header">
                      <h6 className="bold">
                        <a
                          href="javascript:void(0)"
                          onClick={this.showMessageListSidebar}
                          className="back-arrow"
                        >
                          <img src={arrowRightSvg} alt="" />
                          {t("back")}
                        </a>{" "}
                      </h6>
                    </div>
                    <form action="#">
                      <div className="form-group without-lable ">
                        <div className="input-group-wrapper search">
                          <input
                            type="text"
                            className="form-control effect-1"
                            placeholder={t("placeSearchUser")}
                            onChange={event => {
                              this.handleInputChange(event.currentTarget.value);
                            }}
                            value={this.state.searchedWord}
                          />
                          <img src={searchImg} className="search-icon" alt="" />
                          <span className="focus-border" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>

              {this.state.searchedWord != "" ? (
                <section className="message-info">
                  <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                    <div className="media col">
                      <span>
                        <b>{t("searchResult")}</b>
                      </span>
                    </div>
                  </div>

                  {socketusers && socketusers.length > 0 ? (
                    socketusers.map((p, i) =>
                      p.id == this.props.userId ? null : (
                        <a
                          href="javascript:void(0)"
                          key={i}
                          onClick={this.showChatListSidebar({
                            id: p.id,
                            userName: p.name,
                            profileUrl: p.avatarUrl
                          })}
                        >
                          <div className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1">
                            <div className="media col">
                              <img
                                src={p.avatarUrl}
                                className="profile-pic-48"
                                alt=""
                                onError={this.addDefaultSrc}
                              />
                              {allOnlineUserList.includes(p.id) ? (
                                <figure>
                                  <figcaption className="online-48"></figcaption>
                                </figure>
                              ) : null}
                              <div className="media-body ml-3 align-self-center">
                                <p className="mb-0 text-truncate mw-150">
                                  {refactorTitle(p.name, 15)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      )
                    )
                  ) : (
                    <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                      <div className="media col">
                        <span>{t("noSearchResult")}</span>
                      </div>
                    </div>
                  )}
                </section>
              ) : (
                ""
              )}
            </section>
          </div>
        </nav>
      </>
    );
  }
}
const mapDispatchToProps = {
  disableOuterClick: false,
  messageListSidebarOpen: messageListSidebar.open,
  messageListSidebarClose: messageListSidebar.close,
  userListSidebarOpen: userListSidebar.open,
  userListSidebarClose: userListSidebar.close,
  chatListSidebarOpen: chatListSidebar.open,
  openChatHistory: openChatHistory,
  searchProfilesRequest: searchProfilesRequest
};

const mapStateToProps = state => {
  const { socketusers } = state;
  return {
    isUserListSidebarOpen: state.sidebars.isUserListSidebarOpen,
    userId: state.user.item.id,
    socketusers: socketusers.users,
    allOnlineUserList: socketusers.allOnlineUserList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserList));
