import HomeComponent from "../components/Home/HomeComponent";
import { connect } from "react-redux";
import { challenges } from "../actions/challenges";
import { subscribeForm } from "../actions/forms";
import { login, register } from "../actions/modals";
import { leftSidebar } from "../actions/sidebars";

const mapDispatchToProps = {
  fetchChallengesLandingPopularIfNeeded: challenges.fetchIfNeeded,
  invalidateChallengesLandingPopular: challenges.invalidate,
  leftSidebarClose: leftSidebar.close,
  loginOpen: login.open,
  registerOpen: register.open,
  subscribeFormSubmit: subscribeForm.submit,
  subscribeFormReset: subscribeForm.reset,
  subscribeFormResetField: subscribeForm.resetField,
  subscribeFormUpdateField: subscribeForm.updateField,
  subscribeFormSetError: subscribeForm.setError,
  subscribeFormSetSuccess: subscribeForm.setSuccess
};

const mapStateToProps = state => {
  const { challenges, subscribe } = state;
  const {
    isFetching,
    lastUpdated,
    items: challengesList
  } = challenges.landingPopular || {
    isFetching: true,
    items: [],
    subscribe: { ...subscribe }
  };

  return {
    challengesList,
    isFetching,
    lastUpdated,
    subscribeForm: state.forms.subscribeForm,
    isLeftSidebarOpen: state.sidebars.isLeftSidebarOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);
