import { connect } from "react-redux";
import RafflesComponent from "../../components/Raffles/RafflesComponent";
import { rafflesList, myActiveRafflesList } from "../../actions/raffles";

const mapDispatchToProps = {
  fetchRafflesIfNeeded: rafflesList.fetchIfNeeded,
  invalidateRaffles: rafflesList.invalidate,
  fetchMyActiveRafflesIfNeeded: myActiveRafflesList.fetchIfNeeded,
  invalidateMyActiveRaffles: myActiveRafflesList.invalidate
};

const mapStateToProps = state => {
  const { raffles, user } = state;

  const { items: rafflesList } = raffles.rafflesList || {
    items: []
  };

  const { items: myActiveRafflesList } = raffles.myActiveRafflesList || {
    items: []
  };

  return {
    rafflesList,
    myActiveRafflesList,
    tokensInWallet: user.item.points,
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RafflesComponent);
