import React, { Component } from "react";
import SwiperContainer from "../../components/Slider/SwiperContainer";
class FeaturedCardContainer extends Component {
  render() {
    const params = {
      slidesPerView: "auto",
      slideExtraClass: this.props.flexFill ? "flex-fill" : "",
      spaceBetween: 30,
      loop: false,
      centeredSlides: false,
      touchRatio: 0,
      WrapperEl: "ul",
      SlideEl: "li",
      wrapperExtraClass: "list-inline d-flex align-items-end",
      touchRatio: 1
    };

    const { featureData } = this.props;
    return featureData && featureData.length > 0 ? (
      <SwiperContainer {...params}>
        {featureData.map((image, index) => (
          <DisplayImage url={image.image} key={index} />
        ))}
      </SwiperContainer>
    ) : (
      ""
    );
  }
}

export default FeaturedCardContainer;

export const DisplayImage = props => <img src={props.url} alt="" />;
