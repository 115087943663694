import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const onNGLogInUserRequest = postParams => {
    return axios.request({
        url: API_SERVER_URL + config.ngLoginProfile,
        method: "post",
        headers: {
        "content-type": "application/json"
        },
        data: postParams
    });
};
  
export const onLogInUser = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.loginProfile,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onForgotPasswordRequest = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.resetPasswordRequest,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onChangePasswordRequest = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.changePasswordRequest,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onResetPassword = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.resetPassword,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onGetUserProfile = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.getProfile.replace(
          "##id##",
          postParams.profileId ? postParams.profileId : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUserFollowers = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getFollowers.replace('##id##', postParams.profileId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onLogInHyve = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.loginHyve,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onRegisterUser = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.createProfile,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onRegisterWithHyve = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.createProfileHyve,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onActivateUser = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.activateProfile,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onCreateUserName = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createUsername,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onUsernameAvailable = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.usernameAvailable,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onAddShownTutorial = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.addShownTutorial,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onUpdateUserAvatar = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.updateAvatar,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onQuestion = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.messageSupportV2,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onReportPlayer = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.reportPlayerRequest,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};
export const onProfileEdit = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.updateProfile,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUserFollowings = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.getFollowings.replace(
          "##id##",
          postParams.profileId ? postParams.profileId : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onGetLanguages = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getLanguages,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
