import React, { Component } from "react";
import { parseQuery } from "../utils/misc";
import i18n from "../i18n";

class AffiliateContainer extends Component {
    componentDidMount() {
        if (this.props.location.search) {
            const lang = i18n.language;
            const query = parseQuery(this.props.location.search);
            if (query.operatorId && query.id) {
                let routeName = "/" + lang + "/subscribe";
                routeName = routeName + "?id=" + query.id + "&operatorId=" + query.operatorId;
                this.props.history.push(routeName);
            } else {
                let routeName = "/" + lang + "/billing-info";
                routeName = routeName + "?id=" + query.id;
                this.props.history.push(routeName);
            }
        }
    }
    render() {
        return (
            <></>
        );
    }
}

export default AffiliateContainer;
