import React, { Component } from "react";
import { famobi_detection } from "../../utils/famobiDetect";
import "./MobileGameComponent.scss";

class MobileGameComponent extends Component {
  constructor(props) {
    super(props);
    this.deviceInfo = famobi_detection;
    this.onPostMessageReceive = this.onPostMessageReceive.bind(this);
    this.urlTimeStamp = Math.random();
  }

  componentDidMount() {
    window.removeEventListener("message", this.onPostMessageReceive);
    window.addEventListener("message", this.onPostMessageReceive);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onPostMessageReceive);
  }

  onPostMessageReceive(e) {
    if((this.deviceInfo.is.smartphone === true || this.deviceInfo.is.tablet === true) & (window.innerWidth < 769 || window.innerHeight < 769))
    {
      let messageObject = e.data;
      postMessage(JSON.stringify(messageObject));
    }
  }

  render() {
    return (
      <>
        <object
        aria-label = ''
        aria-labelledby = ''
          className="mobileGame"
          data={
            "https://play.famobi.com/" +
            this.props.gameId +
            "/" +
            this.props.affiliateId +
            "/?" +
            this.urlTimeStamp
          }
        />
      </>
    );
  }
}
const defaultProps = {
  gameId: "jewelish-blitz",
  affiliateId: "A-INY7M"
};
MobileGameComponent.defaultProps = defaultProps;

export default MobileGameComponent;
