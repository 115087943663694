import { connect } from "react-redux";
import DashboardPopularHostsViewAllComponent from "../components/Dashboard/DashboardPopularHost/DashboardPopularHostsViewAllComponent";
import {  
  brandsPopularViewAll
} from "../actions/brands";

const mapDispatchToProps = {
  fetchPopularBrandsViewAllIfNeeded: brandsPopularViewAll.fetchIfNeeded,
  invalidatePopularBrandsViewAll: brandsPopularViewAll.invalidate,    
  invalidateAllPopularBrandsViewAll: brandsPopularViewAll.invalidateAll,    
};

const mapStateToProps = state => {  
  const { brands, user } = state;  
  return {
    popularBrandsViewAll : brands.popularBrandsViewAll,
    page: brands.popularBrandsViewAll.page,  
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPopularHostsViewAllComponent);
