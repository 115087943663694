import { connect } from "react-redux";
import RafflesViewAllComponent from "../../components/Raffles/RafflesViewAllComponent";
import { rafflesViewAll, rafflesActiveViewAll } from "../../actions/raffles";
import RafflesActiveViewAllComponent from "../../components/Raffles/RafflesActiveViewAllComponent";

const mapDispatchToProps = {
  fetchRafflesViewAllIfNeeded: rafflesActiveViewAll.fetchIfNeeded,
  invalidateRafflesViewAll: rafflesActiveViewAll.invalidate,
  invalidateAllRafflesViewAll: rafflesActiveViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { raffles, user } = state;
  return {
    userId: user.item.id,
    rafflesViewAll: raffles.rafflesActiveViewAll,
    page: raffles.rafflesActiveViewAll.page,
    tokensInWallet: user.item.points
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RafflesActiveViewAllComponent);
