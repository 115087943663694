import HelpSupportComponent from "../components/HelpSupport/HelpSupportComponent";
import { connect } from "react-redux";
//import { challenges } from "../actions/challenges";

const mapDispatchToProps = {
  //fetchChallengesLandingPopularIfNeeded: challenges.fetchIfNeeded
};

const mapStateToProps = state => {
  // const { challenges } = state;
  // const { isFetching, lastUpdated, items: challengesList } = challenges || {
  //   isFetching: true,
  //   items: []
  // };

  return {
    // challengesList,
    // isFetching,
    // lastUpdated
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpSupportComponent);
