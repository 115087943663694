import React, { Component } from "react";
import TournamentCardComponent from "../../Tournaments/TournamentCardComponent";
import loadinggif from "../../../assets/images/loading.gif";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class ChallengesLiveViewAllComponent extends Component {
  componentDidMount() {
    const {
      fetchLiveChallengesViewAllIfNeeded,
      invalidateAllLiveChallengesViewAll
    } = this.props;
    invalidateAllLiveChallengesViewAll();
    fetchLiveChallengesViewAllIfNeeded({ brandId: "", page: 1 });
  }

  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {
    let { page, t, history } = this.props;
    return (
      <section className="container-fluid my-tournament-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">
                    {t("live")} {t("navTournaments")}
                  </h2>
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start">
                {this.props.liveViewAll.items.length > 0
                  ? this.props.liveViewAll.items.map((tournament, i) => (
                    <div className="col-auto mb-4" key={i}>
                      <TournamentCardComponent
                        {...tournament}
                        cardClass="card card-my-tournament"
                        brandAvatarClass="profile-pic-56"
                        challengeType="Live"
                        order={i + 1}
                      />
                    </div>
                  ))
                  : ""}
              </div>
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {this.props.liveViewAll.items.length > 0 ? (
                    !this.props.liveViewAll.isLastPage ? (
                      <button
                        onClick={() => {
                          this.props.invalidateLiveChallengesViewAll();
                          this.props.fetchLiveChallengesViewAllIfNeeded({
                            brandId: "",
                            page: page
                          });
                        }}
                        className={"btn btn-"+ platformParams['colorClassSufix'] +" dark btn-xs"}
                      >
                        {t("loadMore")}
                      </button>
                    ) : this.props.liveViewAll.isFetching ? (
                      <img src={loadinggif} alt="" />
                    ) : (
                          ""
                        )
                  ) : this.props.liveViewAll.isFetching ? (
                    <img className="text-center" src={loadinggif} alt="" />
                  ) : (
                        <span>{t("noTournamentFound")}</span>
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ChallengesLiveViewAllComponent);
