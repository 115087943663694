import React, { Component } from "react";
import "./ModalV2.scss";
import IconClose from "../../assets/images/svg/nav-close.svg";
import UpdateDimension from "../Utils/UpdateDimension";
import CrossBackgroudImgNickx from "../../assets/images/login-shape-secondary-nick.png";
import CrossBackgroudImg from "../../assets/images/login-shape-secondary.png";

class ModalV2 extends Component {
  constructor(props) {
    super(props);
    this.hideProxy = this.hideProxy.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      isPortrait: false
    };
  }
  componentWillMount() {
    if (!this.props.disableOuterClick) {
      document.addEventListener("mousedown", this.hideProxy, false);
    }
  }
  componentWillUnmount() {
    if (!this.props.disableOuterClick) {
      document.removeEventListener("mousedown", this.hideProxy, false);
    }
  }
  updateDimensions() {
    this.setState({
      isPortrait: window.innerHeight >= 530 && window.innerWidth > 320
    });
  }
  componentDidMount() {
    this.updateDimensions();
  }
  hideProxy = e => {
    if (this.props.disableOuterClick) {
      return;
    }
    if (
      (this.modalWeb && this.modalWeb.contains(e.target)) ||
      (this.modalMobile && this.modalMobile.contains(e.target))
    ) {
      return;
    }
    if (this.props.isOpen) {
      this.props.handleHide();
    }
    return;
  };

  render() {
    return (
      <>
        <UpdateDimension onUpdate={this.updateDimensions} hasSetState={true}>
          {this.props.isOpen ? (
            <div className="d-block d-lg-none">
              <div className="modalV2">
                <div
                  className={
                    "container-fluid popup-responsive " +
                    (this.state.isPortrait ? " position-fixed" : "") +
                    (process.env.REACT_APP_PLATFORM === "nickx"
                      ? " nickx "
                      : "")
                  }
                  ref={modal => {
                    this.modalMobile = modal;
                  }}
                >
                  <div className="row">
                    <div className="container">
                      <div className="col-sm-12 pt-4">
                        {this.props.children}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "cross-background cross-div mobile" +
                      (this.state.isPortrait ? "" : " adjust") +
                      (process.env.REACT_APP_PLATFORM === "nickx"
                        ? " nickx "
                        : "")
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </UpdateDimension>
        <div className="d-none d-lg-block">
          <div
            className={
              this.props.modalClass +
              " fade modalV2" +
              (this.props.isOpen ? " show" : "")
            }
            tabIndex="-1"
            style={
              this.props.isOpen ? { display: "block" } : { display: "none" }
            }
          >
            <div
              className={this.props.modalDialogClass}
              ref={modal => {
                this.modalWeb = modal;
              }}
            >
              <div className={this.props.modalContentClass}>
                <div className={this.props.modalBodyClass}>
                  {this.props.showCloseBtn ? (
                    <div
                      className="popup-dismiss"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.props.handleHide}
                    >
                      <img src={IconClose} alt="" />
                    </div>
                  ) : (
                    ""
                  )}

                  {this.props.children}
                  <div
                    className={
                      (process.env.REACT_APP_PLATFORM === "nickx"
                        ? " nickx "
                        : "") + "cross-background web"
                    }
                  />
                  <svg
                    version="1.1"
                    className={
                      "cross-box" +
                      (this.props.modalCrossSvgClass ? " create-player" : "") +
                      (this.props.withError ? " with-error" : "") +
                      (this.props.showSocialBtns ? "" : " no-social-btn")
                    }
                    height="100%"
                    width="100%"
                    preserveAspectRatio="none"
                    viewBox="0 0 383.9 542.6"
                  >
                    <defs />
                    <polygon
                      fill={
                        process.env.REACT_APP_PLATFORM === "nickx"
                          ? "#fc7323"
                          : "#1a46e5"
                      }
                      x="100%"
                      y="100%"
                      stroke={
                        process.env.REACT_APP_PLATFORM === "nickx"
                          ? "#fc7323"
                          : "#1a46e5"
                      }
                      strokeMiterlimit="1"
                      points="383.4,542.1 0.5,542.1 0.5,104.3 383.4,0.7 "
                    />
                    <image
                      xlinkHref={
                        process.env.REACT_APP_PLATFORM === "nickx"
                          ? CrossBackgroudImgNickx
                          : CrossBackgroudImg
                      }
                      x="0"
                      y="0"
                      height="55%"
                      width="80%"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          {this.props.backdrop && this.props.isOpen ? (
            <div className="modal-backdrop fade show" />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
const defaultProps = {
  disableOuterClick: false,
  backdrop: true,
  isOpen: false,
  handleHide: () => {},
  modalClass: "modal",
  modalDialogClass: "modal-dialog modal-dialog-centered",
  modalContentClass: "modal-content",
  modalBodyClass: "modal-body",
  modalCrossSvgClass: false,
  showCloseBtn: true,
  withError: false,
  showSocialBtns: true
};
ModalV2.defaultProps = defaultProps;
export default ModalV2;
