import React, { Component } from "react";
import globabout from "../../assets/images/glob@about.png";
import keybout from "../../assets/images/key@about.png";
import crownabout from "../../assets/images/crown@about.png";
import trophyabout from "../../assets/images/trophy@about.png";
import gameabout from "../../assets/images/game-controller@about.png";



import "swiper/dist/css/swiper.css";
import "./AboutUsComponent.scss";
import { withTranslation, Trans } from "react-i18next";
import platformParams from '../../utils/platformParams';

class AboutUsComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const offerData = [
      {
        image: gameabout,
        title: () => (
          <Trans i18nKey = "aboutUsPage.onlineEsport">
            Online eSports <br /> tournament platform
          </Trans>
        )
      },
      {
        image: globabout,
        title: () => (
          <Trans  i18nKey = "aboutUsPage.exposure">
            Exposure to an <br /> international market
          </Trans>
        )
      },
      {
        image: crownabout,
        title: () => (
          <Trans  i18nKey = "aboutUsPage.extensive">
            Extensive relationships <br /> in the esports industry
          </Trans>
        )
      },
      {
        image: keybout,
        title: () => (
          <Trans  i18nKey = "aboutUsPage.hostedOver">
            We’ve successfully <br /> hosted over 10,000 <br /> online
            tournaments
          </Trans>
        )
      },
      {
        image: crownabout,
        title: () => (
          <Trans  i18nKey = "aboutUsPage.executive">
            Executive management <br /> that understands <br /> eSports
          </Trans>
        )
      },
      {
        image: trophyabout,

        title: () => (
          <Trans  i18nKey = "aboutUsPage.turkeyEsport">
            Turnkey eSports <br /> marketing solutions for <br /> brands
          </Trans>
        )
      }
    ];

    let envName = platformParams['title'];
    const { t } = this.props;
    return (
      <>
        <section
          className={
            "container-fluid banner-wrapper aboutus-wrapper " +
            platformParams['className']
          }
        >
          <div className="row">
            <div
              className={
                "banner flex-column aboutus " +
                platformParams['className']
              }
            >
              <img
                src={platformParams['aboutBannerMobile']}
                className="img-fluid d-block d-md-none"
                alt=""
              />
              <div className="container">
                <div className="row">
                  <div className="col-md-6 about-content">
                    <h1 className="display-1 mb-5">{t("navAboutUs")}</h1>
                    <h2 className="mb-4">
                      {t("aboutUsPage.perfectLink")}{" "}
                      <span className="bold">{t("aboutUsPage.brands")}</span>{" "}
                      {t("aboutUsPage.and")}{" "}
                      <span className="bold">{t("aboutUsPage.allType")}</span>
                    </h2>
                    <h5>
                      {envName} X {t("aboutUsPage.aboutContent")}
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 about-quote">
                    <h2
                      className={
                        "text-" + platformParams['colorClassSufix'] + " display-2"
                      }
                    >
                      “{envName} X {t("aboutUsPage.linkBetween")}”
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100  about-collumn-section pb-6">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-5 mb-4 mb-lg-0">
                    <img
                      src={platformParams['aboutMobile']}
                      className="about-sec-img"
                      alt=""
                    />
                    <h2 className="bold py-4">
                      {envName} X Mobi <br />
                      {t("aboutUsPage.resolutions")}
                    </h2>
                    {process.env.REACT_APP_PLATFORM === "nickx" ? (
                      ""
                    ) : (
                      <p>Arcade X Mobi {t("offerOverMobile")}</p>
                    )}
                  </div>
                  <div className="col-md-6 mb-4 mb-lg-0">
                    <img
                      src={platformParams['aboutWeb']}
                      className="about-sec-img"
                      alt=""
                    />
                    {process.env.REACT_APP_PLATFORM === "nickx" ? (
                      <h2 className="bold py-4">
                        Nick X {t("aboutUsPage.gaming")} <br />{" "}
                        {t("aboutUsPage.resolutions")}
                      </h2>
                    ) : (
                      <>
                        <h2 className="bold py-4">Arcade X eSports</h2>
                        <p>Arcade X {t("aboutUsPage.arcadexEsport")} </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {process.env.REACT_APP_PLATFORM === "arcadex" && (
          <section className="[ container-fluid ] aboutus-offer-section">
            <div className="padder-main backstyle">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <h1 className="text-center text-white">
                        {t("aboutUsPage.whatWeOffer")}
                      </h1>
                    </div>
                  </div>
                  <div className="row collumn-wrappper justify-content-center">
                    <div className="col-sm-12 col-xl-10 mt-3">
                      <div className="row text-center text-xl-left justify-content-xl-between mb-6">
                        {offerData.map((item, index) => (
                          <div
                            className="col-sm-6 col-md-4  my-4 mb-lg-0"
                            key={index}
                          >
                            <img src={item.image} className="py-3" alt="" />
                            <p className="text-white">
                              <item.title />
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-6 justify-content-center">
                    <div className="col-sm-12 col-xl-10">
                      <h1 className="text-center text-white">
                        {t("aboutUsPage.theTeam")}
                      </h1>
                      <h6 className="text-white text-center mt-5">
                        {t("aboutUsPage.offerSectionContent")}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
export default withTranslation()(AboutUsComponent);
