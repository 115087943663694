import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import wallpaper from "../../assets/images/logo.png";
import { billingRegistrationForm, subscribeWrapmeForm } from "../../actions/vas";
import ModalERROR from "../Modals/ModalERROR";
import { vasError } from "../../actions/modals";
import { APP_MESSAGES } from "../../services/config/messages";
import {
  validateEmail,
  validateMsisdn,
  validatePassword,
  validateNumber
} from "../../utils/validators";
import { withTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import { lang } from "moment";

class BillingRegistrationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePhoneNumber: false
    }
  }
  componentDidMount() {
    if(this.props.subscribeWrapmeForm.fields.phonenumber.value){
      this.setState((state, props)=> {
        return {
          hidePhoneNumber: true
        }
      });
      this.props.billingRegistrationFormUpdateField(
        "phonenumber",
        parseInt(this.props.subscribeWrapmeForm.fields.phonenumber.value)
      );
    }
  }

  render() {
    let ErrorPhoneNum;
    let ErrorPassword;
    let ErrorRePassword;
    ErrorPhoneNum = this.props.billingRegistrationForm.fields.phonenumber
      .errorMsg
      ? APP_MESSAGES[
          this.props.billingRegistrationForm.fields.phonenumber.errorMsg
        ](
          this.props.billingRegistrationForm.fields.phonenumber.errorPayload,
          () => {
            this.props.billingRegistrationFormResetError("phonenumber");
          }
        )
      : props => <>{""}</>;

    ErrorPassword = this.props.billingRegistrationForm.fields.password.errorMsg
      ? APP_MESSAGES[
          this.props.billingRegistrationForm.fields.password.errorMsg
        ](
          this.props.billingRegistrationForm.fields.password.errorPayload,
          () => {
            this.props.billingRegistrationFormResetError("password");
          }
        )
      : props => <>{""}</>;

    ErrorRePassword = this.props.billingRegistrationForm.fields.repassword
      .errorMsg
      ? APP_MESSAGES[
          this.props.billingRegistrationForm.fields.repassword.errorMsg
        ](
          this.props.billingRegistrationForm.fields.repassword.errorPayload,
          () => {
            this.props.billingRegistrationFormResetError("repassword");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    const lang = i18n.language;
    return (
      <>
        <ModalERROR
          handleHide={() => {
            this.props.vasClose();
            this.props.billingRegistrationFormResetError("phonenumber");
            this.props.billingRegistrationFormResetError("password");
            this.props.billingRegistrationFormResetError("repassword");
          }}
          isOpen={this.props.isVasErrorModalOpen}
        >
          <ErrorPhoneNum />
          <ErrorPassword />
          <ErrorRePassword />
        </ModalERROR>
        <section className="billing-background">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 ">
                <div className="row d-flex flex-column height-fixed px-0 px-lg-3">
                  <div className="col-auto d-flex justify-content-center">
                    <div className="logo-wrapper bg-black-transparent d-flex align-items-center px-4">
                      <a href={"/" + lang}>
                        {" "}
                        <img src={wallpaper} className="img-fluid" alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="col bg-white text-center pl-3 pl-lg-5  pr-3 pr-lg-5 pt-2 pt-lg-4 overflow-auto">
                    <div className="row">
                      <div className="col-sm-12">
                        <p className="bold font-size-30">{t("regArcadeX")}</p>
                      </div>
                    </div>
                    <form
                      onSubmit={e => {
                        e.preventDefault();

                        if (!this.props.billingRegistrationForm.isSubmitting) {
                          if (
                            !this.props.billingRegistrationForm.fields
                              .phonenumber.value
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "phonenumber",
                              "VAS_NOT_EMPTY"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            !this.props.billingRegistrationForm.fields.password
                              .value
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "password",
                              "VAS_NOT_EMPTY"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            !validatePassword(
                              this.props.billingRegistrationForm.fields.password
                                .value
                            )
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "password",
                              "VAS_PASSWORD_LENGTH"
                            );
                            this.props.vasError();
                            return false;
                          }
                          if (
                            !validateNumber(
                              this.props.billingRegistrationForm.fields
                                .phonenumber.value
                            )
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "phonenumber",
                              "VAS_NUMBER_LENGTH"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            !this.props.billingRegistrationForm.fields
                              .repassword.value
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "repassword",
                              "VAS_NOT_EMPTY"
                            );
                            this.props.vasError();
                            return false;
                          }

                          if (
                            this.props.billingRegistrationForm.fields.password
                              .value !==
                            this.props.billingRegistrationForm.fields.repassword
                              .value
                          ) {
                            this.props.billingRegistrationFormSetError(
                              "password",
                              "COMPARE_PASSWORD"
                            );
                            this.props.vasError();
                            return false;
                          }

                          let postParam = {
                            mondiaSubscriptionId: this.props.billingSubscriptionForm
                              .fields.mondiaSubscriptionId.value,
                            password: this.props.billingRegistrationForm.fields
                              .password.value,
                            phoneNumber: this.props.billingRegistrationForm
                              .fields.phonenumber.value
                          };
                          console.log("postParam:",postParam);
                          this.props.billingRegistrationFormSubmit(postParam);
                        }
                      }}
                    >
                      <div className="box">
                        <div className="row">
                          { this.state.hidePhoneNumber ? <></> : 
                            <div className="col-sm-12">
                              <div className="form-group mb-2 has-warning">
                                <label className="lable bold font-size-21">
                                  {t("enterPhoneNumber")}
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={t("placePhoneNumber")}
                                  onChange={event => {
                                    this.props.billingRegistrationFormUpdateField(
                                      "phonenumber",
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          }
                          <div className="col-sm-12">
                            <div className="form-group mb-2 has-warning">
                              <label className="lable bold font-size-21">
                                {t("createPassword")}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder={t("placeNewPassword")}
                                onChange={event => {
                                  this.props.billingRegistrationFormUpdateField(
                                    "password",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 mb-3">
                            <div className="form-group mb-2 has-warning">
                              <label className="lable bold font-size-21">
                                {t("repassword")}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                placeholder={t("placeRePassword")}
                                onChange={event => {
                                  this.props.billingRegistrationFormUpdateField(
                                    "repassword",
                                    event.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4 custom-padding">
                          <button
                            type="submit"
                            className={
                              "btn btn-primary py-2 py-lg-3 btn-block rounded-pill font-size-24 bold text-shadow " +
                              (this.props.billingRegistrationForm.isSubmitting
                                ? " disabled"
                                : "")
                            }
                          >
                            {t("Confirm")}
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 mt-3 mt-lg-4">
                            <div className="blank-space"></div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapDispatchToProps = {
  billingRegistrationFormSubmit: billingRegistrationForm.submit,
  billingRegistrationFormReset: billingRegistrationForm.reset,
  billingRegistrationFormResetField: billingRegistrationForm.resetField,
  billingRegistrationFormUpdateField: billingRegistrationForm.updateField,
  billingRegistrationFormSetError: billingRegistrationForm.setError,
  billingRegistrationFormSetSuccess: billingRegistrationForm.setSuccess,
  billingRegistrationFormResetError: billingRegistrationForm.resetError,
  billingRegistrationFormResetSuccess: billingRegistrationForm.resetSuccess,
  vasError: vasError.open,
  vasClose: vasError.close
};

const mapStateToProps = state => {
  return {
    billingRegistrationForm: state.vas_blacklist.billingregistrationform,
    subscribeWrapmeForm: state.vas.subscribewrapmeform,
    billingSubscriptionForm: state.vas.billingsubscriptionform,
    isVasErrorModalOpen: state.modals.isVasErrorModalOpen
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BillingRegistrationComponent));
