import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./NewsDetailsComponent.scss";
import avatarDemo from "../../assets/images/avatar-demo.png";
import DashboardNews from "../Dashboard/DashboardNews/DashboardNewsComponents";
import moment from "moment";
import NotFoundContainer from "../../containers/NotFoundContainer";
import { withTranslation } from "react-i18next";

class NewsDetailsComponent extends Component {
  componentDidMount() {
    const { fetchNewsDetailsIfNeeded, invalidateNewsDetails } = this.props;
    invalidateNewsDetails();
    fetchNewsDetailsIfNeeded({ newsId: this.props.match.params.id });
  }
  render() {
    const { newsDetails, t } = this.props;

    if (!newsDetails) {
      return <NotFoundContainer />;
    }

    if (newsDetails) {
      return (
        <>
          <section className="container-fluid banner-wrapper news-wrapper">
            <div className="row">
              <div className="banner news news-details">
                <div className="d-block d-lg-none">
                  <img
                    src={newsDetails.categoryImageUrl}
                    className="img-fluid"
                  />
                </div>
                <svg
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="100%"
                  height="100%"
                  viewBox="0 0 1345.6 495.3"
                  enableBackground="new 0 0 1345.6 495.3"
                  className="d-none d-lg-block"
                  xmlSpace="preserve"
                  preserveAspectRatio="none"
                >
                  <defs>
                    <clipPath id="hexagonal-mask">
                      <path
                        d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                        fill="none"
                      />
                    </clipPath>
                  </defs>
                  <image
                    clipPath="url(#hexagonal-mask)"
                    height="100%"
                    width="100%"
                    xlinkHref={newsDetails.categoryImageUrl}
                    preserveAspectRatio="none"
                  />
                </svg>
              </div>
            </div>
          </section>
          <section className="[ container-fluid ] news  detail-section news-detail-section news-only">
            <div className="pd-2">
              <div className="row">
                <div className="container">
                  <div className="row mb-5">
                    <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center ">
                      <figure className="news-avatar">
                        <img src={newsDetails.categoryIconUrl} alt="" />
                      </figure>
                      <figcaption className="my-4">
                        <h4 className="bold">{newsDetails.categoryName}</h4>{" "}
                      </figcaption>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center justify-content-center ">
                    <div className="col-sm-12 col-xl-9 d-flex justify-content-start">
                      <h2 className="bold display-2">{newsDetails.title}</h2>
                    </div>
                  </div>
                  <div className="row mt-3 mb-6  justify-content-center">
                    <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                      <div className="ml-3 fs-12">
                        {t("postedOn")}{" "}
                        {moment(newsDetails.createdOn).format("MMMM DD, YYYY")}
                      </div>
                    </div>
                  </div>

                  <div className="row  justify-content-center">
                    <div className="col-sm-12 col-xl-9" />
                  </div>

                  <div className="row justify-content-center mt-6">
                    <div className="col-sm-12 col-xl-9">
                      <div className="bannerImg-section">
                        <img src={newsDetails.imageUrl} alt="" />
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center mt-6">
                    <div className="col-sm-12 col-xl-9 bb-1 pb-5">
                      <p className="mt-4">
                        {newsDetails.content ? newsDetails.content.split("\n").map((item, key) => {
                          return (
                            <React.Fragment key={key}>
                              {item}
                              <br />
                            </React.Fragment>
                          );
                        }) : ""}
                      </p>
                      <p className="mt-4">
                        Source :{" "}
                        <a href={newsDetails.source} target="_blank">
                          {newsDetails.source}
                        </a>
                      </p>
                    </div>
                  </div>

                  {newsDetails.authorName ? (
                    <div className="row justify-content-center mt-5">
                      <div className="col-sm-12 col-xl-9 ">
                        <div className="media">
                          <figure>
                            <img
                              src={
                                newsDetails.authorImage
                                  ? newsDetails.authorImage
                                  : avatarDemo
                              }
                              className="profile-pic-96"
                              alt=""
                            />
                            {/* <figcaption className="online" /> */}
                          </figure>
                          <div className="media-body ml-4">
                            <h4 className="mb-3">{newsDetails.authorName}</h4>
                            <p className="mb-4">{newsDetails.authorAbout}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </section>
          <section className="[ container-fluid ] quick-play section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">
                        {t("related")} {t("navNews")}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {newsDetails.relatedNews &&
                newsDetails.relatedNews.length > 0 ? (
                  <DashboardNews list={newsDetails.relatedNews} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">{t("noNewsFound")}</h6>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      );
    } else {
      return <></>;
    }
  }
}
export default withTranslation()(NewsDetailsComponent);
