import { connect } from "react-redux";
import ChallengeComponent from "../../components/Challenge/ChallengeComponent";
import { challengeDetails } from "../../actions/challenges";
import { chatListSidebar } from "../../actions/sidebars";
import { openChatHistory } from "../../actions/chathistory";
import { newsList } from "../../actions/news";

import { quickChallengeJoin } from "../../actions/modals";
import { joinChallengePasswordForm } from "../../actions/forms";

const mapDispatchToProps = {
  fetchChallengeDetailsIfNeeded: challengeDetails.fetchIfNeeded,
  invalidateChallengeDetails: challengeDetails.invalidate,
  quickChallengeJoinOpen: quickChallengeJoin.open,
  joinChallengePasswordFormSubmit: joinChallengePasswordForm.submit,
  joinChallengePasswordFormReset: joinChallengePasswordForm.reset,
  fetchNewsIfNeeded: newsList.fetchIfNeeded,
  invalidateNewsIfNeeded: newsList.invalidate,

  chatListSidebarOpen: chatListSidebar.open,
  openChatHistory: openChatHistory
};

const mapStateToProps = state => ({
  newsListAll: state.news.newsList.items,
  challengeDetails: state.challenges.challengeDetails || null,
  challengesByBrand: state.challenges.challengesByBrand || null,
  joinChallengePasswordForm: state.forms.joinChallengePasswordForm,
  finishGameChallengeForm: state.forms.finishGameChallengeForm,
  user: state.user.item || null,
  allOnlineUserList: state.socketusers.allOnlineUserList
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeComponent);
