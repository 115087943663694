import ContactUsComponent from "../components/ContactUs/ContactUsComponent";
import { connect } from "react-redux";

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactUsComponent);
