import React, { Component } from "react";
import { connect } from "react-redux";
import { unfollow } from "../../../actions/modals";
import { unfollowForm } from "../../../actions/forms";
import { AllMsgClear } from "../../../actions/alerts";
import "./UnfollowModal.scss";
import Modal from "../Modal";
import { APP_MESSAGES } from "../../../services/config/messages";
import avatar3x from "../../../assets/images/avatar-demo.png";
import { withTranslation } from "react-i18next";

class UnfollowModal extends Component {
  constructor(props) {
    super(props);

    this.showUnfollow = this.showUnfollow.bind(this);
    this.hideUnfollow = this.hideUnfollow.bind(this);

    this.state = {
      isLandScape: false
    };
  }

  showUnfollow() {
    this.props.unfollowOpen();
  }

  hideUnfollow() {
    this.props.unfollowClose();
    this.props.AllMsgClear();
  }
  modalFooter() {
    const { t } = this.props;
    return (
      <div className="modal-footer">
        <ul className="list-group w-100 list-group-flush">
          <li
            className="list-group-item text-center p-4"
            onClick={e => {
              e.preventDefault();
              if (!this.props.unfollowForm.isSubmitting) {
                if (!this.props.unfollowForm.fields.followerId.value) {
                  this.props.unfollowFormSetError("followerId", "NOT_EMPTY");
                  return false;
                }

                if (!this.props.unfollowForm.fields.followeeId.value) {
                  this.props.unfollowFormSetError("followerId", "NOT_EMPTY");
                  return false;
                }

                this.props.unfollowFormSubmit({
                  followeeId: this.props.unfollowForm.fields.followeeId.value
                });
              }
            }}
          >
            <span className="a text-danger">
              <b>{t("unfollow")}</b>
            </span>
          </li>
          <li className="list-group-item text-center p-4">
            <span className="a" onClick={this.hideUnfollow}>
              {t("challengePage.cancel")}
            </span>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    let Success;
    let Error;

    Error = this.props.unfollowForm.fields.followerId.errorMsg
      ? APP_MESSAGES[this.props.unfollowForm.fields.followerId.errorMsg](
          this.props.unfollowForm.fields.followerId.errorPayload,
          () => {
            this.props.unfollowFormResetError("followerId");
          }
        )
      : props => <>{""}</>;

    Success = this.props.unfollowForm.fields.followerId.successMsg
      ? APP_MESSAGES[this.props.unfollowForm.fields.followerId.successMsg](
          this.props.unfollowForm.fields.followerId.successPayload,
          () => {
            this.props.unfollowFormResetSuccess("followerId");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideUnfollow}
          isOpen={this.props.isUnfollowOpen}
          disableOuterClick={false}
          modalClass="modal modal-unfollow modal-change-password"
          withFooter={true}
          footer={this.modalFooter()}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <figure>
                  <img
                    src={
                      this.props.unfollowForm.fields.avatarUrl.value
                        ? this.props.unfollowForm.fields.avatarUrl.value
                        : avatar3x
                    }
                    className="profile-icon profile-pic-80"
                    alt=""
                  />
                  <figcaption className="online" />
                </figure>
              </div>
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h5>
                {t("unfollow")}{" "}
                  <span className="bold">
                    {this.props.unfollowForm.fields.userName.value}
                  </span>{" "}
                  ?
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12" />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  unfollowOpen: unfollow.open,
  unfollowClose: unfollow.close,
  AllMsgClear,
  unfollowFormSubmit: unfollowForm.submit,
  unfollowFormReset: unfollowForm.reset,
  unfollowFormResetField: unfollowForm.resetField,
  unfollowFormUpdateField: unfollowForm.updateField,
  unfollowFormSetError: unfollowForm.setError,
  unfollowFormSetSuccess: unfollowForm.setSuccess,
  unfollowFormResetError: unfollowForm.resetError,
  unfollowFormResetSuccess: unfollowForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isUnfollowOpen: state.modals.isUnfollowOpen,
    isLogging: state.login.isLogging,
    unfollowForm: state.forms.unfollowForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UnfollowModal));
  