import { connect } from "react-redux";
import ClaimComponent from "../components/Claim/ClaimComponent";
import { claimChallengeDetails } from "../actions/challenges";
import { claimPrizeForm, verifyPrizeForm, getLastPrizeForm } from "../actions/forms";
import { verifyPrizeModal } from "../actions/modals";
import { getNotificationDataById } from "../actions/notification";

const mapDispatchToProps = {
  fetchClaimChallengeDetailsIfNeeded: claimChallengeDetails.fetchData,
  
  verifyPrizeFormSubmit: verifyPrizeForm.submit,
  verifyPrizeFormUpdateField: verifyPrizeForm.updateField,
  getLastPrizeFormSubmit: getLastPrizeForm.submit,

  claimPrizeFormSubmit: claimPrizeForm.submit,
  claimPrizeFormReset: claimPrizeForm.reset,
  claimPrizeFormResetField: claimPrizeForm.resetField,
  claimPrizeFormUpdateField: claimPrizeForm.updateField,
  claimPrizeFormSetError: claimPrizeForm.setError,
  claimPrizeFormSetSuccess: claimPrizeForm.setSuccess,
  claimPrizeFormResetError: claimPrizeForm.resetError,
  claimPrizeFormResetSuccess: claimPrizeForm.resetSuccess,

  verifyPrizeOpen: verifyPrizeModal.open,
  getNotificationDataById:getNotificationDataById
};

const mapStateToProps = state => {
  return {
    claimChallengeData: state.challenges.claimChallengeDetails || null,
    claimPrizeForm: state.forms.getClaimPrizeForm || null,
    verifyPrizeForm: state.forms.verifyPrizeForm || null,
    userId: state.user.item.id || null,
    notificationData: state.notifications.list || null,

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimComponent);
