import React, { Component } from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";

// 'Fancy' theme - boilerplate styles for all components, as seen on our demo:
import "react-accessible-accordion/dist/fancy-example.css";
import "./HelpSupportComponent.scss";
import HaveAnotherQuestionComponent from "./HaveAnotherQuestionComponent";
import { withTranslation, Trans } from "react-i18next";
import { isNgCountry } from "../../utils/misc";
import platformParams from "../../utils/platformParams";

class HelpSupportComponent extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="container-fluid banner-wrapper news-wrapper">
          <div className="row">
            <div
              className={
                "banner help-support position-relative " + platformParams['className']
              }
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  contents">
                  <div className="col-sm-12 col-xl-9 d-flex flex-column  justify-content-center align-items-start">
                    <h2 className="bold display-1 text-white mb-3 uppercase">
                      {t("helpSupport")}
                    </h2>
                    {process.env.REACT_APP_PLATFORM !== "mtn" &&  
                        <h5 className="text-white">
                          {t("helpSupportPage.takeLook")}
                        </h5>
                    }
                   
                  </div>
                </div>
              </div>
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1345.6 495.3"
                enable-background="new 0 0 1345.6 495.3"
                className="svgIE"
                xmlSpace="preserve"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="hexagonal-mask">
                    <path
                      d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                      Fill="none"
                    />
                  </clipPath>
                </defs>
                <image
                  clip-path="url(#hexagonal-mask)"
                  height="100%"
                  width="100%"
                  xlinkHref={platformParams['helpSupportBanner']}
                  preserveAspectRatio="none"
                />
              </svg>
            </div>
          </div>
        </section>

        <section className="[ container-fluid ] news  event-section help-support about-mtn-treasure">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12 col-xl-9 d-flex justify-content-start">
                    <h2 className="bold">{t("helpSupportPage.general")}</h2>
                  </div>
                </div>
                <div className="row mt-3 mb-6  justify-content-center">
                  <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                    <div className="tournament-accordian w-100">
                      <General t={t} />
                    </div>
                  </div>
                </div>
                <div className="row d-flex align-items-center justify-content-center mt-6">
                  <div className="col-sm-12 col-xl-9 d-flex justify-content-start">
                    <h2 className="bold">{t("navTournaments")}</h2>
                  </div>
                </div>
                <div className="row mt-3 mb-6  justify-content-center">
                  <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                    <div className="tournament-accordian w-100">
                      <Tournaments t={t} />
                    </div>
                  </div>
                </div>
                {
                  !isNgCountry() ? 
                    <> 
                      <div className="row d-flex align-items-center justify-content-center mt-6">
                        <div className="col-sm-12 col-xl-9 d-flex justify-content-start">
                          <h2 className="bold">{t("raffles")}</h2>
                        </div>
                      </div>
                      <div className="row mt-3 mb-6  justify-content-center">
                        <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                          <div className="tournament-accordian w-100">
                            <Raffles t={t} />
                          </div>
                        </div>
                      </div>
                    </> 
                  : <></>
                }
                
                {/* <div className="row d-flex align-items-center justify-content-center mt-6">
                  <div className="col-sm-12 col-xl-9 d-flex justify-content-start">
                    <h2 className="bold">My Account</h2>
                  </div>
                </div>
                <div className="row mt-3  justify-content-center">
                  <div className="col-sm-12 col-xl-9 d-flex align-items-center justify-content-start">
                    <div className="tournament-accordian w-100">
                      <MyAccount />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <HaveAnotherQuestionComponent t={t} />
      </>
    );
  }
}

const Desc =
  "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.";

const TitleHtml = props => (
  <h4 className="semi-bold text-left u-position-relative">
    {props.title}
    <div className="accordion__arrow" role="presentation" />
  </h4>
);

const DescHtml = props => (
  <div className="card-body text-left">{props.desc}</div>
);
const envName = platformParams['envTitle'];

const General = props => (
  <Accordion>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={`${props.t("helpSupportPage.whatIs")} ${envName}?`} />
      </AccordionItemTitle>
      <AccordionItemBody>
        {
          isNgCountry() ?  
            <DescHtml
            desc={
              <Trans i18nKey="helpSupportPage.NGWhatIsAns" envName={envName}>
                {{ envName }} is a mobile gaming platform that hosts tournaments
                for you enter into. There are a variety of games to choose from.
              </Trans>
            }
          />
          : <DescHtml
              desc={
                <Trans i18nKey="helpSupportPage.whatIsAns" envName={envName}>
                  {{ envName }} is a mobile gaming platform that hosts tournaments
                  for you enter into. There are a variety of games to choose from as
                  well as the chance to win a prize! Not the best gamer around? No
                  worries, as there are weekly raffles that give everyone a chance
                  to win!
                </Trans>
              }
            />
        }
       
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.howDoPlay")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml
          desc={
            <Trans i18nKey="helpSupportPage.playAns" envName={envName}>
              Once you have subscribed to {{ envName }}, you will be taken to
              the dashboard, where you can choose which games you want to play
              and which tournaments you want to enter.
            </Trans>
          }
        />
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.howDoSearch")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.searchAns")} />
      </AccordionItemBody>
    </AccordionItem>
    {/* <AccordionItem className="card">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How do I follow a host?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem> */}
  </Accordion>
);

const Tournaments = props => (
  <Accordion>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.whatIsTornament")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.tournamentAns")} />
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.whatIsQuickPlay")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.quickPlayAns")} />
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.whatLeaderboard")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.leaderBoardAns")} />
      </AccordionItemBody>
    </AccordionItem>
    
    { !isNgCountry() ? 
      <>
        <AccordionItem className="card bb-1">
          <AccordionItemTitle className="card-header">
            <TitleHtml title={props.t("helpSupportPage.whatGoldCoin")} />
          </AccordionItemTitle>
          <AccordionItemBody>
            <DescHtml desc={props.t("helpSupportPage.goldTokenAns")} />
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem className="card bb-1">
          <AccordionItemTitle className="card-header">
            <TitleHtml title={props.t("helpSupportPage.howDoWinPrize")} />
          </AccordionItemTitle>
          <AccordionItemBody>
            <DescHtml desc={props.t("helpSupportPage.winPrizeAns")} />
          </AccordionItemBody>
        </AccordionItem>
      </>
      : <></>
    }
    {/* <AccordionItem className="card">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How do I contact a host?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem> */}
  </Accordion>
);

const Raffles = props => (
  <Accordion>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.whatIsRaffleTicket")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.raffleTicketAns")} />
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.howDoEnterRaffle")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={props.t("helpSupportPage.enterRaffleAns")} />
      </AccordionItemBody>
    </AccordionItem>
    {/* <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How many raffles can I enter?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem> */}
    <AccordionItem className="card">
      <AccordionItemTitle className="card-header">
        <TitleHtml title={props.t("helpSupportPage.howClaimRafflePrize")} />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml
          desc={
            <Trans i18nKey="helpSupportPage.claimRaffleAns" envName={envName}>
              You will be notified that you have won a raffle, followed by being
              contacted by the {{ envName }} support team.
            </Trans>
          }
        />
      </AccordionItemBody>
    </AccordionItem>
  </Accordion>
);

const MyAccount = () => (
  <Accordion>
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How do I cancel my subscription?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem>
    {/* <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How do I play Raffles?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem> */}
    <AccordionItem className="card bb-1">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="How do I delete my account?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem>
    <AccordionItem className="card">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="Is my data stored?" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={Desc} />
      </AccordionItemBody>
    </AccordionItem>
    {/* <AccordionItem className="card">
      <AccordionItemTitle className="card-header">
        <TitleHtml title="News And Events" />
      </AccordionItemTitle>
      <AccordionItemBody>
        <DescHtml desc={"Will have copywriter do some copy for this section. Will revert asap on this."} />
      </AccordionItemBody>
    </AccordionItem> */}
  </Accordion>
);

export default withTranslation()(HelpSupportComponent);
