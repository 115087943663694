import React, { Component } from "react";
import helpSupportBannerImg from "../../assets/images/help-support-banner.jpg";
import "./TermsConditionsComponent.scss";

class TermsContent extends Component {
  render() {
    return (
      <>
        <section className={this.props.setclass}>
          <div
            className={
              this.props.setclass !== "container-fluid" ? "padder-main" : ""
            }
          >
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center ">
                  <div className="col-sm-12">
                    <h6
                      className={
                        "mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      MODALITÉS ET CONDITIONS D'UTILISATION
                    </h6>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      1 Introduction
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.1</span>Modalités
                        </p>
                        Bonjour et bienvenue dans la section détaillant nos
                        conditions générales d'utilisation{" "}
                        <span className="text-black bold">(“Modalités”)</span>.
                        Les présentes modalités sont importantes car elles :
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>énoncent les modalités
                        et conditions en vertu desquelles nous vous accordons le
                        droit d'utiliser ce logiciel en nuage pour dispositifs
                        intelligents{" "}
                        <span className="text-black bold">(“Logiciel”)</span>{" "}
                        et nos services, y compris toutes les instructions sous
                        forme électronique ou sur papier, ainsi que toute mise à
                        jour, modification ou publication de toute partie de ce
                        Logiciel ou de ce service{" "}
                        <span className="text-black bold">(“Services”)</span>.
                        Vous pouvez accéder, utiliser, acheter ou vous abonner
                        aux Services en téléchargeant l'application sur un
                        appareil mobile ou autre plateforme (par exemple un site
                        Web) <span className="text-black bold">(“Appli”)</span>
                        ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>elles vous contraignent
                        à partir de la date{" "}
                        <span className="text-black bold">
                          (“Date d’entrée en vigueur”)
                        </span>{" "}
                        à laquelle vous téléchargez ou accédez à l’Appli et que
                        nous vous fournissons un compte vous permettant
                        d'accéder et d'utiliser le Service{" "}
                        <span className="text-black bold">(“Compte”)</span>{" "}
                        jusqu'à la date à laquelle votre Compte est résilié
                        conformément aux présentes modalités{" "}
                        <span className="text-black bold">(“Modalités”)</span>.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.2</span>Modalités
                        </p>
                        Veuillez lire attentivement les présentes Modalités et
                        résilier immédiatement votre compte si vous n’y convenez
                        pas.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.3</span>Clauses obligatoires
                          d’Apple.
                        </p>
                        Si vous téléchargez ou accédez à l'Appli à partir de
                        l’App Store de Apple Inc{" "}
                        <span className="text-black bold">(“Apple”)</span> vous
                        acceptez le Contrat de licence d'utilisateur final de
                        l'Appli sous licence d'Apple et toutes les règles
                        d'utilisation énoncées dans les Conditions d'utilisation
                        de l'App Store et si vous téléchargez l'Appli à partir
                        de Google Play Store, vous acceptez les modalités et
                        conditions d’utilisation d’Android Google Inc, y compris
                        le contrat de licence et les conditions d'application.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">1.4</span>En créant un Compte
                          et/ou en accédant et/ou en utilisant le Service, vous:
                        </p>
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>nous garantissez que
                        vous avez lu les présentes conditions, y compris notre
                        politique de confidentialité, disponible dans l'Appli,
                        et que vous les comprenez;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>nous garantissez que
                        vous avez la capacité juridique de conclure un accord
                        juridiquement contraignant;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>nous garantissez que
                        vous avez l'autorité d'agir au nom de toute personne ou
                        entité pour laquelle vous utilisez le Service, et l’on
                        considère que vous avez accepté les présentes Modalités
                        au nom de l’entité pour laquelle vous utilisez le
                        Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>vous nous garantissez
                        que vous disposez de tout le matériel, des logiciels et
                        des services nécessaires pour accéder au Service et
                        l’utiliser ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>acceptez d'utiliser le
                        Service conformément aux présentes Modalités.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4 " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      2 Enregistrement et Compte
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.1</span>Compte
                        </p>
                        Vous allez devoir créer un Compte. Vous devez vous
                        assurer que tous les renseignements personnels que vous
                        nous fournissez lorsque vous créez un compte sont exacts
                        et à jour. Tous les renseignements personnels que vous
                        nous fournirez seront traités conformément à notre
                        politique de confidentialité.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.2</span>Utiliser des réseaux
                          sociaux pour s’inscrire
                        </p>
                        Vous pouvez vous inscrire à un Compte en utilisant votre
                        compte Facebook, Google ou tout autre compte de réseaux
                        sociaux disponible sur l'Appli{" "}
                        <span className="text-black bold">
                          (“compte de réseaux sociaux”)
                        </span>
                        . Si vous vous connectez à votre Compte à l'aide de
                        votre compte de réseaux sociaux, vous nous autorisez à
                        accéder à certaines informations sur votre compte de
                        réseaux sociaux, y compris, entre autres, votre photo de
                        profil actuelle et autres informations de base.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.3</span>Noms et comptes de
                          l’Utilisateur
                        </p>
                        Lorsque vous créez un Compte, vous pourrez choisir ou
                        l'Appli générera certains détails pour le Compte (tels
                        qu’un nom d'utilisateur et un mot de passe). Il vous
                        revient la responsabilité de préserver la
                        confidentialité des détails de votre Compte. Vous êtes
                        responsable de tout le contenu affiché et de toute
                        activité sur votre Compte, y compris les achats
                        effectués en utilisant les détails de votre Compte, et
                        vous devez nous informer immédiatement de toute
                        utilisation non autorisée de votre Compte.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.4</span>Profil
                        </p>
                        Une fois que vous avez enregistré un Compte, les
                        informations de votre Compte seront utilisées pour créer
                        un profil que vous pourrez ensuite organiser{" "}
                        <span className="text-black bold">(“Profil”)</span>.
                        Votre Profil est personnel et vous ne devez pas le
                        transférer à d'autres personnes, sauf suite à notre
                        consentement par écrit.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.5</span>Limite d’âge
                        </p>
                        Vous ne devez pas créer un Compte si vous avez moins de
                        18 ans.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">2.6</span>Notre discrétion
                        </p>
                        A notre seule discrétion, nous pouvons refuser de
                        permettre à toute personne de créer un Compte.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      3 Usage de l’Appli
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">3.1</span>Usage de l’Appli
                        </p>
                        Notre Appli propose des jeux d'adresse, sans risque ni
                        hasard, où les joueurs peuvent gagner des prix. Les
                        utilisateurs peuvent directement envoyer des messages à
                        d'autres utilisateurs sur l'Appli, et doivent le faire
                        conformément au présent Accord.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      4 Avis de recueil des informations
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.1</span>Recueil des
                          informations
                        </p>
                        Nous recueillons certaines de vos informations
                        personnelles afin de vous permettre d'accéder à l'Appli
                        et de l'utiliser, de communiquer avec vous, d'analyser
                        vos intérêts et de vous proposer des services
                        personnalisés, de répondre à vos demandes de
                        renseignements et à d'autres fins énoncées dans notre
                        Politique de confidentialité.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.2</span>Parties tierces
                        </p>
                        Nous pouvons divulguer ces renseignements à des tiers
                        fournisseurs de services qui nous aident à fournir nos
                        services (y compris des fournisseurs de services de
                        technologie de l'information, de stockage de données,
                        d'hébergement Web et de serveurs, des conseillers
                        professionnels, des exploitants de systèmes de paiement
                        et nos partenaires commerciaux) ou comme l'exige la loi.
                        Si vous ne fournissez pas ces informations, nous
                        pourrions ne pas être en mesure de vous fournir le
                        Service. Dans certaines circonstances, nous pouvons
                        divulguer vos informations personnelles à des parties
                        tierces situées à l’extérieur de l'Australie ou qui
                        stockent des données hors de l'Australie.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.3</span>Politique de
                          confidentialité
                        </p>
                        Notre Politique de confidentialité comporte de plus
                        amples informations sur:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>comment nous conservons
                        et utilisons vos renseignements personnels;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>comment vous pouvez
                        accéder à vos renseignements personnels et demander
                        qu'ils soient corrigés;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>comment vous pouvez
                        déposer une plainte relative à la confidentialité ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>notre processus de
                        traitement des plaintes. En nous fournissant des
                        renseignements personnels, vous consentez à ce que nous
                        recueillions, conservions, utilisions et divulguions vos
                        renseignements personnels conformément à notre Politique
                        de confidentialité.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">4.4</span>Analyses
                        </p>
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>Malgré toute
                        disposition contraire dans les présentes Modalités ou
                        ailleurs, nous pouvons surveiller, analyser et compiler
                        des informations statistiques et de performance basées
                        sur et/ou liées à votre utilisation du Service, dans un
                        format agrégé et anonyme{" "}
                        <span className="text-black bold">(“Analyses”)</span>.
                        Vous acceptez que nous puissions mettre de telles
                        Analyses à la disposition du public, à condition
                        qu'elles:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>ne contiennent pas
                        d'informations d'identification ; et
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>ne soient pas
                        compilées à partir d'un échantillon suffisamment petit
                        pour que les données sous-jacentes soient identifiables.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>Nous et/ou nos
                        concédants de licence détenons tous les droits, titres
                        et intérêts relatifs aux analyses et à tous les
                        logiciels, technologies, documentations et contenus
                        connexes fournis en rapport avec les Analyses, y compris
                        tous les droits de propriété intellectuelle mentionnés
                        ci-dessus.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      5 Licence
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">5.1</span>Licence
                        </p>
                        Nous vous accordons une licence personnelle non
                        exclusive, non transférable, ne pouvant faire l'objet
                        d'une sous-licence (sauf si autrement autorisé par les
                        présentes Modalités) pour accéder et utiliser le Service
                        à vos fins personnelles pendant la Durée{" "}
                        <span className="text-black bold">(“Licence”)</span>.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">5.2</span>Usage
                        </p>
                        Vous convenez que:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>la Licence vous permet
                        d'utiliser le Service conformément aux présentes
                        Modalités d'utilisation ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>nous nous réservons le
                        droit, à tout moment et de temps à autre, de:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>refuser toute demande
                        relative au Service que nous jugeons inappropriée,
                        déraisonnable, illégale ou autrement non conforme aux
                        présentes Modalités ; et/ou
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>modifier ou
                        interrompre, temporairement ou définitivement, l'accès
                        au Service (ou à toute partie du Service) avec préavis.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      6 Directives aux utilisateurs
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">6.1</span>Droits de propriété
                          intellectuelle
                        </p>
                        Vous ne devez pas accéder ou utiliser le Service sauf
                        dans conformément à la Licence et vous ne devez pas
                        faire, ou omettre de faire, tout acte qui invaliderait
                        ou pourrait invalider ou être incompatible avec nos
                        droits de propriété intellectuelle sur le Service ou le
                        Logiciel.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">6.2</span>Directives
                        </p>
                        Vous ne devez pas:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>revendre, céder,
                        transférer, distribuer ou fournir l'accès au Service à
                        d'autres;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>"encadrer", "refléter",
                        ou offrir n'importe quelle partie du Service sur un
                        quelconque serveur web ou autre serveur informatique sur
                        Internet ou n'importe quel autre réseau;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>copier, altérer,
                        modifier, créer des œuvres dérivées, reproduire à un
                        tiers, assembler à l'envers, faire de l'ingénierie
                        inverse, compiler à l'envers ou améliorer le Service ou
                        le Logiciel;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>modifier, supprimer ou
                        altérer toute marque de commerce, tout avis de brevet ou
                        de droit d'auteur, toute légende ou avis de
                        confidentialité, tout numéro ou tout autre moyen
                        d'identification utilisé sur ou par rapport au Service
                        ou au Logiciel;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>utiliser le Service
                        pour faire des offres frauduleuses de biens ou de
                        services;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>utiliser le Service de
                        manière à porter atteinte aux lois et règlements locaux,
                        étatiques, fédéraux et internationaux applicables{" "}
                        <span className="text-black bold">(“les Lois”)</span>{" "}
                        ou qui viole les droits d'une personne, y compris les
                        droits de propriété intellectuelle;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>utiliser le Service
                        pour transmettre, publier ou communiquer du matériel
                        diffamatoire, offensant, abusif, indécent, menaçant ou
                        indésirable;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(h)</span>utiliser le Service de
                        quelque manière que ce soit qui endommage, entrave ou
                        interrompt la fourniture du Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(i)</span>introduire des
                        programmes malveillants dans notre matériel et nos
                        Logiciels ou dans notre matériel, nos Logiciels et nos
                        services qui sont intégrés et fonctionnent ensemble, y
                        compris nos réseaux, notamment les virus, vers, chevaux
                        de Troie et bombes électroniques;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(j)</span>révéler le mot de passe
                        de votre Compte à d'autres personnes ou permettre à
                        d'autres personnes d'utiliser votre Compte;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(k)</span>uutiliser le Service
                        pour effectuer des perturbations ou des violations de la
                        sécurité d'un réseau. Les violations de la sécurité
                        comprennent l'accès aux données lorsque vous n'êtes pas
                        le destinataire prévu ou la connexion à un serveur ou à
                        un compte que vous n'êtes pas expressément autorisé à
                        accéder ou à corrompre des données (y compris le
                        reniflement ou la surveillance d’un réseau, des
                        inondations de pings, des attaques de paquets,
                        l’usurpation, le déni de service et les fausses
                        informations de routage pour fins malicieuses);
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(l)</span>utiliser tout
                        programme/script/commande, ou envoyer des messages de
                        toute nature, avec l'intention d'interférer ou de
                        désactiver l'utilisation du Service par toute personne;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(m)</span>envoyer des messages
                        électroniques non sollicités par l'intermédiaire du
                        Service ou à des utilisateurs du Service en violation de
                        la Loi anti spam 2003 (Cth) ou pour envoyer toute forme
                        de harcèlement par courrier électronique, ou toute autre
                        forme de messagerie, que ce soit par la langue, la
                        fréquence ou la taille des messages ou utiliser le
                        Service en violation de la vie privée de toute personne
                        (comme par le vol ou « hameçonnage » d'identité) ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(n)</span>utiliser le Service
                        pour contourner l'authentification de l’utilisateur ou
                        la sécurité de vos réseaux, comptes ou hôtes ou de ceux
                        de vos clients ou fournisseurs.
                      </li>
                    </ul>

                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      7 Les droits que vous nous accordez
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.1</span>Autorisation
                        </p>
                        Pour que nous puissions vous fournir les Services, vous
                        nous accordez, ainsi qu'à nos sous-traitants, une
                        licence mondiale, non exclusive, perpétuelle,
                        irrévocable, libre de redevances, entièrement payée,
                        transférable et pouvant faire l'objet d'une sous-licence
                        afin de:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>utiliser, copier,
                        sauvegarder, traiter, transmettre, stocker, éditer,
                        modifier, agréger, combiner, reproduire, distribuer,
                        afficher, exécuter et préparer des travaux dérivés des
                        informations, documents, d’autres données que vous nous
                        fournissez, et de tout contenu que vous téléchargez ou
                        auquel nous avons autrement accès en fournissant le
                        Service y compris le Contenu d’utilisateur{" "}
                        <span className="text-black bold">(“Données”)</span>;
                        et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>autrement accéder,
                        utiliser ou de faire référence aux Données ou à tout
                        droit de propriété intellectuelle sur les Données:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>fournir le Service;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>à des fins de
                        diagnostic;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iii)</span>pour tester,
                        améliorer et autrement modifier le Service;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iv)</span>développer d'autres
                        services ; et
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(v)</span>comme raisonnablement
                        requis pour l'exécution de nos obligations en vertu des
                        présentes Modalités.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.2</span>Les droits moraux
                          australiens
                        </p>
                        Si vous ou l'un de vos employés avez des droits moraux
                        (tels que définis dans la Loi sur les Droits d’auteur de
                        1968 (Cth)){" "}
                        <span className="text-black bold">
                          (“Droits moraux”)
                        </span>{" "}
                        dans toutes Données, vous (et vous vous assurerez que
                        votre personnel) consentez à la violation de ces Droits
                        moraux par nous ou notre personnel.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.3</span>L’intégrité des
                          données
                        </p>
                        Vous devez, à tout moment, vous assurer de l'intégrité
                        de vos Données et de la conformité de votre utilisation
                        de vos Données avec toutes les Lois. Vous déclarez et
                        garantissez que:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>vous avez obtenu tous
                        les droits, renonciations et autorisations nécessaires
                        pour nous fournir toutes vos Données et pour nous
                        accorder les droits qui nous sont accordés dans les
                        présentes Modalités ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>vos Données et leur
                        transfert et leur utilisation par nous, tels
                        qu'autorisés par vous en vertu des présentes Modalités,
                        ne violent aucune loi (y compris celles relatives au
                        contrôle des exportations et aux communications
                        électroniques) ni aucun droit d'un tiers, y compris les
                        droits de propriété intellectuelle, les droits de
                        confidentialité ou les droits de publicité, et toute
                        utilisation, collecte et divulgation autorisées dans les
                        présentes Modalités n'est pas incompatible avec les
                        modalités des éventuelles politiques de confidentialité
                        applicables. Nous ne sommes pas responsables de vos
                        Données. Vous serez seul responsable de vos Données et
                        des conséquences de leur utilisation, divulgation,
                        stockage ou transmission.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.4</span>Pas de surveillance
                        </p>
                        Nous n'avons aucune obligation de surveiller tout
                        contenu téléchargé sur le Service. Néanmoins, si nous
                        jugeons qu'une telle action est nécessaire pour une
                        raison quelconque, nous pouvons (sans limiter nos autres
                        droits) retirer vos Données du Service. Nous n'avons
                        aucune responsabilité envers vous pour la suppression de
                        vos Données du Service.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">7.5</span>Sécurité
                        </p>
                        Nous mettons en œuvre des procédures sécuritaires pour
                        protéger vos Données contre les attaques de sécurité.
                        Toutefois, vous comprenez que l'utilisation du Service
                        implique nécessairement la transmission de vos Données
                        sur des réseaux qui ne nous appartiennent pas, ne sont
                        pas exploités ou contrôlés par nous, et nous ne sommes
                        pas responsables de la perte, l'altération,
                        l'interception ou le stockage de vos Données sur ces
                        réseaux. Nous ne pouvons pas garantir que nos procédures
                        sécuritaires seront exemptes d'erreurs, que la
                        transmission de vos Données sera toujours sécurisée ou
                        que des parties tierces non autorisés ne seront jamais
                        en mesure de contourner nos mesures de sécurité ou
                        celles de nos tiers fournisseurs de services.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      8 Problèmes techniques
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        En cas de problèmes techniques, vous devez faire tous
                        les efforts raisonnables pour enquêter et diagnostiquer
                        les problèmes avant de nous contacter. Si vous avez
                        toujours besoin d'aide, veuillez vérifier l'assistance
                        que nous fournissons comme indiqué sur l'Appli ou, à
                        défaut, veuillez nous envoyer un e-mail à
                        support@arcadex.co.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      9 Vos responsabilités et obligations
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">Vous devez, à vos frais:</li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>nous fournir toute
                        l'aide et la coopération raisonnables afin que nous
                        puissions fournir le Service de manière efficace et
                        opportune;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>s'assurer que toutes
                        les informations qui nous sont fournies sont tenues à
                        jour et que l'adresse électronique que vous nous
                        fournissez est valide et régulièrement vérifiée ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>apporter des
                        modifications sur votre appareil, telles que des mises à
                        niveau du système, qui peuvent être nécessaires pour
                        assurer la fourniture et le fonctionnement de tout
                        Service.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      10 Droits de la propriété intellectuelle
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">10.1</span>Propriété
                          intellectuelle (PI)
                        </p>
                        Toute propriété intellectuelle (y compris les droits
                        d'auteur) développée, adaptée, modifiée ou créée par
                        nous ou notre personnel (y compris en relation avec les
                        présentes Modalités, le Logiciel et le Service) nous
                        appartiendra à tout moment, ou restera dévolue.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">10.2</span>Conditions
                          d’utilisation
                        </p>
                        Vous ne devez pas, sans notre consentement préalable par
                        écrit:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>copier ou utiliser,
                        entièrement ou partiellement, l'un de nos droits de
                        propriété intellectuelle;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>reproduire,
                        retransmettre, distribuer, diffuser, vendre, publier,
                        diffuser ou faire circuler nos droits de propriété
                        intellectuelle à des parties tierces ; ou
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>enfreindre les droits
                        de propriété intellectuelle liés à l'Appli, au Logiciel
                        ou au Service, y compris (sans s'y limiter) modifier ou
                        altérer notre propriété intellectuelle ; faire en sorte
                        que notre propriété intellectuelle soit encadrée ou
                        intégrée dans une autre application ; ou créer des
                        travaux dérivés à partir de notre propriété
                        intellectuelle. La présente{" "}
                        <span className="text-black bold">clause 10</span>{" "}
                        survivra à la résiliation de votre Compte.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      11 Contenu
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.1</span>Contenu
                          d’utilisateur
                        </p>
                        Vous pouvez être autorisé à afficher, télécharger,
                        publier, soumettre ou transmettre des informations
                        pertinentes, du contenu et des photographies{" "}
                        <span className="text-black bold">
                          (“Contenu d’utilisateur”)
                        </span>{" "}
                        sur l’Appli. En mettant à notre disposition tout Contenu
                        d’utilisateur sur ou via l'Appli, vous nous accordez une
                        licence mondiale, irrévocable, perpétuelle, non
                        exclusive, non exclusive, transférable et libre de
                        droits pour utiliser le Contenu d’utilisateur, avec le
                        droit d'utiliser, visualiser, copier, adapter, modifier,
                        distribuer, licencier, vendre, transférer, communiquer,
                        afficher publiquement, exécuter publiquement,
                        transmettre, diffuser, diffuser ou autrement exploiter
                        ce Contenu d’utilisateur sur ou par l'Appli.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.2</span>Vous êtes
                          responsable de votre Contenu d’utilisateur
                        </p>
                        Vous convenez que vous êtes seul responsable de tout le
                        Contenu d’utilisateur que vous rendez disponible sur ou
                        via l'Appli. Vous déclarez et garantissez que:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>vous êtes le
                        propriétaire unique et exclusif de tout le Contenu
                        d'utilisateur ou vous avez tous les droits, licences,
                        consentements et autorisations nécessaires pour nous
                        accorder des droits sur de tel Contenu d'utilisateur
                        conformément aux présentes Modalités ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>ni le Contenu
                        d’utilisateur, ni l'affichage, le téléchargement, la
                        publication, la soumission ou la transmission du Contenu
                        d’utilisateur ou notre usage du Contenu d’utilisateur
                        sur, par le biais ou au moyen de l'Appli n'enfreindra,
                        ne détournera ou ne violera les droits de propriété
                        intellectuelle d'un tiers, ni les droits de publicité ou
                        de confidentialité, ou n'entraînera la violation des
                        lois ou réglementations applicables.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">11.3</span>Non responsable
                        </p>
                        Nous n'endossons, ni n'approuvons, ni ne sommes
                        responsables du Contenu d'utilisateur. Nous pouvons, à
                        tout moment et à notre seule discrétion, supprimer tout
                        Contenu Utilisateur.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      12 Indemnisation et responsabilité
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">12.1</span>Responsabilité
                        </p>
                        En dépit de toute disposition contraire, dans toute la
                        mesure permise par la loi:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>notre responsabilité
                        globale maximale découlant des présentes Modalités (y
                        compris le Service ou l'objet des présentes Modalités)
                        sera limitée au montant total des frais que vous nous
                        avez payés au cours du mois où la responsabilité est
                        intervenue ou à 1 $ si aucun de ces Frais n'a été payé
                        et ne doit dépasser ce montant ; et
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>nous ne serons
                        responsables envers vous d'aucune perte consécutive,
                        perte indirecte, perte de profit (y compris le profit
                        anticipé), perte de revenu, perte d'affaires, perte de
                        clientèle, perte d'opportunité, perte d'économies (y
                        compris les économies anticipées), perte de réputation,
                        perte d'utilisation et/ou perte ou corruption des
                        données,
                      </li>
                      <li className="mb-2">
                        que ce soit en vertu d'une loi, d'un contrat, de
                        l'équité, d'un délit (y compris la négligence), d'une
                        indemnité ou autrement.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">12.2</span>Exclusion
                        </p>
                        En dépit de toute disposition contraire, dans toute la
                        mesure permise par la loi, nous n'avons aucune
                        responsabilité, et vous nous déchargez de toute
                        responsabilité, découlant de ou en relation avec tout ce
                        qui suit:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>blessures, dommages ou
                        pertes à une personne ou à un bien;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>défaut ou retard dans
                        la fourniture du Service;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>indisponibilité, panne
                        ou interruption du Service ou de votre appareil ; ou
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>la violation des
                        présentes Modalités ou de toute loi,
                      </li>
                      <li className="mb-2">
                        lorsqu'elle est causée ou contribuée par:
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>un événement ou une
                        circonstance hors de notre contrôle raisonnable;
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>un défaut, une
                        défaillance, une erreur ou une omission au niveau de
                        votre environnement informatique ou dans vos Données ;
                        ou
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>un acte ou une omission
                        de votre part, de associés ou d'un tiers fournisseur de
                        services et, dans tous les cas, une erreur, une omission
                        ou un manque de pertinence (ou l'absence ou la réduction
                        de tout résultat, issue ou avantage prévu) par rapport
                        au Service.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      13 Droit australien sur la consommation
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.1</span>LAC
                        </p>
                        Certaines lois, y compris la Loi australienne sur la
                        consommation{" "}
                        <span className="text-black bold">(“LAC”)</span>, et
                        des lois et règlements similaires sur la protection des
                        consommateurs, peuvent vous conférer des droits, des
                        garanties, des gages et des recours relatifs à la
                        fourniture des biens livrables que nous vous fournissons
                        qui ne peuvent être exclus, limités ou modifiés{" "}
                        <span className="text-black bold">
                          ("Droits statutaires")
                        </span>
                        .
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.2</span>Défaillances
                        </p>
                        Nos services sont assortis de garanties qui ne peuvent
                        être exclues en vertu de la LAC. En cas de défaillance
                        majeure des Services, vous avez le droit d'annuler les
                        présentes Modalités avec de nous.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.3</span>Consommateurs
                        </p>
                        Si la LAC s'applique à vous en tant que consommateur,
                        rien dans les présentes Modalités n'exclut vos droits
                        légaux en tant que consommateur en vertu de la LAC. Vous
                        convenez que notre responsabilité à l'égard des produits
                        livrables fournis à une entité définie comme
                        consommatrice en vertu de la LAC est régie uniquement
                        par la LAC et par les présentes Modalités.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">13.4</span>Exclusions
                        </p>
                        Sous réserve de vos Droits statutaires, nous excluons
                        toute garantie expresse ou implicite et tout matériel,
                        travail et service (y compris les Services) qui vous
                        sont fournis sans garantie d'aucune sorte, expresse ou
                        implicite, que ce soit dans la loi, en droit ou sur
                        toute autre base. La présente{" "}
                        <span className="text-black bold">clause 13</span>{" "}
                        survivra à la résiliation ou à l'expiration du présent
                        Accord.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      14 Résiliation
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">14.1</span>La suppression de
                          l’Appli ne supprime pas votre Compte
                        </p>
                        Si vous supprimez l'Appli, votre Compte restera actif.
                        Vous ne pouvez résilier votre Compte qu'en cliquant sur
                        l'option ‘Supprimer votre profil’ dans la section
                        ‘Modifier votre profil’ de l'Appli, ou en envoyant un
                        courriel à notre personnel de soutien aux coordonnées
                        ci-dessous. Aucun remboursement ne sera effectué en cas
                        de résiliation conformément à la présente
                        <span className="text-black bold"> clause 14</span> .
                        Une fois votre profil supprimé, les informations que
                        vous aviez saisies dans l'Appli seront archivées et
                        resteront dans notre base de données.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">14.2</span>Résiliation
                        </p>
                        Nous pouvons, à tout moment et à notre seule discrétion,
                        suspendre ou résilier votre Compte pour quelque raison
                        que ce soit. Vous devez vous assurer que toutes vos
                        Données sur le Service sont sauvegardées afin que vous
                        ne perdiez pas vos Données si nous suspendons ou
                        résilions votre Compte. Normalement, nous vous
                        avertirons lorsque nous serons sur le point de prendre
                        de telles mesures et nous vous accorderons un temps
                        raisonnable pour remédier à toute violation des
                        présentes Modalités, mais si nous déterminons que vos
                        actions mettent en danger le fonctionnement du Service
                        ou d'autres utilisateurs, nous pouvons suspendre ou
                        résilier votre Compte immédiatement sans préavis. Vous
                        continuerez à être facturé pour le Service pendant toute
                        période de suspension au cours de laquelle vous
                        enfreignez les présentes Modalités.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      15 Avis concernant Apple
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(a)</span>Dans la mesure où vous
                        utilisez ou accédez à l'Appli sur un appareil iOS, vous
                        reconnaissez et acceptez les termes de cette clause.
                        Vous reconnaissez que les présentes Modalités sont
                        uniquement entre vous et nous, et non avec Apple, et
                        Apple n'est pas responsable de l'Appli et de tout
                        contenu disponible sur l'Appli.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(b)</span>Apple n'a aucune
                        obligation de vous fournir des services d’entretien et
                        d'assistance en ce qui concerne l'Appli.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(c)</span>Si notre application
                        mobile n'est pas conforme à toute garantie applicable,
                        vous pouvez en informer Apple et Apple vous remboursera
                        le prix d'achat de l'application mobile. Dans toute la
                        mesure permise par la loi applicable, Apple n'aura
                        aucune autre obligation de garantie, quelle qu'elle
                        soit, en ce qui concerne l'application mobile et toute
                        autre réclamation, perte, responsabilité, dommage, coût
                        ou dépense attribuable à tout non-respect d’une garantie
                        sera de notre responsabilité.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(d)</span>Apple n'est pas
                        responsable du traitement de toute réclamation de votre
                        part ou de la part d'un tiers concernant notre
                        application mobile ou votre utilisation de notre
                        application mobile, y compris entre autres:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>les demandes
                        d’indemnisation pour le produit;
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>toute allégation selon
                        laquelle notre application mobile n'est pas conforme à
                        toute exigence légale ou réglementaire applicable ; et
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(iii)</span>les réclamations
                        découlant de la protection des consommateurs ou d'une
                        législation similaire.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(e)</span>Apple n'est pas
                        responsable de l'enquête, de la défense, du règlement et
                        de l'acquittement de toute réclamation d'un tiers selon
                        laquelle notre application mobile viole les droits de
                        propriété intellectuelle de ce tiers.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(f)</span>Vous acceptez d’adhérer
                        aux conditions des parties tierces applicables lors de
                        l'utilisation de notre application mobile.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(g)</span>Apple et ses filiales
                        sont des tiers bénéficiaires des présentes Modalités, et
                        dès votre acceptation des présentes Modalités, Apple
                        aura le droit (et sera considérée comme ayant accepté le
                        droit) de faire valoir les présentes Modalités contre
                        vous en tant que tiers bénéficiaire des présentes
                        Modalités.
                      </li>
                      <li className="mb-2 ml-4">
                        <span className="mr-2">(h)</span>Vous déclarez et
                        garantissez par les présentes que:
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(i)</span>
                        vous n'êtes pas situé dans un pays qui fait l'objet d'un
                        embargo imposé par le gouvernement des États-Unis ou qui
                        a été désigné par le gouvernement des États-Unis comme
                        un pays &quot; soutenant le terrorisme &quot; ; et
                      </li>
                      <li className="mb-2 ml-5">
                        <span className="mr-2">(ii)</span>vous ne figurez sur
                        aucune liste du gouvernement américain des parties
                        interdites ou restreintes.
                      </li>
                    </ul>
                    <h6
                      className={
                        "bold mb-4  " +
                        (process.env.REACT_APP_PLATFORM === "nickx"
                          ? "text-orange"
                          : "")
                      }
                    >
                      16 Général
                    </h6>
                    <ul className="w-100 list-unstyled mb-4 text-muted">
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.1</span>Force Majeure:
                        </p>
                        Si une partie{" "}
                        <span className="text-black bold">
                          (“Partie affectée”)
                        </span>{" "}
                        est incapable d'exécuter l'une de ses obligations en
                        vertu des présentes Modalités en raison d'un événement
                        ou d'une circonstance échappant à son contrôle
                        raisonnable{" "}
                        <span className="text-black bold">
                          (“Force majeure”)
                        </span>{" "}
                        et qu'elle en avise l'autre partie rapidement par écrit,
                        les obligations de la Partie affectée seront suspendues
                        dans la mesure nécessaire. La partie affectée doit faire
                        des efforts raisonnables pour limiter l'impact de
                        l'événement sur sa performance et doit continuer à
                        s'acquitter pleinement de ses obligations dès que la
                        force majeure prend fin.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.2</span>Litiges
                        </p>
                        Aucune des parties ne peut engager une procédure
                        judiciaire relative à un différend découlant des
                        présentes Modalités ou s'y rapportant sans avoir
                        préalablement rencontré un représentant principal de
                        l'autre partie pour tenter (de bonne foi) de résoudre ce
                        différend (à moins que cette partie ne demande un
                        redressement interlocutoire urgent ou que le différend
                        ait trait au respect de cette disposition).
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.3</span>Avis
                        </p>
                        Tout avis donné en vertu des présentes Modalités doit
                        être exprimé par écrit et nous être adressé aux
                        coordonnées indiquées ci-dessous ou à vous aux
                        coordonnées fournies lors de l'ouverture de votre
                        compte. Toute notification peut être envoyée par
                        courrier postal ou électronique et sera réputée avoir
                        été signifiée à l'expiration d'un délai de 3 jours dans
                        le cas de la poste, ou au moment de la transmission dans
                        le cas d’un courrier électronique.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.4</span>Renonciation
                        </p>
                        Tout manquement ou retard d'une partie dans l'exercice
                        d'un pouvoir ou d'un droit (en tout ou en partie) en
                        relation avec les présentes Modalités ne constitue pas
                        une renonciation ni n'empêche l’une des parties
                        d'exercer ce pouvoir ou droit ou tout autre pouvoir ou
                        droit. Une renonciation doit être exprimée par écrit.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.5</span>Relation entre les
                          parties
                        </p>
                        Les présentes Modalités ne visent pas à créer une
                        relation de partenariat, de coentreprise ou d'agence
                        entre les parties.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.6</span>Séparation
                        </p>
                        Si une disposition des présentes Modalités est jugée
                        nulle, invalide, illégale ou inapplicable, cette
                        disposition doit être interprétée aussi étroitement que
                        nécessaire pour lui permettre d'être valide ou
                        exécutoire, faute de quoi, cette disposition (ou la
                        partie de cette disposition) sera séparée des présentes
                        Modalités sans affecter la validité ou le caractère
                        exécutoire du reste de cette disposition ou des autres
                        dispositions.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.7</span>Cession
                        </p>
                        Vous ne devez céder aucun droit ou obligation en vertu
                        des présentes Modalités, en tout ou en partie, sans
                        notre consentement préalable par écrit.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.8</span>Accord entier
                        </p>
                        Les présentes Modalités contiennent l'intégralité de
                        l'entente et de l'accord entre vous et nous en ce qui
                        concerne leur objet.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.9</span>Modifications
                        </p>
                        Nous pouvons, à tout moment et à notre discrétion,
                        modifier les présentes Modalités en publiant des
                        modalités modifiées sur l'Appli. Cette modification
                        entrera en vigueur au début du prochain cycle de
                        facturation de votre régime. Avant le début du cycle de
                        facturation de chaque régime, vous devez lire
                        attentivement les modalités en vigueur à ce moment-là
                        pour vous assurer de les comprendre et de les accepter.
                      </li>
                      <li className="mb-2">
                        <p className="text-black bold">
                          <span className="mr-2">16.10</span>Lois applicables
                        </p>
                        Les présentes Modalités sont régies par les lois de la
                        Nouvelle-Galles du Sud en Australie. Vous vous soumettez
                        irrévocablement et inconditionnellement à la juridiction
                        exclusive des tribunaux de Nouvelle-Galles du Sud et de
                        tout tribunal habilité à examiner les recours de ces
                        tribunaux et renoncez à tout droit de vous opposer aux
                        procédures engagées devant ces tribunaux. Le Service est
                        accessible en Australie et à l'étranger. Nous ne faisons
                        aucune déclaration que le Service respecte les lois (y
                        compris les lois sur la propriété intellectuelle) de
                        tout pays en dehors de l'Australie. Si vous accédez au
                        Service depuis l'extérieur de l'Australie, vous le
                        faites à vos propres risques et nous sommes responsables
                        du respect des lois en vigueur dans la juridiction où
                        vous accédez au Service.
                      </li>
                    </ul>

                    <p className="text-black mb-4 bold">
                      Pour toutes les questions, n’hésitez pas à nous contacter
                      à:
                    </p>
                    <p className="text-muted mb-4">
                      Emerge Gaming Limited ABN 31 004 766 376
                    </p>
                    <p className="text-muted mb-4">
                      Email:{" "}
                      <a
                        href="mailto:support@arcadex.co"
                        style={{
                          color: "#0024a7",
                          textDecoration: "underline"
                        }}
                      >
                        support@arcadex.co
                      </a>
                    </p>
                    <p className="text-muted mb-4">
                      Dernière mise à jour : 28 mai 2019
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default TermsContent;
