import React, { Component } from "react";
import { connect } from "react-redux";
import { createName } from "../../../actions/modals";
import {
  createUsernameForm,
  usernameAvailableForm
} from "../../../actions/forms";
import "./CreateNameModal.scss";
import ModalV2 from "../ModalV2";
import CheckGreenCircle from "../../../assets/images/svg/check-green-circle.svg";
import { APP_MESSAGES } from "../../../services/config/messages";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import { validateUsername } from "../../../utils/validators";
import { withTranslation } from "react-i18next";

class CreateNameModal extends Component {
  constructor(props) {
    super(props);
    this.showCreateName = this.showCreateName.bind(this);
    this.hideCreateName = this.hideCreateName.bind(this);
  }
  showCreateName() {
    this.props.createNameOpen();
  }
  hideCreateName() {
    this.props.createNameClose();
  }
  render() {
    let Error;
    let Success;
    let ErrorUser;
    Success = this.props.createUsernameForm.fields.username.success
      ? APP_MESSAGES[this.props.createUsernameForm.fields.username.successMsg](
          this.props.createUsernameForm.fields.username.successPayload,
          () => {
            this.props.createUsernameFormResetSuccess("username");
          }
        )
      : props => <>{""}</>;

    Error = this.props.createUsernameForm.fields.username.error
      ? APP_MESSAGES[this.props.createUsernameForm.fields.username.errorMsg](
          this.props.createUsernameForm.fields.username.errorPayload,
          () => {
            this.props.createUsernameFormResetError("username");
          }
        )
      : props => <>{""}</>;

    ErrorUser = this.props.usernameAvailableForm.fields.username.error
      ? APP_MESSAGES[this.props.usernameAvailableForm.fields.username.errorMsg](
          this.props.usernameAvailableForm.fields.username.errorPayload,
          () => {
            this.props.usernameAvailableFormResetError("username");
          }
        )
      : props => <>{""}</>;
    const { t } = this.props;
    return (
      <>
        <ModalV2
          showCloseBtn={false}
          modalCrossSvgClass={true}
          handleHide={this.hideCreateName}
          isOpen={this.props.isCreateNameOpen}
          disableOuterClick={true}
          withError={
            this.props.createUsernameForm.fields.username.error ||
            this.props.usernameAvailableForm.fields.username.error ||
            this.props.createUsernameForm.fields.username.success
          }
        >
          <div className="card-popup create-player">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="mb-3 bold">{t("createPlayerName")}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <ErrorUser />
                <Success />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 text-left">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (
                      !(
                        this.props.createUsernameForm.isSubmitting ||
                        this.props.usernameAvailableForm.isSubmitting
                      )
                    ) {
                      if (
                        !this.props.createUsernameForm.fields.username.value
                      ) {
                        this.props.createUsernameFormSetError(
                          "username",
                          "NOT_EMPTY"
                        );
                        return false;
                      }

                      if (
                        !this.props.usernameAvailableForm.fields.username.error
                      ) {
                        this.props.createUsernameFormSubmit({
                          username: this.props.createUsernameForm.fields
                            .username.value
                        });
                      }
                    }
                  }}
                >
                  <div className="form-group with-lable custom-top">
                    <AppInputComponent
                      withDebounce={true}
                      labelText={t("enterPlayerName")}
                      onKeyPress={e => {
                        if (e.which === 32) {
                          e.preventDefault();
                          return false;
                        }
                      }}
                      onChange={event => {
                        this.props.usernameAvailableFormReset(
                          usernameAvailableForm
                        );
                        if (!event.target.value) {
                          this.props.createUsernameFormReset(
                            createUsernameForm
                          );
                          this.props.usernameAvailableFormReset(
                            usernameAvailableForm
                          );
                          return false;
                        } else {
                          if (!validateUsername(event.target.value)) {
                            this.props.usernameAvailableFormSetError(
                              "username",
                              "USERNAME_LENGTH"
                            );
                            return false;
                          }
                          this.props.createUsernameFormReset(
                            createUsernameForm
                          );
                        }

                        this.props.createUsernameFormUpdateField(
                          "username",
                          event.target.value
                        );
                        this.props.usernameAvailableFormSubmit({
                          name: event.target.value
                        });
                      }}
                      inputAddon={() =>
                        this.props.usernameAvailableForm.fields.username
                          .success ? (
                          <img
                            src={CheckGreenCircle}
                            className="checked-playername"
                            alt=""
                          />
                        ) : (
                          ""
                        )
                      }
                    />
                  </div>
                  <small className="mt-3 bottom-margin float-left">
                   {t("makeSureitAwesome")}
                  </small>
                  <button
                    type="submit"
                    className={
                      "btn btn-pink dark btn-block mt-5" +
                      (this.props.createUsernameForm.isSubmitting
                        ? " disable"
                        : "")
                    }
                  >
                    {t("next")}
                  </button>
                </form>
              </div>
            </div>
            <div className="row privacy-policy">
              <div className="col-sm-12 text-center bottom-margin" />
            </div>
          </div>
        </ModalV2>
      </>
    );
  }
}
const mapDispatchToProps = {
  createNameOpen: createName.open,
  createNameClose: createName.close,
  createUsernameFormSubmit: createUsernameForm.submit,
  createUsernameFormReset: createUsernameForm.reset,
  createUsernameFormResetField: createUsernameForm.resetField,
  createUsernameFormUpdateField: createUsernameForm.updateField,
  createUsernameFormSetError: createUsernameForm.setError,
  createUsernameFormSetSuccess: createUsernameForm.setSuccess,
  createUsernameFormResetError: createUsernameForm.resetError,
  createUsernameFormResetSuccess: createUsernameForm.resetSuccess,

  usernameAvailableFormSubmit: usernameAvailableForm.submit,
  usernameAvailableFormUpdateField: usernameAvailableForm.updateField,
  usernameAvailableFormReset: usernameAvailableForm.reset,
  usernameAvailableFormResetField: usernameAvailableForm.resetField,
  usernameAvailableFormSetError: usernameAvailableForm.setError,
  usernameAvailableFormSetSuccess: usernameAvailableForm.setSuccess,
  usernameAvailableFormResetError: usernameAvailableForm.resetError,
  usernameAvailableFormResetSuccess: usernameAvailableForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    usernameAvailableForm: state.forms.usernameAvailableForm,
    createUsernameForm: state.forms.createUsernameForm,
    isCreateNameOpen: state.modals.isCreateNameOpen,
    userId: state.user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CreateNameModal));
