import { connect } from "react-redux";
import ChallengesLiveViewAllComponent from "../../components/Dashboard/DashboardLive/ChallengesLiveViewAllComponent";
import {  
  challengesLiveViewAll
} from "../../actions/challenges";

const mapDispatchToProps = {
  fetchLiveChallengesViewAllIfNeeded: challengesLiveViewAll.fetchIfNeeded,
  invalidateLiveChallengesViewAll: challengesLiveViewAll.invalidate,    
  invalidateAllLiveChallengesViewAll: challengesLiveViewAll.invalidateAll,    
};

const mapStateToProps = state => {  
  const { challenges } = state;  
  return {
    liveViewAll : challenges.liveViewAll,
    page: challenges.liveViewAll.page,  
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesLiveViewAllComponent);
