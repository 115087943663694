import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/avatar-demo.png";
// import ProfileImage from "../../../assets/images/svg/profile-pic-120.svg";
import { ValidURL } from "../../../utils/date";
import i18n from "../../../i18n";
import platformParams from "../../../utils/platformParams";

class FriendsFollowersCardViewAllComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }

  render() {
    const { allOnlineUserList } = this.props;
    const lang = i18n.language;
    let userId = this.props.id;
    return (
      <div className="card popular-host text-center followBox">
        <figure className="mr-2 mr-md-4">
          <a href={"/" + lang + "/profile/" + userId} target="_blank">
            <img
              className="profile-pic-120"
              src={
                ValidURL(this.props.avatarUrl)
                  ? this.props.avatarUrl
                  : ProfileImage
              }
              alt={this.props.name}
              onError={this.addDefaultSrc}
            />
          </a>
          {allOnlineUserList.includes(userId) ? (
            <figcaption className="online-120" />
          ) : null}
          <figcaption className="text-secondary mt-3 brand-label">
            <a
              href={"/" + lang + "/profile/" + userId}
              className={
                "text-" + platformParams['colorClassSufix']
              }
            >
              {this.props.name}
            </a>
          </figcaption>
        </figure>
      </div>
    );
  }
}

const defaultProps = {
  followerId: 1,
  avatarUrl: "Dunkin Donuts",
  name: ProfileImage
};

const mapStateToProps = state => {
  return {
    isLoggedIn: state.login.isLoggedIn,
    allOnlineUserList: state.socketusers.allOnlineUserList
  };
};

FriendsFollowersCardViewAllComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(FriendsFollowersCardViewAllComponent);
