import React, { Component } from "react";
import "./TermsConditionsComponent.scss";
import TermsContent from "./TermsContent";
import TermsContentFr from "./TermsContentFr";
import i18n from "../../i18n";
import platformParams from "../../utils/platformParams";

class TermsConditionsNewComponent extends Component {
  render() {
    const lang = i18n.language;    
    return (
      <>
        <section className="container-fluid banner-wrapper news-wrapper">
          <div className="row">
            <div
              className={
                "banner news help-support position-relative " +  platformParams['className']
              }
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  contents">
                  <div className="col-sm-12 col-xl-9 d-flex flex-column  justify-content-center align-items-start">
                    <h2 className="bold display-1 text-white mb-3 uppercase">
                      {lang == "en"
                        ? "TERMS & CONDITIONS"
                        : "MODALITÉS ET CONDITIONS"}
                    </h2>
                  </div>
                </div>
              </div>
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1345.6 495.3"
                enableBackground="new 0 0 1345.6 495.3"
                className="svgIE"
                xmlSpace="preserve"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="hexagonal-mask">
                    <path
                      d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <image
                  clipPath="url(#hexagonal-mask)"
                  height="100%"
                  width="100%"
                  xlinkHref={platformParams['termConditionsBanner']}
                  preserveAspectRatio="none"
                />
              </svg>
            </div>
          </div>
        </section>
        {lang == "en" ? (
          <TermsContent setclass="container-fluid news event-section help-support about-mtn-treasure" />
        ) : (
          <TermsContentFr setclass="container-fluid news event-section help-support about-mtn-treasure" />
        )}
      </>
    );
  }
}

export default TermsConditionsNewComponent;
