import React, { Component } from "react";
import DashboardFreeToPlayGamesCardComponent from "../../Dashboard/DashboardFreeToPlayGames/DashboardFreeToPlayGamesCardComponent";
import loadinggif from "../../../assets/images/loading.gif";
import { withTranslation } from "react-i18next";
import platformParams from "../../../utils/platformParams";

class FreeToPlayGamesViewAllComponent extends Component {

  componentDidMount() {    
    const { fetchFreeToPlayViewAllIfNeeded, invalidateAllFreeToPlayViewAll, page } = this.props;
    invalidateAllFreeToPlayViewAll();
    fetchFreeToPlayViewAllIfNeeded({ page: 1 });
  }
  componentDidUpdate() {
    document.body.classList.remove("modal-open");
  }

  render() {        
    let { page  , t} = this.props;
    return (      
      <section className="container-fluid free-game-wrapper">
        <div className="row">
          <div className="container">
            <div className="padder-main">
              <div className="row d-flex align-items-center mb-30 ">
                <div className="col-8 d-flex justify-content-start">
                  <h2 className="bold">{t("freeToPlayGame")}</h2>
                </div>
              </div>

              <div className="row card-wrapper d-flex justify-content-center justify-content-sm-start free-game-collumn">
                {this.props.freeToPlayViewAll.items.length > 0
                  ? this.props.freeToPlayViewAll.items.map((tournament, i) => (
                      <div className="col-auto mb-4" key={i}>                        
                        <DashboardFreeToPlayGamesCardComponent {...tournament} key={i} />
                      </div>
                    ))
                  : ""}                  
              </div>
              <div className="row mb-30 text-center">
                <div className="col-sm-12">
                  {this.props.freeToPlayViewAll.items.length > 0 ? (
                    !this.props.freeToPlayViewAll.isLastPage ? (
                      <button
                        onClick={() => {
                          this.props.invalidateFreeToPlayViewAll();
                          this.props.fetchFreeToPlayViewAllIfNeeded({                            
                            page: page
                          });
                        }}
                        className={"btn btn-"+ platformParams['colorClassSufix'] +" dark btn-xs"}
                      >
                       {t("loadMore")}
                      </button>
                    ) : this.props.freeToPlayViewAll.isFetching ? (
                      <img src={loadinggif} alt="" />
                    ) : (
                      ""
                    )
                  ) : this.props.freeToPlayViewAll.isFetching ? (
                    <img className="text-center" src={loadinggif} alt="" />
                  ) : (
                    <span>{t("noGameFound")}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(FreeToPlayGamesViewAllComponent);
