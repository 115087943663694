import { connect } from "react-redux";
import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./SubscribeComponent.scss";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { isNgCountry } from "../../utils/misc";

class JoinNowComponent extends Component {
    componentWillMount() {
        if (isNgCountry()) {
            const lang = i18n.language;
            let origin = window.location.origin;
            let routeName = "/ng-subscribe";
            let redirectUrl = encodeURIComponent(origin + "/" + lang + routeName);
            const url = `http://18.223.7.91/arcadeX?operatorId=MTNNigeria&redirectUrl=${redirectUrl}`;
            window.location.href = url;
        }
    }
    render() {
        if (isNgCountry()) {
            return (
                <section className="billing-background">
                </section>
            )
        }
    }
}

export default (withTranslation()(JoinNowComponent));
