import React, { Component } from "react";
// import banner1 from "../../../assets/images/adv/banner-1.jpeg";
// import telokomBanner1 from "../../../assets/images/adv/telkom-banner-1.png";

import "./DashboardBannerComponent.scss";
import SwiperContainer from "../../Slider/SwiperContainer";
import SlideBannerComponent from "./SlideBannerComponent";
import platformParams from "../../../utils/platformParams";

class DashboardBannerComponent extends Component {
  componentDidUpdate() {
    if (
      this.swiper &&
      this.swiper.swiper &&
      this.swiper.swiper.swiper &&
      !this.swiper.swiper.swiper.autoplay.running
    ) {
      this.swiper.swiper.swiper.autoplay.start();
    }
  }
  render() {
    const params = {
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      loop: true,
      fixDrag: true,
      slidesPerView: 1,
      spaceBetween: 30,
      touchRatio: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn"
      },
      centeredSlides: false,
      slideExtraClass: "tutorial",
      breakpoints: {
        768: {
          touchRatio: 1
        },
        320: {
          slidesPerView: 1,
          touchRatio: 1,
          centerInsufficientSlides: true,
          setWrapperSize: true,
          touchEventsTarget: "swiper-slide"
        }
      },
      on: {
        slideChange: this.onSlideChange
      }
    };

    const {
      featureChallengesList,
      t,
      featureChallengesIsFetching,
      history
    } = this.props;
    if (featureChallengesList && featureChallengesList.length > 0 && !featureChallengesIsFetching) {
      return (
        <>
          <section className="container-fluid banner-wrapper">
            <div className="row no-gutters no-space">
              <SwiperContainer
                {...params}
                ref={swiper => {
                  this.swiper = swiper;
                }}
                shouldSlideTo={false}
              >
                {featureChallengesList.map(function(challenge, index) {
                  return (
                    <SlideBannerComponent
                      history={history}
                      challengeData={challenge}
                      key={index + 1}
                      order={index + 1}
                      t={t}
                    />
                  );
                })}
              </SwiperContainer>
              <div className="swiper-pagination" />
            </div>
          </section>
        </>
      );
    } else {
      return (
        <section className="container-fluid banner-wrapper">
          <div className="row no-gutters no-space">
            <div className="col-sm-12">
              <div className="banner dashboard d-flex flex-column align-items-center">
                <img
                  src={
                    platformParams['dashboardDefaultBannerImage']
                  }
                  alt=""
                  className="dynamic-banner"
                />

                <div
                  className={
                    "book-yoour-seat w-100 mt-auto " + platformParams['className']
                  }
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-lg-7">
                        <div className="row">
                          <div className="col d-flex align-items-center justify-content-center">
                            <h4 className="text-white my-3">
                              {t("brandNewTournament")}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default DashboardBannerComponent;
