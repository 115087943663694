import React, { Component } from "react";
import "./NotificationList.scss";
import profilePic48 from "../../../../assets/images/avatar-demo.png";
import { ordinalSuffix } from "../../../../utils/misc";
import platformParams from "../../../../utils/platformParams";
import navClose from "../../../../assets/images/svg/nav-close.svg";
import { withTranslation } from "react-i18next";

import moment from "moment";
class NotificationListItem extends Component {
  constructor(props) {
    super(props);
    this.removeNotification = this.removeNotification.bind(this);
  }

  getActionButtonText() {
    const { t } = this.props;
    switch (this.props.event) {
      case "rewards distributed":
        if (this.props.payload.didWinReward) {
          return (
            <a
              href={this.getActionRoute()}
              className={
                "btn  rounded-xs text-capitalize btn-" + platformParams['baseButtonClassSufix2']
              }
            >
              {/* {this.props.payload.didWinReward?"Info sent":"Get prize!"} */}
              {t("getPrize")}
            </a>
          );
        } else {
          return null;
        }
      case "today challenge":
        return (
          <a
            href={this.getActionRoute()}
            className={
              "btn  rounded-xs text-capitalize  btn-" + platformParams['baseButtonClassSufix2']
            }
          >
            {t("playNow")}
          </a>
        );
      case "follow request":
        let userFollowingsList = this.props.userFollowingsList;

        let findObj = userFollowingsList
          ? userFollowingsList.find(
              o => o.followeeId === this.props.payload.followerId
            )
          : null;

        if (!findObj) {
          return (
            <button
              className={
                "btn  rounded-xs  align-items-center justify-content-center  btn-block  btn-outline-" + platformParams['baseButtonClassSufix2']
              }
              onClick={() => {
                this.props.followFormSubmit({
                  followeeId: this.props.payload.followerId
                });
              }}
            >
              {t("follow")}
            </button>
          );
        } else {
          return null;
        }
      case "invitation received":
        return (
          <a
            href={this.getActionRoute()}
            className={
              "btn  rounded-xs text-capitalize btn-" + platformParams['baseButtonClassSufix2']
            }
          >
            {t("playNow")}
          </a>
        );
      default:
        return null;
    }
  }

  getActionButton() {
    return <div className="col-3 ml-auto">{this.getActionButtonText()}</div>;
  }

  getActionRoute() {
    switch (this.props.event) {
      case "rewards distributed":
        if (this.props.payload.didWinReward) {
          // return `/challenge/${this.props.payload.competitionId}`;
          return (
            `/getprize/${this.props.id}` +
            `/${this.props.payload.competitionId}`
          );
        } else {
          return (
            `/getprize/${this.props.id}` +
            `/${this.props.payload.competitionId}`
          );
        }
      case "today challenge":
        if (this.props.payload.competitionId) {
          return `/challenge/${this.props.payload.competitionId}`;
        } else {
          return `/dashboard`;
        }
      case "follow request":
        return `/dashboard`;

      case "invitation received":
        if (this.props.payload.competitionId) {
          return `/challenge/${this.props.payload.competitionId}`;
        } else {
          return `/dashboard`;
        }
      default:
        return false;
    }
  }

  removeNotification(notificationId) {
    this.props.onNotificationDeactivated(notificationId);
  }

  addDefaultSrc(ev) {
    ev.target.src = profilePic48;
  }

  render() {
    const { allOnlineUserList } = this.props;
    let actionLink = false;
    if (this.props.actionButton) actionLink = this.getActionRoute();

    if (
      this.props.event === "rewards distributed" ||
      this.props.event === "raffle won" ||
      this.props.event === "follow request" ||
      this.props.event === "today challenge" ||
      this.props.event === "invitation received"
    ) {
      let imageUrl = "";
      if (
        this.props.event === "today challenge" ||
        this.props.event === "invitation received"
      ) {
        imageUrl = this.props.payload.organizerAvatar;
      }

      if (this.props.event === "follow request") {
        imageUrl = this.props.payload.requestorAvatar;
      }
      const { t } = this.props;
      return (
        <>
          <div className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1 position-relative">
            <div
              className="close_notification"
              onClick={e => {
                e.preventDefault();
                this.removeNotification(this.props.id);
              }}
            >
              <span className="a icon-close">
                <img src={navClose} alt="" />
              </span>
            </div>
            <div className="media col">
              {this.props.event === "follow request" ||
              this.props.event === "today challenge" ||
              this.props.event === "invitation received" ? (
                <figure>
                  <img
                    src={imageUrl ? imageUrl : profilePic48}
                    className="profile-pic-48"
                    alt=""
                    onError={this.addDefaultSrc}
                  />
                  {this.props.event === "follow request" ? (
                    allOnlineUserList.includes(
                      this.props.payload.followerId
                    ) ? (
                      <figcaption className="online-48" />
                    ) : null
                  ) : null}
                  {this.props.event === "invitation received" ? (
                    allOnlineUserList.includes(
                      this.props.payload.organizerId
                    ) ? (
                      <figcaption className="online-48" />
                    ) : null
                  ) : null}
                </figure>
              ) : null}
              <div className="media-body ml-3">
                {this.props.event === "rewards distributed" ? (
                  <h6>
                    {t("congratulationsPlaced")}{" "}
                    {!this.props.payload.placement
                      ? "1st"
                      : ordinalSuffix(this.props.payload.placement)}{" "}
                    {t("inThe")} {this.props.payload.competitionName}
                  </h6>
                ) : (
                  <h6 className="">{this.props.title}</h6>
                )}

                {this.props.event === "rewards distributed" ? null : (
                  <p className="">{this.props.content}</p>
                )}

                <p className="mb-0 fs-12 time-indication">
                  {" "}
                  {moment(this.props.createdAt).fromNow()}
                </p>
              </div>
            </div>

            {this.props.event === "rewards distributed"
              ? this.getActionButton()
              : actionLink
              ? this.getActionButton()
              : null}
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(NotificationListItem);
