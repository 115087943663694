import { connect } from "react-redux";
import ChallengesQuickPlayViewAllComponent from "../../components/Dashboard/DashboardQuickPlay/ChallengesQuickPlayViewAllComponent";
import { challengesQuickPlayViewAll } from "../../actions/challenges";

const mapDispatchToProps = {
  fetchQuickPlayChallengesViewAllIfNeeded:
    challengesQuickPlayViewAll.fetchIfNeeded,
  invalidateQuickPlayChallengesViewAll: challengesQuickPlayViewAll.invalidate,
  invalidateAllQuickPlayChallengesViewAll:
    challengesQuickPlayViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { challenges, user } = state;
  return {
    quickPlayViewAll: challenges.quickPlayViewAll,
    page: challenges.quickPlayViewAll.page,
    userId: user.item.id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengesQuickPlayViewAllComponent);
