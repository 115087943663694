import { connect } from "react-redux";
import RafflesViewAllComponent from "../../components/Raffles/RafflesViewAllComponent";
import {  
  rafflesViewAll
} from "../../actions/raffles";

const mapDispatchToProps = {
  fetchRafflesViewAllIfNeeded: rafflesViewAll.fetchIfNeeded,
  invalidateRafflesViewAll: rafflesViewAll.invalidate,    
  invalidateAllRafflesViewAll: rafflesViewAll.invalidateAll,    
};

const mapStateToProps = state => {  
  const { raffles, user } = state;  
  return {
    userId : user.item.id,
    rafflesViewAll : raffles.rafflesViewAll,
    page: raffles.rafflesViewAll.page,  
    tokensInWallet: user.item.points
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RafflesViewAllComponent);
