import { combineReducers } from "redux";
import { ListReducerCreator, FormReducerCreator } from "./creators";

import { MobileOperatorList } from "../redux-config/listActionMap";
import {
  SubscribeNowForm,
  BillingSubscriptionForm,
  BillingRegistrationForm,
  BillingLoginForm,
  BillingForgotPasswordForm,
  BillingHoldForm,
  SubscribeWrapmeForm,
  BillingNGHoldForm
} from "../redux-config/formActionMap";

const vas = combineReducers({
  [MobileOperatorList.stateSubKey]: ListReducerCreator(MobileOperatorList),
  [SubscribeNowForm.stateSubKey]: FormReducerCreator(SubscribeNowForm),
  [BillingSubscriptionForm.stateSubKey]: FormReducerCreator(
    BillingSubscriptionForm
  ),
  [BillingForgotPasswordForm.stateSubKey]: FormReducerCreator(
    BillingForgotPasswordForm
  ),
  [BillingHoldForm.stateSubKey]: FormReducerCreator(BillingHoldForm),
  [SubscribeWrapmeForm.stateSubKey]: FormReducerCreator(SubscribeWrapmeForm),
  [BillingNGHoldForm.stateSubKey]: FormReducerCreator(BillingNGHoldForm)
});

export default vas;
