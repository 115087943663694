import { connect } from "react-redux";
import HostChallengesQuickPlayViewAllComponent from "../../components/Brand/ViewAll/HostChallengesQuickPlayViewAllComponent";
import {
  brandDetails,
  hostChallengesQuickPlayViewAll
} from "../../actions/brands";

const mapDispatchToProps = {
  fetchBrandDetailsIfNeeded: brandDetails.fetchIfNeeded,
  invalidateBrandDetailsDetails: brandDetails.invalidate,

  fetchHostChallengesQuickPlayViewAllIfNeeded:
    hostChallengesQuickPlayViewAll.fetchIfNeeded,
  invalidateHostChallengesQuickPlayViewAll:
    hostChallengesQuickPlayViewAll.invalidate,
  invalidateAllHostChallengesQuickPlayViewAll:
    hostChallengesQuickPlayViewAll.invalidateAll
};

const mapStateToProps = state => {
  const { brands } = state;
  return {
    userId: state.user.item ? state.user.item.id : null,
    brandDetails: brands.brandDetails || null,
    hostChallengesQuickPlayList: brands.quickPlayViewAll || null,
    page: brands.quickPlayViewAll.page
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostChallengesQuickPlayViewAllComponent);
