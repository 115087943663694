import React, { Component } from "react";

class NotFoundContainer extends Component {
  render() {
    return (
      <div className="not-found-container text-center">
        <h1 style={{marginTop:'250px',height:'350px'}}>Page Not Found</h1>
      </div>
    );
  }
}

export default NotFoundContainer;
