import homeImageNick from "../assets/images/home-banner-nick.png";
import homeImageMTN from "../assets/images/home-banner-mtn.png";
import homeImage from "../assets/images/home-banner.png";
import subscribeDevice3xImageNickx from "../assets/images/subscribe-device@3x-nick.png";
import subscribeDevice3xImageMTN from "../assets/images/subscribe-device@3x-mtn.png";
import subscribeDevice3xImage from "../assets/images/subscribe-device@3x.png";
import tagImageNick from "../assets/images/tag@3x-ticket-nick.png";
import tagImageMTN from "../assets/images/tag@3x-ticket-mtn.png";
import tagImage from "../assets/images/tag@3x.png";
import Logo from "../assets/images/svg/logo.svg";
import logoNickX from "../assets/images/logo-nick.png";
import logoMTNX from "../assets/images/logo-mtn.png";
import DashboardOn from "../assets/images/svg/dashboard-on.svg";
import DashboardOnNick from "../assets/images/svg/dashboard-on-nick.svg";
import TicketOn from "../assets/images/svg/ticket-on.svg";
import TicketOnNick from "../assets/images/svg/ticket-on-nick.svg";
import wallpaperNickx from "../assets/images/new-banner-nick.png";
import wallpaperMTN from "../assets/images/new-banner-mtn.png";
import wallpaper from "../assets/images/wallpaper-v1.jpg";
import FeaturedImage1 from "../assets/images/featuredLogo4.png";
import FeaturedImage2 from "../assets/images/featuredLogo5.png";
import FeaturedImage3 from "../assets/images/featuredLogo6.png";
import FeaturedImageMTN1 from "../assets/images/featuredLogoMNT1.png";
import FeaturedImageMTN2 from "../assets/images/featuredLogoMNT2.png";
import FeaturedImageMTN3 from "../assets/images/featuredLogoMNT3.png";
import facebookIconNickx from "../assets/images/svg/facebook-container-nick.svg";
import twitterIconNickx from "../assets/images/svg/twitter-container-nick.svg";
import instagramIconNickx from "../assets/images/svg/instagram-container-nick.svg";
import facebookIconMTN from "../assets/images/svg/fb-container-black.svg";
import twitterIconMTN from "../assets/images/svg/twitter-container-black.svg";
import instagramIconMTN from "../assets/images/svg/instagram-container-black.svg";
import facebookIcon from "../assets/images/svg/facebook-container.svg";
import twitterIcon from "../assets/images/svg/twitter-container.svg";
import instagramIcon from "../assets/images/svg/instagram-container.svg";
import navRightArrow from "../assets/images/svg/nav-arrow-right-blue.svg";
import navRightArrowMTN from "../assets/images/svg/nav-arrow-right.svg";
import totallyTurtles from "../assets/images/totally-turtles.png";
import candyBubble from "../assets/images/candy-bubble.png";
import highwayRiderExtreme from "../assets/images/Highway-Rider-Extreme.png";
import monsterIslandAdventure from "../assets/images/monster-island-adventures.png";
import darkdot from "../assets/images/darkdot.png";
import thor from "../assets/images/thor.png";
import VisualTimerImgNick from "../assets/images/svg/visual-timer-nick.svg";
import VisualTimerImgMTN from "../assets/images/svg/visual-timer-mtn.svg";
import VisualTimerImg from "../assets/images/svg/visual-timer.svg";
import banner1 from "../assets/images/adv/banner-1.jpeg";
import banner1Nickx from "../assets/images/adv/challenge-banner-nick.png";
import banner1MTN from "../assets/images/adv/challenge-banner-mtn.jpg";
import Nicklodeonbanner from "../assets/images/adv/dashboard-banner-nick.png";
import dashboardBannerMTN from "../assets/images/adv/dashboard-banner-mtn.png";
import aboutBannerMobile from "../assets/images/about-banner@mobile.png";
import aboutBannerMobileNickx from "../assets/images/about-banner@mobile-niks.png";
import aboutBannerMobileMTN from "../assets/images/about-banner@mobile-mtn.png";
import aboutWeb from "../assets/images/about-page-web.png";
import aboutWebNickx from "../assets/images/about-page-web-nick.png";
import aboutWebMTN from "../assets/images/about-page-web-mtn.png";
import aboutMobile from "../assets/images/about-page-mobile.png";
import aboutMobileNickx from "../assets/images/about-page-mobile-nick.png";
import aboutMobileMTN from "../assets/images/about-page-mobile-mtn.png";
import gameController3x from "../assets/images/game-controller@3x.png";
import trophy3x from "../assets/images/trophy@3x.png";
import prize3x from "../assets/images/prize@3x.png";
import gameControllerSvg from "../assets/images/svg/game-controller.svg";
import trophySvg from "../assets/images/svg/trophy.svg";
import prizeSvg from "../assets/images/svg/prize.svg";
import gameController3xMTN from "../assets/images/game-controller-mtn@3x.png";
import trophy3xMTN from "../assets/images/trophy-mtn@3x.png";
import prize3xMTN from "../assets/images/prize-mtn@3x.png";
import gameControllerSvgMTN from "../assets/images/svg/game-controller-mtn.svg";
import trophySvgMTN from "../assets/images/svg/trophy-mtn.svg";
import prizeSvgMTN from "../assets/images/svg/prize-mtn.svg";
import helpSupportBannerImgNickx from "../assets/images/help-support-banner-nick.png";
import helpSupportBannerImg from "../assets/images/help-support-banner.jpg";
import helpSupportBannerMTN from "../assets/images/help-support-banner-mtn.png";
import privacyPolicyBannerMTN from "../assets/images/privacy-policy-banner-mtn.png";
import termConditionsBannerMTN from "../assets/images/terms-condition-banner-mtn.png";
import contactUsBannerMTN from "../assets/images/contact-banner-mtn.png";
import prizeIconFourth from "../assets/images/svg/x-4-mtn.svg";
import prizeIconFourthMTN from "../assets/images/svg/x-4-mtn.svg";
import challengeRewardDefault from "../assets/images/poo_default.png";
import challengeRewardDefaultMTN from "../assets/images/poo_default-mtn.png";

const platformParams = [];
platformParams['arcadex'] = {
   'title': 'Arcade',
    'titleX': 'Arcade X',
    'envTitle': 'ArcadeX',
    'baseColor': '',
    'baseButtonClassSufix': 'pink',
    'baseButtonClassSufix2': 'pink',
    'shadeButtonClassSufix': 'pink',
    'className': 'arcadex',
    'colorClassSufix': 'secondary',
    'textColorClassSufix': 'secondary',
    'linkClassSufix': 'danger',
    'favicon': 'favicon.ico',
    'bgHomeImageJoinNow': homeImage,
    'homeTagImage': tagImage,
    'subscribeDeviceImage': subscribeDevice3xImage,
    'logoImage': Logo,
    'dashboardOnImage': DashboardOn,
    'ticketOnImage': TicketOn,
    'newsBannerImage': wallpaper,
    'featuredImage1': FeaturedImage1,
    'featuredImage2': FeaturedImage2,
    'featuredImage3': FeaturedImage3,
    'gameCardImageHome1': candyBubble,
    'gameCardImageHome2': highwayRiderExtreme,
    'facebook': facebookIcon,
    'twitter': twitterIcon,
    'instagram': instagramIcon,
    'navRightArrow': navRightArrow,
    'visualTimer': VisualTimerImg,
    'challengeDefaultBannerImage': banner1,
    'dashboardDefaultBannerImage': banner1,
    'helpSupportBanner': helpSupportBannerImg,
    'termConditionsBanner': helpSupportBannerImgNickx,
    'privacyPolicyBanner': helpSupportBannerImgNickx,
    'contactUsBanner': helpSupportBannerImgNickx,
    'aboutBannerMobile': aboutBannerMobile,
    'aboutWeb': aboutWeb,
    'aboutMobile': aboutMobile,
    'privacyTableBgColor': 'bg-secondary',
    'privacyTableTxtColor': '',
    'gameController3x': gameController3x,
    'trophy3x': trophy3x,
    'prize3x': prize3x,
    'gameControllerSvg': gameControllerSvg,
    'trophySvg': trophySvg,
    'prizeSvg': prizeSvg,
    'prizeIconFourth': prizeIconFourth,
    'challengeRewardDefault': challengeRewardDefault,
};
platformParams['mtn'] = {
    'title': 'MTN Arcade',
    'titleX': 'MTN Arcade X',
    'envTitle': 'MTN ArcadeX',
    'baseColor': 'mtnBlack',
    'baseButtonClassSufix': 'orange',
    'baseButtonClassSufix2': 'mtnBlack',
    'shadeButtonClassSufix': 'shade-orange',
    'className': 'mtn',
    'colorClassSufix': 'mtnBlack',
    'textColorClassSufix': 'mtn-light-black',
    'linkClassSufix': 'mtnBlack',
    'favicon': 'favicon-mtn.ico',
    'bgHomeImageJoinNow': homeImageMTN,
    'homeTagImage': tagImageMTN,
    'subscribeDeviceImage': subscribeDevice3xImageMTN,
    'logoImage': logoMTNX,
    'dashboardOnImage': DashboardOnNick,
    'ticketOnImage': TicketOnNick,
    'newsBannerImage': wallpaperMTN,
    'featuredImage1': FeaturedImageMTN1,
    'featuredImage2': FeaturedImageMTN2,
    'featuredImage3': FeaturedImageMTN3,
    'gameCardImageHome1': darkdot,
    'gameCardImageHome2': thor,
    'facebook': facebookIconMTN,
    'twitter': twitterIconMTN,
    'instagram': instagramIconMTN,
    'navRightArrow': navRightArrowMTN,
    'visualTimer': VisualTimerImgMTN,
    'challengeDefaultBannerImage': banner1MTN,
    'dashboardDefaultBannerImage': dashboardBannerMTN,
    'helpSupportBanner': helpSupportBannerMTN,
    'termConditionsBanner': termConditionsBannerMTN,
    'privacyPolicyBanner': privacyPolicyBannerMTN,
    'aboutBannerMobile': aboutBannerMobileMTN,
    'contactUsBanner': contactUsBannerMTN,
    'aboutWeb': aboutWebMTN,
    'aboutMobile': aboutMobileMTN,
    'privacyTableBgColor': 'bg-mtn-orange',
    'privacyTableTxtColor': 'bg-white',
    'gameController3x': gameController3xMTN,
    'trophy3x': trophy3xMTN,
    'prize3x': prize3xMTN,
    'gameControllerSvg': gameControllerSvgMTN,
    'trophySvg': trophySvgMTN,
    'prizeSvg': prizeSvgMTN,
    'prizeIconFourth': prizeIconFourthMTN,
    'challengeRewardDefault': challengeRewardDefaultMTN,
};
platformParams['nickx'] = {
    'title': 'Nick',
    'titleX': 'Nick X',
    'envTitle': 'NickX',
    'baseColor': 'orange',
    'baseButtonClassSufix': 'orange',
    'baseButtonClassSufix2': 'pink',
    'shadeButtonClassSufix': 'pink',
    'className': 'nickx',
    'colorClassSufix': 'orange',
    'textColorClassSufix': 'orange',
    'linkClassSufix': 'orange',
    'favicon': 'favicon-nick.ico',
    'bgHomeImageJoinNow': homeImageNick,
    'homeTagImage': tagImageNick,
    'subscribeDeviceImage': subscribeDevice3xImageNickx,
    'logoImage': logoNickX,
    'dashboardOnImage': DashboardOnNick,
    'ticketOnImage': TicketOnNick,
    'newsBannerImage': wallpaperNickx,
    'featuredImage1': FeaturedImage1,
    'featuredImage2': FeaturedImage2,
    'featuredImage3': FeaturedImage3,
    'gameCardImageHome1': monsterIslandAdventure,
    'gameCardImageHome2': totallyTurtles,
    'facebook': facebookIconNickx,
    'twitter': twitterIconNickx,
    'instagram': instagramIconNickx,
    'navRightArrow': navRightArrow,
    'visualTimer': VisualTimerImgNick,
    'challengeDefaultBannerImage': banner1Nickx,
    'dashboardDefaultBannerImage': Nicklodeonbanner,
    'helpSupportBanner': helpSupportBannerImg,
    'termConditionsBanner': helpSupportBannerImgNickx,
    'privacyPolicyBanner': helpSupportBannerImgNickx,
    'contactUsBanner': helpSupportBannerImgNickx,
    'aboutBannerMobile': aboutBannerMobileNickx,
    'aboutWeb': aboutWebNickx,
    'aboutMobile': aboutMobileNickx,
    'privacyTableBgColor': 'background-orange',
    'privacyTableTxtColor': 'bg-white',
    'gameController3x': gameController3x,
    'trophy3x': trophy3x,
    'prize3x': prize3x,
    'gameControllerSvg': gameControllerSvg,
    'trophySvg': trophySvg,
    'prizeSvg': prizeSvg,
    'prizeIconFourth': prizeIconFourth,
    'challengeRewardDefault': challengeRewardDefault,
};
export default platformParams[process.env.REACT_APP_PLATFORM] ? platformParams[process.env.REACT_APP_PLATFORM] : platformParams['arcadex'];