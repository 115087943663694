import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./NewsComponent.scss";
import Select2Component from "../Select2/Select2Component";
import DashboardNews from "../Dashboard/DashboardNews/DashboardNewsComponents";
import { validateEmail } from "../../utils/validators";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import { withTranslation, Trans } from "react-i18next";
import platformParams from "../../utils/platformParams";
import i18n from "../../i18n";

class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.handleNewsCategory = this.handleNewsCategory.bind(this);
    this.state = { categoryName: "All" };
  }
  componentDidMount() {
    const {
      fetchnewsListIfNeeded,
      invalidatenewsList,
      fetchNewsListByCategoriesIfNeeded,
      invalidateNewsListByCategories
    } = this.props;
    invalidatenewsList();
    fetchnewsListIfNeeded({
      page: ""
    });
    invalidateNewsListByCategories();
    fetchNewsListByCategoriesIfNeeded({
      page: ""
    });
  }

  handleNewsCategory(e) {
    this.setState({ categoryName: e });
  }
  render() {
    const lang = i18n.language;    
    const { newsList, newsListByCategories, t } = this.props;
    const category = {};
    const categoryAll = [{ id: 0, value: "All" }];
    const categoryDropDown = newsListByCategories.map((data, index) => {
      return {
        ...category,
        id: data.id,
        value: data.description
      };
    });
    const categoryOptionList = [...categoryAll, ...categoryDropDown];
    const selectOptions = categoryOptionList;
    return (
      <>
        <section className="container-fluid banner-wrapper news-wrapper">
          <div className="row">
            <div
              className={
                "banner news help-support position-relative " +  platformParams['className']
              }
            >
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center  contents">
                  <div className="col-sm-12 col-xl-9 d-flex flex-column  justify-content-center align-items-start">
                    <h2 className="bold display-1 text-white mb-3 uppercase">
                      {lang == "en"
                        ? "NEWS AND EVENTS"
                        : "NOUVELLES ET EVÈNEMENTS"}
                    </h2>
                  </div>
                </div>
              </div>
              <svg
                version="1.1"
                x="0px"
                y="0px"
                width="100%"
                height="100%"
                viewBox="0 0 1345.6 495.3"
                enableBackground="new 0 0 1345.6 495.3"
                className="svgIE"
                xmlSpace="preserve"
                preserveAspectRatio="none"
              >
                <defs>
                  <clipPath id="hexagonal-mask">
                    <path
                      d="M39.9,0L0.3,227c0,0-5.3,31.3,31,37.3s1314.3,231,1314.3,231V0H39.9z"
                      fill="none"
                    />
                  </clipPath>
                </defs>
                <image
                  clipPath="url(#hexagonal-mask)"
                  height="100%"
                  width="100%"
                  xlinkHref={platformParams['newsBannerImage']}
                  preserveAspectRatio="none"
                />
              </svg>
            </div>
          </div>
        </section>       
        {/* <section className="[ container-fluid ] news  event-section">
          <div className="padder-main"> */}

        <section className="[ container-fluid ] news  event-section">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold display-2">{t("newsAndEvent")}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.categoryName === "All" ? (
          newsList && newsList.length > 0 ? (
            newsListByCategories.map((data, index) =>
              index === 0 ? (
                newsList && newsList.length > 0 ? (
                  <section
                    className="[ container-fluid ] news  event-section"
                    key={index}
                  >
                    <div className="padder-main">
                      <div className="row">
                        <div className="container">
                          {/* <div className="row d-flex align-items-center mb-30 ">
                            <div className="col-8 d-flex justify-content-start">
                              <h2 className="bold display-2">
                                News and Events
                              </h2>
                            </div>
                          </div> */}
                          <div className="row mb-30 ">
                            <div className="col-sm-12 z-index-1">
                              <form>
                                <div className="form-row align-items-center">
                                  <p className="bold">{t("filter")}</p>
                                  <div className="form-group without-lable  ml-3">
                                    <div className="input-group-wrapper">
                                      <div className="form-group with-lable mt-4">
                                        <div className="input-group-wrapper z-index-1">
                                          <Select2Component
                                            selectOptions={selectOptions}
                                            onChange={this.handleNewsCategory}
                                            selectedValue={
                                              this.state.categoryName
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>

                          <div className="row d-flex align-items-center mb-30 ">
                            <div className="col-8 d-flex justify-content-start">
                              <h2 className="bold">{data.description}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <DashboardNews
                          list={newsList}
                          newsCategory={data.description}
                          key={index}
                        />
                      </div>
                    </div>
                  </section>
                ) : (
                  <section className="[ container-fluid ] news  event-section">
                    <div className="padder-main">
                      <div className="col">
                        <h6 className="text-center">{t("noNewsFound")}</h6>
                      </div>
                    </div>
                  </section>
                )
              ) : (
                ""
              )
            )
          ) : (
            ""
          )
        ) : newsList && newsList.length > 0 ? (
          <section className="[ container-fluid ] news  event-section">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  {/* <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold display-2">News and Events</h2>
                    </div>
                  </div> */}
                  <div className="row mb-30 ">
                    <div className="col-sm-12 z-index-1">
                      <form>
                        <div className="form-row align-items-center">
                          <p className="bold">{t("filter")}</p>
                          <div className="form-group without-lable  ml-3">
                            <div className="input-group-wrapper">
                              <div className="form-group with-lable mt-4">
                                <div className="input-group-wrapper z-index-1">
                                  <Select2Component
                                    selectOptions={selectOptions}
                                    onChange={this.handleNewsCategory}
                                    selectedValue={this.state.categoryName}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">{this.state.categoryName}</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ">
                <DashboardNews
                  list={newsList}
                  newsCategory={this.state.categoryName}
                />
              </div>
            </div>
          </section>
        ) : (
          <section className="[ container-fluid ] news  event-section">
            <div className="padder-main">
              <div className="col">
                <h6 className="text-center">{t("noNewsFound")}</h6>
              </div>
            </div>
          </section>
        )}
        {/* </div> */}
        {/* </div>
        </section> */}

        {this.state.categoryName === "All"
          ? newsList && newsList.length > 0
            ? newsListByCategories.map((data, index) =>
                index !== 0 ? (
                  <section
                    className="[ container-fluid ] quick-play section-border-top"
                    key={index}
                  >
                    <div className="padder-main">
                      <div className="row">
                        <div className="container">
                          <div className="row d-flex align-items-center mb-30 ">
                            <div className="col-8 d-flex justify-content-start">
                              <h2 className="bold">{data.description}</h2>
                            </div>
                            {/* <div className="col text-right d-flex justify-content-end">
                          <a
                            href="#"
                            className="view-all d-flex align-items-center"
                          >
                            View All{" "}
                            <span>
                              <img src={navArrowRightBlue} alt="" />
                            </span>
                          </a>
                        </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="row livetournament">
                        {newsList && newsList.length > 0 ? (
                          <DashboardNews
                            list={newsList}
                            newsCategory={data.description}
                          />
                        ) : (
                          <div className="col">
                            <h6 className="text-center">{t("noNewsFound")}</h6>
                          </div>
                        )}
                      </div>
                    </div>
                  </section>
                ) : (
                  ""
                )
              )
            : ""
          : ""}
        <section
          className={
            "container-fluid  news-letter " +
            ( "bg-" +platformParams['colorClassSufix'])
          }
        >
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 subscrible-newsletter">
                  <h1>{t("subscribeNewsletter")}</h1>
                  <h5 className="my-4">
                    <Trans i18nKey="stayAtTop">
                      Stay at the top of your game with the latest updates,{" "}
                      <br /> live streaming and more.{" "}
                    </Trans>
                  </h5>
                  {this.props.subscribeForm.fields.email.error ? (
                    <span className="alert alert-danger small">
                      {this.props.subscribeForm.fields.email.errorMsg}
                    </span>
                  ) : (
                    ""
                  )}
                  {this.props.subscribeForm.fields.email.success ? (
                    <span className="alert alert-success small">
                      {this.props.subscribeForm.fields.email.successMsg}
                    </span>
                  ) : (
                    ""
                  )}
                  <form
                    className="form-inline mt-5 align-items-end"
                    onSubmit={e => {
                      e.preventDefault();
                      if (!this.props.subscribeForm.fields.email.value) {
                        this.props.subscribeFormSetError(
                          "email",
                          "Please enter email"
                        );
                        return false;
                      }
                      if (
                        !validateEmail(
                          this.props.subscribeForm.fields.email.value
                        )
                      ) {
                        this.props.subscribeFormSetError(
                          "email",
                          "Please enter valid email"
                        );
                        return false;
                      }
                      this.props.subscribeFormSubmit({
                        email: this.props.subscribeForm.fields.email.value
                      });
                    }}
                  >
                    <AppInputComponent
                      inputClass="form-control effect-1"
                      divClass="form-group mb-2 without-lable"
                      labelText=""
                      placeholder="Enter Your Email Address"
                      onChange={event => {
                        this.props.subscribeFormUpdateField(
                          "email",
                          event.target.value
                        );
                      }}
                      inputAddon={() => (
                        <small className="help-text mt-sm-0 mt-3">
                          {t("neverSpam")}
                        </small>
                      )}
                      value={this.props.subscribeForm.fields.email.value}
                    />

                    <button
                      type="submit"
                      className={"btn btn-" +  platformParams['baseButtonClassSufix'] + " btn-sm btn-sm-block subscribe-alignment-adjust light mb-2"}
                    >
                      {t("subscribe")}
                    </button>
                  </form>
                </div>
                <div className="col-lg-5 d-flex align-items-center justify-content-center align-items-lg-end justify-content-lg-end subscribe-img">
                  <img src={platformParams['subscribeDeviceImage']} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default withTranslation()(NewsComponent);
