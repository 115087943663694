import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getArcadeCategoriesViewAll = (postParams) => {  
  return axios
    .request({
      url:
      API_SERVER_URL + config.arcadeCategories,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getArcadeGamesByCategoryViewAll = (postParams) => {  
  return axios
    .request({
      url:
      API_SERVER_URL + config.arcadeGamesByCategory,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getArcadeGameDetail = (postParams) => {  
  return axios
    .request({
      url:
      API_SERVER_URL + config.arcadeGameDetail.replace("##id##", postParams.casualGameId),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};