import React, { Component } from "react";
import { connect } from "react-redux";
import {
  profileSidebar,
  friendListSidebar
} from "../../../../actions/sidebars";
import arrowRightSvg from "../../../../assets/images/svg/nav-arrow-right.svg";
import avatar3x from "../../../../assets/images/avatar-demo.png";

import "./FriendList.scss";

import NiceDropdownComponent from "./../../../../components/NiceDropdown/NiceDropdownComponent";
import "./../../../../components/NiceDropdown/NiceDropdownComponent.scss";
import searchImg from "../../../../assets/images/svg/search.svg";

//get friends
import { friendsList } from "../../../../actions/friends";
import { followForm } from "../../../../actions/forms";

//search
import { searchProfilesRequest } from "../../../../actions/socketusers";
import { refactorTitle } from "../../../../utils/misc";

//get followers
import { followings } from "../../../../actions/profiles";
import { withTranslation } from "react-i18next";

class FriendList extends Component {
  constructor(props) {
    super(props);

    this.loadFollowers = this.loadFollowers.bind(this);
    this.loadFriends = this.loadFriends.bind(this);
    this.showFriendListSidebar = this.showFriendListSidebar.bind(this);
    this.hideFriendListSidebar = this.hideFriendListSidebar.bind(this);

    this.state = {
      searchedWord: ""
    };
  }

  loadFriends() {
    const { fetchFriendsIfNeeded, invalidateFriends } = this.props;

    invalidateFriends();
    fetchFriendsIfNeeded({});
  }

  loadFollowers() {
    const { invalidateFollowingsDetails, fetchFollowingsIfNeeded } = this.props;

    invalidateFollowingsDetails();
    fetchFollowingsIfNeeded({
      profileId: this.props.userId,
      onlyId: false
    });
  }

  componentDidMount() {
    this.loadFollowers();
    this.loadFriends();
  }

  showFriendListSidebar() {
    this.props.friendListSidebarOpen();
  }
  hideFriendListSidebar() {
    this.props.friendListSidebarClose();
  }

  addDefaultSrc(ev) {
    ev.target.src = avatar3x;
  }

  handleInputChange = () => {
    let searchedWord = this.search.value.trim();
    if (searchedWord !== "") {
      this.props.searchProfilesRequest(searchedWord);
    }
    this.setState({
      searchedWord: searchedWord
    });
  };

  render() {
    const {
      friendsList,
      socketusers,
      followingsList,
      allOnlineUserList,
      t
    } = this.props;
    // console.log(friendsList)
    // console.log(socketusers)
    // console.log(this.state.searchedWord)
    //console.log(followingsList)

    // var friendsIds = ["steve", "bob", "john"];
    // console.log(friendsIds.indexOf("bob1") > -1);

    var friendsIds = [];
    if (friendsList && friendsList.length > 0) {
      friendsList.map((p, i) => friendsIds.push(p.id));
    }

    var followingsIds = [];
    if (followingsList && followingsList.length > 0) {
      followingsList.map((p, i) => followingsIds.push(p.id.toString()));
    }

    let inc = 0;

    return (
      <>
        <nav
          className={
            "my-friend-slider" +
            (this.props.isfriendListSidebarOpen ? " active" : "")
          }
          ref={rightSidebar => {
            this.rightSidebar = rightSidebar;
          }}
        >
          <div className="fixed-sidebar-scroll-landscap chat-screen">
            <section className="d-flex flex-column ">
              <section className="row no-gutters">
                <div className="col-sm-12 text-center">
                  <div className="sidebar-header">
                    <div className="d-flex  justify-content-between align-items-center message-header">
                      <h6 className="bold">
                        <span
                          onClick={this.props.profileSidebarOpen}
                          className="a back-arrow"
                        >
                          <img src={arrowRightSvg} alt="" />
                        </span>{" "}
                        {t("profile")}
                      </h6>
                    </div>
                    <form action="#">
                      <div className="form-group without-lable ">
                        <div className="input-group-wrapper search">
                          <input
                            type="text"
                            className="form-control effect-1"
                            placeholder="Search"
                            ref={input => (this.search = input)}
                            onChange={this.handleInputChange}
                          />
                          <img src={searchImg} className="search-icon" alt="" />
                          <span className="focus-border" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>

              {this.state.searchedWord !== "" ? (
                <section className="message-info">
                  <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                    <div className="media col">
                      <span>
                        <b>{t("searchResult")}</b>
                      </span>
                    </div>
                  </div>

                  {socketusers && socketusers.length > 0
                    ? socketusers.map((p, i) =>
                        this.props.userId != p.id
                          ? ((inc = inc + 1),
                            (
                              <div
                                key={i}
                                className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1"
                              >
                                <div className="media col">
                                  <img
                                    src={p.avatarUrl ? p.avatarUrl : avatar3x}
                                    className="profile-pic-48"
                                    alt=""
                                    onError={this.addDefaultSrc}
                                  />
                                  {allOnlineUserList.includes(p.id) ? (
                                    <figure>
                                      <figcaption className="online-48" />
                                    </figure>
                                  ) : null}
                                  <div className="media-body ml-3 align-self-center">
                                    <p className="mb-0 text-truncate mw-150">
                                      {refactorTitle(p.name, 10)}
                                    </p>
                                  </div>
                                </div>
                                {friendsIds.indexOf(p.id) > -1 == true &&
                                followingsIds.indexOf(p.id) > -1 == false ? (
                                  <div className="col-3 ml-auto">
                                    <a
                                      href="javascript:void(0)"
                                      className={
                                        "btn  rounded-xs align-items-center justify-content-center btn-block " +
                                        (process.env.REACT_APP_PLATFORM ===
                                        "nickx"
                                          ? "btn-outline-orange"
                                          : "btn-outline-secondary")
                                      }
                                      onClick={() => {
                                        this.props.followFormSubmit({
                                          followeeId: p.id
                                        });
                                      }}
                                    >
                                      {" "}
                                      Follow
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <NiceDropdownComponent
                                  reporterId={this.props.userId}
                                  reporteeId={p.id}
                                  following={
                                    followingsIds.indexOf(p.id) > -1
                                      ? "true"
                                      : "false"
                                  }
                                  profileId={p.id}
                                  followerId={this.props.userId}
                                  followeeId={p.id}
                                  avatarUrl={p.avatarUrl}
                                  userName={p.name}
                                  isFriend={friendsIds.indexOf(p.id) > -1}
                                  fixToBottom={i < 1}
                                />
                              </div>
                            ))
                          : ""
                      )
                    : ""}

                  {inc === 0 ? (
                    <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                      <div className="media col">
                        <span>{t("noSearchResult")}</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </section>
              ) : (
                ""
              )}

              <section className="message-info mt-3">
                <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                  <div className="media col">
                    <span>
                      <b>{t("myFriend").toUpperCase()}</b>
                    </span>
                  </div>
                </div>

                {friendsList && friendsList.length > 0 ? (
                  friendsList.map((p, i) => (
                    <div
                      key={i}
                      className="row no-gutters d-flex justify-content-between align-items-center message-list bb-1"
                    >
                      <div className="media col">
                        <img
                          src={p.avatar_url ? p.avatar_url : avatar3x}
                          className="profile-pic-48"
                          alt=""
                          onError={this.addDefaultSrc}
                        />
                        {allOnlineUserList.includes(p.id) ? (
                          <figure>
                            <figcaption className="online-48" />
                          </figure>
                        ) : null}
                        <div className="media-body ml-3 align-self-center">
                          <p
                            className="mb-0 text-truncate mw-150"
                            title={p.name}
                          >
                            {refactorTitle(p.name, 10)}
                          </p>
                        </div>
                      </div>
                      {!p.isFollowing ? (
                        <div className="col-3 ml-auto">
                          <span
                            className={
                              "btn rounded-xs  align-items-center justify-content-center  btn-block " +
                              (process.env.REACT_APP_PLATFORM === "nickx"
                                ? "btn-outline-orange"
                                : "btn-outline-secondary")
                            }
                            onClick={() => {
                              this.props.followFormSubmit({
                                followeeId: p.id
                              });
                            }}
                          >
                            {" "}
                            {t("follow")}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      <NiceDropdownComponent
                        reporterId={this.props.userId}
                        reporteeId={p.id}
                        following={p.isFollowing ? "true" : "false"}
                        profileId={p.id}
                        followerId={this.props.userId}
                        followeeId={p.id}
                        avatarUrl={p.avatar_url}
                        userName={p.name}
                        isFriend={friendsIds.indexOf(p.id) > -1}
                        fixToBottom={i < 1}
                      />
                    </div>
                  ))
                ) : (
                  <div className="row no-gutters d-flex justify-content-between align-items-center message-list">
                    <div className="media col">
                      <span>{t("noFriendYet")}</span>
                    </div>
                  </div>
                )}
              </section>
            </section>
          </div>
        </nav>
      </>
    );
  }
}
const mapDispatchToProps = {
  disableOuterClick: false,
  friendListSidebarOpen: friendListSidebar.open,
  friendListSidebarClose: friendListSidebar.close,
  profileSidebarOpen: profileSidebar.open,

  //friends list
  fetchFriendsIfNeeded: friendsList.fetchIfNeeded,
  invalidateFriends: friendsList.invalidate,

  followFormSubmit: followForm.submit,

  //search profile
  searchProfilesRequest: searchProfilesRequest,

  //followings
  fetchFollowingsIfNeeded: followings.fetchIfNeeded,
  invalidateFollowingsDetails: followings.invalidate
};

const mapStateToProps = state => {
  const { friends, socketusers, profiles } = state;

  const { items: friendsList } = friends.friendsList || {
    items: []
  };

  const { items: followingsList } = profiles.followingsList || {
    items: []
  };

  return {
    isfriendListSidebarOpen: state.sidebars.isfriendListSidebarOpen,
    friendsList,
    userId: state.user.item.id,
    socketusers: socketusers.users,
    allOnlineUserList: socketusers.allOnlineUserList,
    followingsList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FriendList));
