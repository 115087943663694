import React, { Component } from "react";
import { connect } from "react-redux";
import "./InactiveSite.scss";
// import Modal from "../Modal";
import deactivatedArcadexLogo from "../../../assets/images/deactivated-arcadex-logo.png";
import hand from "../../../assets/images/hand.png";
import { withTranslation } from "react-i18next";

class InactiveSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLandScape: false
    };
  }

  modalFooter() {
    const { t } = this.props;
    return (
      <div className="modal-footer">
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {/* <Modal
          handleHide={() => {}}
          isOpen={true}
          disableOuterClick={false}
          modalclass=""
          withFooter={true}
          footer={() => {}}
        > */}
           <section className="no-longer-active">
              <div className="message container-fluid">
                  <div className="row">
                      <div className="col-sm-8 text-center">
                          <div className="no-longer-arcadex-logo">
                              <img src={deactivatedArcadexLogo} alt=""/>
                          </div>
                          <div className="device-image text-center first-device">
                              <img src={hand} alt=""/>
                          </div>
                      </div>
                      <div className="col-sm-4">
                          <h3 className="message-text">
                          Arcade X does not have any active tournaments. We will begin operating tournaments soon. <br/><br/>
                          Please email us at <a href='mailto:support@arcadex.co' className="mailto">support@arcadex.co</a> <br/>if you have any questions. <br/><br/>

                          Kind regards,<br/>
                          Arcade X Team <br/>
                          </h3>
                          <div className="device-image text-center second-device">
                              <img src={hand} alt=""/>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <div className="modal-backdrop fade show" />
        {/* </Modal> */}
      </>
    );
  }
}
const mapDispatchToProps = {
};

const mapStateToProps = state => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InactiveSite));