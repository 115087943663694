import { connect } from "react-redux";
import DetailComponent from "../components/FreeGames/DetailComponent";

import { newsList, newsCategoriesList } from "../actions/news";

import { arcadeGameDetail } from "../actions/categories";

const mapDispatchToProps = {
  fetchNewsIfNeeded: newsList.fetchIfNeeded,
  invalidateNewsIfNeeded: newsList.invalidate,
  fetchArcadeGameDetailIfNeeded: arcadeGameDetail.fetchIfNeeded,
  invalidateArcadeGameDetail: arcadeGameDetail.invalidate,
  fetchnewsCategoriesListIfNeeded: newsCategoriesList.fetchIfNeeded,
  invalidateNewsCategoriesList: newsCategoriesList.invalidate
};

const mapStateToProps = state => {
  const { news, categories } = state;

  const { items: newsListAll } = news.newsList || {
    items: []
  };

  return {
    newsListAll,
    arcadeGameDetail: categories.arcadeGameDetail
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);
