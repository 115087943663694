import React from "react";
import { VALUE_UPDATE } from "../types/forms";
import { FormActionCreator } from "./creators";
import {
  joinChallengeService,
  finishGameChallengeService,
  claimPrizeService
} from "../services/challenges.service";
import { getVerifyPrize, getProfileLastPrize } from "../services/prize.service";
import { challengeDetails, claimChallengeDetails } from "../actions/challenges";
import { myActiveRafflesList, rafflesList } from "../actions/raffles";
import { friendsList } from "../actions/friends";
import {
  followings,
  friendsFollowers,
  friendsProfile
} from "../actions/profiles";
import { brandDetails } from "../actions/brands";
import { Trans } from "react-i18next";

import {
  LoginForm,
  RegisterForm,
  RegisterHyveForm,
  SubscribeForm,
  CreateUsernameForm,
  UsernameAvailableForm,
  CreateAvatarForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  CreateTutorialForm,
  LoginHyveForm,
  JoinChallengePasswordForm,
  FinishGameChallengeForm,
  QuestionForm,
  ProfileEditForm,
  ChangePasswordForm,
  GetProfileDataForm,
  JoinRafflesForm,
  ReportPlayerForm,
  FollowForm,
  UnfollowForm,
  FollowBrandForm,
  UnfollowBrandForm,
  AddAsFriendForm,
  GetClaimPrizeForm,
  VerifyPrizeForm,
  NewsDetailsForm,
  UnFriendForm,
  FriendDeclineForm,
  FriendAcceptForm,
  GetLastPrizeForm,
  PublishRaffleForm,
  ContactForm,
  EditAvatarForm,
  NGLoginForm
} from "../redux-config/formActionMap";
import {
  onLogInUser,
  onCreateUserName,
  onUsernameAvailable,
  onRegisterUser,
  onUpdateUserAvatar,
  onForgotPasswordRequest,
  onResetPassword,
  onAddShownTutorial,
  onLogInHyve,
  onRegisterWithHyve,
  onQuestion,
  onProfileEdit,
  onGetUserProfile,
  onChangePasswordRequest,
  onReportPlayer,
  onNGLogInUserRequest
} from "../services/users.service";
import { joinRaffles, publishRaffleService } from "../services/raffles.service";
import {
  followFriend,
  unfollowFriend,
  addAsFriendService,
  unFriendService,
  friendDeclinedService,
  friendAcceptedService
} from "../services/friends.service";
import { followBrand, unfollowBrand } from "../services/brands.service";
import {
  createAvatar,
  createTutorial,
  resetPassword,
  modalCloseAll,
  endGameModal,
  changePassword,
  verifyPrizeModal
} from "./modals";
import { tutorialRequest } from "./tutorials";

import {
  subscribeEmail,
  contactUsService
} from "../services/subscribe.service";
import { loginSuccess, updateUserDetail } from "./user";
import { axiosInstance } from "../utils/axios";
import { sidebarCloseAll } from "./sidebars";
import { setToastr } from "./toastr";
import { toggleOtherReason } from "./toggles";
import { newsDetails } from "./news";
import { initialiseSocket } from "./socket";
import i18n from "../i18n";

export const onValueUpdate = (scope, field, value) => ({
  type: VALUE_UPDATE,
  payload: { scope: scope, field: field, value: value }
});

export const subscribeForm = FormActionCreator({
  ...SubscribeForm,
  service: subscribeEmail,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(SubscribeForm));
      dispatch(
        extraActions.setSuccess(
          SubscribeForm,
          "email",
          "You are successfully subscribed."
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(SubscribeForm, "email"));
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(SubscribeForm, "email", "Subscription failed")
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});
export const contactForm = FormActionCreator({
  ...ContactForm,
  service: contactUsService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(ContactForm));
      dispatch(
        extraActions.setSuccess(ContactForm, "email", "CONTACT_US_SUCCESS")
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ContactForm, "email"));
      }, 5000);
    } else {
      dispatch(extraActions.setError(ContactForm, "email", "CONTACT_SUPPORT"));
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const joinChallengePasswordForm = FormActionCreator({
  ...JoinChallengePasswordForm,
  service: joinChallengeService,
  onResponse: (response, extraActions, dispatch, getState) => {
    let msg = (
      <Trans i18nKey="successJoin">You have successfully joined.</Trans>
    );
    if (response.data.success) {
      dispatch(
        challengeDetails.fetchData(
          getState().challenges.challengeDetails.item.id
        )
      );
      dispatch(modalCloseAll());
      dispatch(
        extraActions.setSuccess(
          JoinChallengePasswordForm,
          "joinChallengePasswordformStatus",
          "Success!"
        )
      );
      dispatch(setToastr("success", msg));
    } else {
      dispatch(
        extraActions.setError(
          JoinChallengePasswordForm,
          "joinChallengePasswordformStatus",
          response.data.error.message
        )
      );
      dispatch(setToastr("error", response.data.error.message));
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const finishGameChallengeForm = FormActionCreator({
  ...FinishGameChallengeForm,
  service: finishGameChallengeService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        challengeDetails.fetchData(
          getState().challenges.challengeDetails.item.id
        )
      );
      const userId = getState().user.item.id;
      dispatch(getProfileDataForm.submit({ profileId: userId }));
      dispatch(endGameModal.open());
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});
export const createUsernameForm = FormActionCreator({
  ...CreateUsernameForm,
  service: onCreateUserName,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(tutorialRequest({ hasCreatenamePreferences: true }));
      const userId = getState().user.item.id;
      dispatch(getProfileDataForm.submit({ profileId: userId }));

      dispatch(createAvatar.open());

      setTimeout(() => {
        const name = getState().user.item.name;
        dispatch(profileEditForm.updateField("username", name));
        dispatch(extraActions.reset(CreateUsernameForm));
      }, 1000);
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        extraActions.setError(
          CreateUsernameForm,
          "username",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : somethingWrong
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const createAvatarForm = FormActionCreator({
  ...CreateAvatarForm,
  service: onUpdateUserAvatar,
  onResponse: (response, extraActions, dispatch, getState) => {
    const avatarUrl = getState().forms.createAvatarForm.fields.avatar.value;
    const userId = getState().user.item.id;
    const tutorials = getState().user.item.tutorials;

    if (response.data.success) {
      dispatch(tutorialRequest({ hasCreateavatarPreferences: true }));
      if (avatarUrl) {
        dispatch(getProfileDataForm.submit({ profileId: userId }));
      }
      setTimeout(() => {
        dispatch(extraActions.reset(CreateAvatarForm));
      }, 1000);

      if (!tutorials) {
        dispatch(createTutorial.open());
      } else {
        dispatch(createAvatar.close());
      }
    } else {
      if (!avatarUrl) {
        if (!tutorials) {
          dispatch(tutorialRequest({ hasCreateavatarPreferences: true }));
          dispatch(createTutorial.open());
        } else {
          dispatch(
            tutorialRequest({
              hasCreateavatarPreferences: true,
              hasCreatetutorialPreferences: true
            })
          );

          dispatch(createAvatar.close());
        }
      } else {
        dispatch(
          extraActions.setError(
            CreateAvatarForm,
            "avatar",
            response.data.error && response.data.error.code
              ? response.data.error.code
              : "CONTACT_SUPPORT"
          )
        );
      }
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const editAvatarForm = FormActionCreator({
  ...EditAvatarForm,
  service: onUpdateUserAvatar,
  onResponse: (response, extraActions, dispatch, getState) => {
    const avatarUrl = getState().forms.editAvatarForm.fields.avatar.value;
    const userId = getState().user.item.id;
    const tutorials = getState().user.item.tutorials;

    if (response.data.success) {
      // dispatch(tutorialRequest({ hasCreateavatarPreferences: true }));
      
      if (avatarUrl) {
        dispatch(getProfileDataForm.submit({ profileId: userId }));
      }
      setTimeout(() => {
        dispatch(extraActions.reset(EditAvatarForm));
      }, 1000);

      // if (!tutorials) {
      //   dispatch(createTutorial.open());
      // } else {
      //   dispatch(createAvatar.close());
      // }
    } else {
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const createTutorialForm = FormActionCreator({
  ...CreateTutorialForm,
  service: onAddShownTutorial,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(createTutorial.close());
      dispatch(tutorialRequest({ hasCreatetutorialPreferences: true }));
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const usernameAvailableForm = FormActionCreator({
  ...UsernameAvailableForm,
  service: onUsernameAvailable,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let usernotExit = <Trans i18nKey="userNotExit">User not exists</Trans>;
      dispatch(
        extraActions.setSuccess(UsernameAvailableForm, "username", usernotExit)
      );
    } else {
      response.data.error &&
      response.data.error.code &&
      response.data.error.code === "G001"
        ? dispatch(
            extraActions.setError(
              UsernameAvailableForm,
              "username",
              "USERNAME_USE"
            )
          )
        : dispatch(
            extraActions.setError(
              UsernameAvailableForm,
              "username",
              response.data.error.code
            )
          );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const forgotPasswordForm = FormActionCreator({
  ...ForgotPasswordForm,
  service: onForgotPasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const resendEmail = getState().forms.forgotPasswordForm.fields.resendEmail
        .value;

      dispatch(extraActions.reset(ForgotPasswordForm));
      dispatch(
        extraActions.setSuccess(
          ForgotPasswordForm,
          "email",
          resendEmail
            ? "FORGOT_PASSWORD_RESEND_MAIL"
            : "FORGOT_PASSWORD_SEND_MAIL"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ForgotPasswordForm, "email"));
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(
          ForgotPasswordForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const changePasswordForm = FormActionCreator({
  ...ChangePasswordForm,
  service: onChangePasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        extraActions.setSuccess(
          ChangePasswordForm,
          "newPassword",
          "RESET_PASSWORD_SUCCESS"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ChangePasswordForm, "newPassword"));
        dispatch(changePassword.close());
        dispatch(extraActions.reset(ChangePasswordForm));
      }, 2000);
    } else {
      dispatch(
        extraActions.setError(
          ChangePasswordForm,
          "newPassword",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const resetPasswordForm = FormActionCreator({
  ...ResetPasswordForm,
  service: onResetPassword,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(ResetPasswordForm));
      dispatch(
        extraActions.setSuccess(
          ResetPasswordForm,
          "newPassword",
          "RESET_PASSWORD_SUCCESS"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ResetPasswordForm, "newPassword"));
        dispatch(resetPassword.close());
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(
          ResetPasswordForm,
          "newPassword",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "NOT_RESET_PASSWORD"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});
  
export const loginForm = FormActionCreator({
  ...LoginForm,
  service: onLogInUser,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.token)
        axiosInstance.setToken(response.data.data.token);
      const hasCreateName = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateAvatar = response.data.data.hasInitialPreferences
        ? true
        : false;
      const hasCreateTutorial = response.data.data.hasInitialPreferences
        ? true
        : false;
      let param = {
        data: response.data.data
      };

      dispatch(loginSuccess(param));

      setTimeout(() => {
        dispatch(initialiseSocket());
      }, 500);
      let tutorialsParam = {
        hasCreatenamePreferences: hasCreateName,
        hasCreateavatarPreferences: hasCreateAvatar,
        hasCreatetutorialPreferences: hasCreateTutorial
      };
      dispatch(tutorialRequest(tutorialsParam));
      let successLogin = (
        <Trans i18nKey="successLogin">You are successfully login.</Trans>
      );
      dispatch(extraActions.setSuccess(LoginForm, "email", successLogin));
      dispatch(extraActions.reset(LoginForm));
      dispatch(sidebarCloseAll());
      if (
        response.data.data.hasInitialPreferences &&
        response.data.data.profileTutorials
      ) {
        dispatch(modalCloseAll());
      }
    } else {
      dispatch(
        extraActions.setError(
          LoginForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT",
          {
            text:
              response.data.info !== undefined ? response.data.info.text : "",
            shortCode:
              response.data.info !== undefined
                ? response.data.info.shortCode
                : "",
            reason:
              response.data.banReason !== undefined
                ? response.data.banReason
                : ""
          }
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const loginHyveForm = FormActionCreator({
  ...LoginHyveForm,
  service: onLogInHyve,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.token)
        axiosInstance.setToken(response.data.data.token);

      const hasCreateName = response.data.data.has_initial_preferences
        ? true
        : false;
      const hasCreateAvatar = response.data.data.has_initial_preferences
        ? true
        : false;
      const hasCreateTutorial = response.data.data.has_initial_preferences
        ? true
        : false;

      let param = {
        data: response.data.data
      };

      dispatch(loginSuccess(param));
      setTimeout(() => {
        dispatch(initialiseSocket());
      }, 500);
      let tutorialsParam = {
        hasCreatenamePreferences: hasCreateName,
        hasCreateavatarPreferences: hasCreateAvatar,
        hasCreatetutorialPreferences: hasCreateTutorial
      };
      dispatch(tutorialRequest(tutorialsParam));
      let successLogin = (
        <Trans i18nKey="successLogin">You are successfully login.</Trans>
      );
      dispatch(extraActions.setSuccess(loginHyveForm, "email", successLogin));
      dispatch(extraActions.reset(LoginHyveForm));
      dispatch(modalCloseAll());
    } else {
      dispatch(
        extraActions.setError(
          loginHyveForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT",
          {
            text:
              response.data.info !== undefined ? response.data.info.text : "",
            shortCode:
              response.data.info !== undefined
                ? response.data.info.shortCode
                : "",
            reason:
              response.data.banReason !== undefined
                ? response.data.banReason
                : ""
          }
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const registerForm = FormActionCreator({
  ...RegisterForm,
  service: onRegisterUser,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(RegisterForm));
      dispatch(extraActions.updateField(RegisterForm, "terms", false));
      dispatch(
        extraActions.setSuccess(RegisterForm, "email", "REGISTER_SUCCESS")
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(RegisterForm, "email"));
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(
          RegisterForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT",
          {
            text:
              response.data.info !== undefined ? response.data.info.text : "",
            shortCode:
              response.data.info !== undefined
                ? response.data.info.shortCode
                : "",
            reason:
              response.data.banReason !== undefined
                ? response.data.banReason
                : ""
          }
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const registerHyveForm = FormActionCreator({
  ...RegisterHyveForm,
  service: onRegisterWithHyve,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        extraActions.setSuccess(RegisterHyveForm, "email", "REGISTER_SUCCESS")
      );
      setTimeout(() => {
        dispatch(extraActions.reset(RegisterHyveForm));
      }, 1000);
    } else {
      dispatch(
        extraActions.setError(
          RegisterHyveForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT",
          {
            text:
              response.data.info !== undefined ? response.data.info.text : "",
            shortCode:
              response.data.info !== undefined
                ? response.data.info.shortCode
                : "",
            reason:
              response.data.banReason !== undefined
                ? response.data.banReason
                : ""
          }
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const questionForm = FormActionCreator({
  ...QuestionForm,
  service: onQuestion,
  onResponse: (response, extraActions, dispatch, getState) => {
    dispatch(
      extraActions.setSuccess(QuestionForm, "email", "HELP_SUPPORT_SUCCESS")
    );
    setTimeout(() => {
      dispatch(extraActions.reset(QuestionForm));
    }, 1000);
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const reportPlayerForm = FormActionCreator({
  ...ReportPlayerForm,
  service: onReportPlayer,
  onResponse: (response, extraActions, dispatch, getState) => {
    dispatch(
      extraActions.setSuccess(
        ReportPlayerForm,
        "reason",
        "REPORT_PLAYER_SUCCESS"
      )
    );
    dispatch(toggleOtherReason.reset());
    dispatch(modalCloseAll());
    setTimeout(() => {
      dispatch(extraActions.reset(ReportPlayerForm));
    }, 1000);
    let reportSuccess = (
      <Trans i18nKey="reportSuccess">Reported successfully.</Trans>
    );
    if (response.data.success) {
      dispatch(setToastr("success", reportSuccess));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        setToastr(
          "error",
          response.data.error && response.data.error.message
            ? response.data.error.message
            : somethingWrong
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});
export const profileEditForm = FormActionCreator({
  ...ProfileEditForm,
  service: onProfileEdit,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const userId = getState().user.item.id;
      dispatch(getProfileDataForm.submit({ profileId: userId }));
      setTimeout(() => {
        dispatch(sidebarCloseAll());
      }, 1000);
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        extraActions.setError(
          ProfileEditForm,
          "username",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : somethingWrong
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const getProfileDataForm = FormActionCreator({
  ...GetProfileDataForm,
  service: onGetUserProfile,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let param = {
        data: response.data.data
      };
      dispatch(updateUserDetail(param));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        extraActions.setError(
          ProfileEditForm,
          "username",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : somethingWrong
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});

export const JoinRafflesDataForm = FormActionCreator({
  ...JoinRafflesForm,
  service: joinRaffles,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const userId = getState().user.item.id;
      dispatch(getProfileDataForm.submit({ profileId: userId }));
      dispatch(
        myActiveRafflesList.fetchData({
          page: ""
        })
      );
      dispatch(
        rafflesList.fetchData({
          page: ""
        })
      );

      dispatch(modalCloseAll());
      dispatch(
        extraActions.setSuccess(JoinRafflesForm, "raffleId", "Success!")
      );
      let raffleSuccess = (
        <Trans i18nKey="joinRaffleSuccess">Join Raffles successfully.</Trans>
      );
      dispatch(setToastr("success", raffleSuccess));
    } else {
      dispatch(
        extraActions.setError(
          JoinRafflesForm,
          "raffleId",
          response.data.error.message
        )
      );
      if (response.data.error.code === "T005" || response.data.error.code === "T055" || response.data.error.code === "T054") {
        let notEnoughToken = (
          <Trans i18nKey="notEnoughTokenToJoin">
            You do not have enough tokens to enter.
          </Trans>
        );
        dispatch(setToastr("error", notEnoughToken));
      } else if (response.data.error.code === "T050") {
        let notAllowForBrand = (
          <Trans i18nKey="notAllowForBrand">Not allowed for a brand.</Trans>
        );
        dispatch(setToastr("error", notAllowForBrand));
      }
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const followBrandForm = FormActionCreator({
  ...FollowBrandForm,
  service: followBrand,
  onResponse: (response, extraActions, dispatch, getState) => {
    let brandId = getState().brands.brandDetails.item.brandId;
    if (response.data.success) {
      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
      dispatch(
        followings.fetchData({
          profileId: getState().user.item.id,
          onlyId: false
        })
      );
      if (brandId) {
        dispatch(
          brandDetails.fetchData({
            brandId: getState().brands.brandDetails.item.brandId
          })
        );
      }
      dispatch(
        getProfileDataForm.submit({ profileId: getState().user.item.id })
      );
      dispatch(
        friendsFollowers.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
      dispatch(
        friendsProfile.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
    }
    if (response.data.success) {
      let followSuccess = (
        <Trans i18nKey="followSuccess">Followed successfully.</Trans>
      );
      dispatch(setToastr("success", followSuccess));
    } else {
      dispatch(setToastr("error", response.data.error.message));
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const unfollowBrandForm = FormActionCreator({
  ...UnfollowBrandForm,
  service: unfollowBrand,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let brandId = getState().brands.brandDetails.item.brandId;
      dispatch(
        extraActions.setSuccess(
          UnfollowBrandForm,
          "followerId",
          "REPORT_PLAYER_SUCCESS"
        )
      );

      dispatch(modalCloseAll());

      setTimeout(() => {
        dispatch(extraActions.reset(UnfollowForm));
      }, 1000);

      if (brandId) {
        dispatch(
          brandDetails.fetchData({
            brandId: getState().brands.brandDetails.item.brandId
          })
        );
      }
      dispatch(
        getProfileDataForm.submit({ profileId: getState().user.item.id })
      );
      dispatch(
        followings.fetchData({
          profileId: getState().user.item.id,
          onlyId: false
        })
      );

      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
      dispatch(
        friendsFollowers.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
      dispatch(
        friendsProfile.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
    }

    if (response.data.success) {
      let unFollowSuccess = (
        <Trans i18nKey="unFollowSuccess">Unfollowed successfully.</Trans>
      );
      dispatch(setToastr("success", unFollowSuccess));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        setToastr(
          "error",
          response.data.error && response.data.error.message
            ? response.data.error.message
            : somethingWrong
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const followForm = FormActionCreator({
  ...FollowForm,
  service: followFriend,
  onResponse: (response, extraActions, dispatch, getState) => {
    let brandId = getState().brands.brandDetails.item.brandId;
    if (response.data.success) {
      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
      dispatch(
        followings.fetchData({
          profileId: getState().user.item.id,
          onlyId: false
        })
      );
      if (brandId) {
        dispatch(
          brandDetails.fetchData({
            brandId: getState().brands.brandDetails.item.brandId
          })
        );
      }
      dispatch(
        getProfileDataForm.submit({ profileId: getState().user.item.id })
      );
      dispatch(
        friendsFollowers.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
      dispatch(
        friendsProfile.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
    }
    if (response.data.success) {
      let followSuccess = (
        <Trans i18nKey="followSuccess">Followed successfully.</Trans>
      );
      dispatch(setToastr("success", followSuccess));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        setToastr(
          "error",
          response.data.error && response.data.error.message
            ? response.data.error.message
            : somethingWrong
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const unfollowForm = FormActionCreator({
  ...UnfollowForm,
  service: unfollowFriend,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let brandId = getState().brands.brandDetails.item.brandId;
      dispatch(
        extraActions.setSuccess(
          UnfollowForm,
          "followerId",
          "REPORT_PLAYER_SUCCESS"
        )
      );

      dispatch(modalCloseAll());

      setTimeout(() => {
        dispatch(extraActions.reset(UnfollowForm));
      }, 1000);

      if (brandId) {
        dispatch(
          brandDetails.fetchData({
            brandId: getState().brands.brandDetails.item.brandId
          })
        );
      }
      dispatch(
        getProfileDataForm.submit({ profileId: getState().user.item.id })
      );
      dispatch(
        followings.fetchData({
          profileId: getState().user.item.id,
          onlyId: false
        })
      );

      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
      dispatch(
        friendsFollowers.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
      dispatch(
        friendsProfile.fetchData({
          profileId: getState().profiles.profileOfFriend.item.profile.id
        })
      );
    }

    if (response.data.success) {
      let unFollowSuccess = (
        <Trans i18nKey="unFollowSuccess">Unfollowed successfully.</Trans>
      );
      dispatch(setToastr("success", unFollowSuccess));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        setToastr(
          "error",
          response.data.error && response.data.error.message
            ? response.data.error.message
            : somethingWrong
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const addAsFriendForm = FormActionCreator({
  ...AddAsFriendForm,
  service: addAsFriendService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let requestSentSuccess = (
        <Trans i18nKey="requestSentSuccess">
          You sent request successfully.
        </Trans>
      );
      dispatch(setToastr("success", requestSentSuccess));
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Something went wrong</Trans>
      );
      dispatch(
        setToastr(
          "error",
          response.data.error && response.data.error.message
            ? response.data.error.message
            : somethingWrong
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const newsDetailsForm = FormActionCreator({
  ...NewsDetailsForm,
  service: newsDetails,
  onResponse: (response, extraActions, dispatch, getState) => {},
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const friendAcceptForm = FormActionCreator({
  ...FriendAcceptForm,
  service: friendAcceptedService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const friendDeclineForm = FormActionCreator({
  ...FriendDeclineForm,
  service: friendDeclinedService,
  onResponse: (response, extraActions, dispatch, getState) => {},
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const unFriendForm = FormActionCreator({
  ...UnFriendForm,
  service: unFriendService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
    }

    if (response.data.success) {
      let removeSuccess = (
        <Trans i18nKey="removeSuccess">Removed successfully.</Trans>
      );
      dispatch(setToastr("success", removeSuccess));
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const claimPrizeForm = FormActionCreator({
  ...GetClaimPrizeForm,
  service: claimPrizeService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      setTimeout(() => {
        dispatch(extraActions.reset(GetClaimPrizeForm));
      }, 1000);
      dispatch(
        friendsList.fetchData({
          profileId: getState().user.item.id
        })
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const verifyPrizeForm = FormActionCreator({
  ...VerifyPrizeForm,
  service: getVerifyPrize,
  onResponse: (response, extraActions, dispatch, getState) => {
    const state = getState();
    const challengeId = state.forms.verifyPrizeForm.fields.challengeId.value;
    if (!response.data.success) {
      dispatch(verifyPrizeModal.open());
    } else {
      claimChallengeDetails.fetchData(challengeId);
      dispatch(
        extraActions.updateField(
          VerifyPrizeForm,
          "verifyResponse",
          response.data.data
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const getLastPrizeForm = FormActionCreator({
  ...GetLastPrizeForm,
  service: getProfileLastPrize,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        claimPrizeForm.updateField(
          "sirName",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.surname
            : ""
        )
      );

      dispatch(
        claimPrizeForm.updateField(
          "firstName",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.firstname
            : ""
        )
      );

      dispatch(
        claimPrizeForm.updateField(
          "bankName",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.bankname
            : ""
        )
      );

      dispatch(
        claimPrizeForm.updateField(
          "branchCode",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.branchcode
            : ""
        )
      );

      dispatch(
        claimPrizeForm.updateField(
          "contactNumber",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.cellnumber
            : ""
        )
      );
      dispatch(
        claimPrizeForm.updateField(
          "residence",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.residence
            : ""
        )
      );

      dispatch(
        claimPrizeForm.updateField(
          "email",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.emailaddress
            : ""
        )
      );
      dispatch(
        claimPrizeForm.updateField(
          "accType",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.accounttype
            : ""
        )
      );
      dispatch(
        claimPrizeForm.updateField(
          "accNumber",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.accountnumber
            : ""
        )
      );
      dispatch(
        claimPrizeForm.updateField(
          "accHolderName",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.accountholdersname
            : ""
        )
      );
      dispatch(
        claimPrizeForm.updateField(
          "contractProvider",
          response.data.data.redemption_data
            ? response.data.data.redemption_data.contactprovider
            : ""
        )
      );
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => {}
});

export const publishRaffleForm = FormActionCreator({
  ...PublishRaffleForm,
  service: publishRaffleService,
  onResponse: (response, extraActions, dispatch, getState) => {
    const lang = i18n.language;
    if (response.data.success) {
      dispatch(
        extraActions.setSuccess(
          ReportPlayerForm,
          "entryFee",
          "PUBLISH_RAFFLE_SUCCESS"
        )
      );

      setTimeout(() => {
        dispatch(extraActions.reset(PublishRaffleForm));
      }, 1000);
      //dispatch(setToastr("success", "Raffle created successfully."));
      window.location = "/" + lang + "/raffles";
    } else {
      let somethingWrong = (
        <Trans i18nKey="somethingWentWrong">Oops, Something went wrong</Trans>
      );
      dispatch(setToastr("error", somethingWrong));
    }
  },
  onError: (response, extraActions, dispatch, getState) => {}
});
